import gql from 'graphql-tag';
import * as Fragments from './fragments.js';

export const FETCH_USERS = gql`
  query fetchUsers($query: UserQuery) {
    users(query: $query) {
      ...userFields
    }
  }
  ${Fragments.UserFragments.fields}
`;

export const UPDATE_POLICY = gql`
  mutation updatePolicy($id: ID!, $input: PolicyInput! ) {
    policy(id: $id) {
      update(input: $input) {
        ...policyFields
      }
    }
  }
  ${Fragments.PolicyFragments.fields}
`
export const FETCH_POLICIES = gql`
  query fetchPolicies($query: PolicyQuery) {
    policies(query: $query) {
      ...policyFields
      user {
        id
        fullName
      }
      contract {
        id
        endDate
      }
      address {
        ...addressFields
      }
      property {
        id
        propertyUse
      }
      lastInvoice {
        ...invoiceFields
      }
      lastPayment {
        createdAt
      }
    }
  }
  ${Fragments.PolicyFragments.fields}
  ${Fragments.AddressFragments.fields}
  ${Fragments.InvoiceFragments.fields}
`


export const FETCH_USER_FULL = gql`
  query fetchUsers($query: UserQuery, $propertyQuery: PropertyQuery) {
    users(query: $query) {
      ...userFields
      clientDetail {
        ...clientDetailFields
        address {
          ...addressFields
        }
      }
      tenants {
        ...tenantFields
      }
      properties(query: $propertyQuery) {
        ...propertyFields
        createdBy {
          id
          fullName
        }
        lastPolicy {
          ...policyFields
          lastInvoice {
            ...invoiceFields
          }
        }
        lastContract {
          ...contractFields
        }
      }
    }
  }
  ${Fragments.ContractFragments.fields}
  ${Fragments.InvoiceFragments.fields}
  ${Fragments.PolicyFragments.fields}
  ${Fragments.PropertyFragments.fields}
  ${Fragments.UserFragments.fields}
  ${Fragments.ClientDetailFragments.fields}
  ${Fragments.AddressFragments.fields}
  ${Fragments.TenantFragments.fields}
`;

export const CREATE_USER = gql`
  mutation createUser($input: UserInput!) {
    user {
      create(input: $input) {
        ...userFields
      }
    }
  }
  ${Fragments.UserFragments.fields}
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $input: UserInput!) {
    user(id: $id) {
      update(input: $input) {
        ...userFields
      }
    }
  }
  ${Fragments.UserFragments.fields}
`;

export const FETCH_CURRENT_USER = gql`
  query fetchCurrentUser {
    currentUser {
      ...userFields
    }
  }
  ${Fragments.UserFragments.fields}
`;

export const FETCH_REQUIREMENTS = gql`
  query fetchRequirements($query: RequirementQuery) {
    requirements(query: $query) {
      ...requirementFields
      lots {
        ...lotFields
      }
    }
  }
  ${Fragments.RequirementFragments.fields}
  ${Fragments.LotFragments.fields}
`;

export const FETCH_REQUIREMENT = gql`
  query fetchRequirement($query: RequirementQuery!) {
    requirements(query: $query) {
      ...requirementFields
      lots {
        ...lotFields
      }
    }
  }
  ${Fragments.RequirementFragments.fields}
  ${Fragments.LotFragments.fields}
`;

export const FETCH_LOT = gql`
  query fetchLots($query: LotQuery!) {
    lots(query: $query) {
      ...lotFields
    }
  }
  ${Fragments.LotFragments.fields}
`;

export const FETCH_LOTS = gql`
  query fetchLots($query: LotQuery) {
    lots(query: $query) {
      ...lotFields
    }
  }
  ${Fragments.LotFragments.fields}
`;


export const FETCH_PRODUCT_CATEGORIES = gql`
  query fetchProductCategories($query: ProductCategoryQuery) {
    productCategories(query: $query) {
      ...productCategoryFields
    }
  }
  ${Fragments.ProductCategoryFragments.fields}
`;

export const FETCH_PRODUCTS = gql`
  query fetchProducts($query: ProductQuery) {
    products(query: $query) {
      ...productFields
      productPresentations {
        ...productPresentationFields
      }
    }
  }
  ${Fragments.ProductFragments.fields}
  ${Fragments.ProductPresentationFragments.fields}
`

export const FETCH_PRODUCTS_WITH_REQUIREMENTS = gql`
  query fetchProductsWithRequirements {
    products {
      id,
      name
      requirements {
        id
        status
      }
    }
  }
`

export const CREATE_REQUIREMENT = gql`
  mutation createMutation($input: RequirementInput!) {
    requirement {
      create(input: $input) {
        ...requirementFields
      }
    }
  }
  ${Fragments.RequirementFragments.fields}
`;

export const UPDATE_REQUIREMENT = gql`
  mutation updateRequirement($input: RequirementInput!, $id: ID!) {
    requirement(id: $id) {
      update(input: $input) {
        ...requirementFields
      }
    }
  }
  ${Fragments.RequirementFragments.fields}
`;

export const CREATE_LOT = gql`
  mutation createLots($input: LotInput!) {
    lot {
      create(input: $input) {
        ...lotFields
      }
    }
  }
  ${Fragments.LotFragments.fields}
`;

export const UPDATE_LOT = gql`
  mutation updateLots($input: LotInput!, $id: ID!) {
    lot(id: $id) {
      update(input: $input) {
        ...lotFields
      }
    }
  }
  ${Fragments.LotFragments.fields}
`;

export const LOG_IN = gql`
  mutation LogIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      authToken
      email
      firstName
      lastName
      id
      credentials
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID) {
    user(id: $id) {
      destroy
    }
  }
`;

export const DELETE_REQUIREMENT = gql`
  mutation DeleteRequirement($id: ID) {
    requirement(id: $id) {
      destroy
    }
  }
`;

export const DELETE_LOT = gql`
  mutation DeleteLot($id: ID!) {
    lot(id: $id) {
      destroy
    }
  }
`;

export const DELETE_FILE = gql`
  mutation DeleteFile($id: ID!) {
    deleteFile(id: $id) {
      result
    }
  }
`;
export const UPDATE_CLIENT_DETAIL = gql`
  mutation updateClientDetail($id: ID!, $input: ClientDetailInput!) {
    clientDetail(id: $id) {
      update(input: $input) {
        ...clientDetailFields
      }
    }
  }
  ${Fragments.ClientDetailFragments.fields}
`;

export const GET_MESSAGES_FOR_LOT = gql`
  query getMessagesForLot($query: MessageQuery!) {
    messages(query: $query) {
      ...messageFields
    }
  }
  ${Fragments.MessageFragments.fields}
`

export const ADD_MESSAGE_TO_LOT = gql`
  mutation addMessageToLot($input: MessageInput!) {
    message {
      create(input: $input) {
        ...messageFields
      }
    }
  }
  ${Fragments.MessageFragments.fields}
`;

export const SUBSCRIPTION_ADD_MESSAGES_TO_LOT = gql`
  subscription ($lotId: ID!) {
    messageAddedToLot(lotId: $lotId) {
      ...messageFields
    }
  }
  ${Fragments.MessageFragments.fields}
`


export const FETCH_CHAT_ROOM = gql`
query FetchChatRoom($query: ChatRoomQuery!) {
  chatRooms(query: $query) {
    id
    name
    chatMessages {
      id
      createdAt
      body
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
}
`
export const POST_CHAT_MESSAGE = gql`
  mutation PostChatMessage($input: ChatMessageInput!) {
    chatMessage {
      create(input: $input) {
      ...chatMessageFields
      }
    }
  }
  ${Fragments.ChatMessageFragments.fields}
`

export const SUBSCRIPTION_CHAT_UPDATED = gql`
  subscription($roomId: ID!) {
    messageAddedToRoom(roomId: $roomId) {
      ...chatMessageFields
    }
  }
  ${Fragments.ChatMessageFragments.fields}
`

export const SUBSCRIPTION_CHAT_ROOM_UPDATES = gql`
  subscription {
    chatRoomUpdated {
      name
      id
      messageCount
      lastMessage {
        id  
        body
        createdAt
        userId
      }
      creator {
        fullName
        id
      }
    }
  }
`

export const UPDATE_ROOM_LAST_SEEN = gql`
  mutation updateRoomLastSeen($id: ID!) {
    chatRoom(id: $id) {
      updateLastSeenForCurrentUser
    }
  }
`


export const GET_ACTIVE_CHAT_ROOMS = gql`
{
  chatRooms {
    name
    id
    messageCount
    lastSeenForCurrentUser
    lastMessage {
      id  
      body
      createdAt
      userId
    }
    creator {
      fullName
      id
    }
  }
}
`

export const FETCH_ADDRESSES = gql`
  query FetchAddresses($query: AddressQuery) {
    addresses(query: $query) {
      ...addressFields
    }
  }
  ${Fragments.AddressFragments.fields}
`
export const CREATE_ADDRESS = gql`
  mutation CreateAddress($input: AddressInput!) {
    address {
      create(input: $input) {
        ...addressFields
      }
    }
  }
  ${Fragments.AddressFragments.fields}
`


export const UPDATE_ADDRESS = gql`
  mutation UpdateAddress($id: ID!, $input: AddressInput!) {
    address(id: $id) {
      update(input: $input) {
        ...addressFields
      }
    }
  }
  ${Fragments.AddressFragments.fields}
`

export const FETCH_TENANTS = gql`
  query FetchTenants($query: TenantQuery) {
    tenants(query: $query) {
      ...tenantFields
      address {
        ...addressFields
      }
    }
  }
  ${Fragments.TenantFragments.fields}
  ${Fragments.AddressFragments.fields}
`

export const CREATE_TENANT = gql`
  mutation CreateTenant($input: TenantInput!) {
    tenant {
      create(input: $input) {
        ...tenantFields
      }
    }
  }
  ${Fragments.TenantFragments.fields}
`

export const UPDATE_TENANT = gql`
  mutation UpdateTenant($id: ID!, $input: TenantInput!) {
    tenant(id: $id) {
      update(input: $input) {
        ...tenantFields
      }
    }
  }
  ${Fragments.TenantFragments.fields}
`

export const DELETE_TENANT = gql`
  mutation DeleteTenant($id: ID!) {
    tenant(id: $id) {
      destroy
    }
  }
`

export const FETCH_AVALS = gql`
  query FetchAvals($query: AvalQuery) {
    avals(query: $query) {
      ...avalFields
    }
  }
  ${Fragments.AvalFragments.fields}
`

export const CREATE_AVAL = gql`
  mutation CreateTenant($input: AvalInput!) {
    aval {
      create(input: $input) {
        ...avalFields
      }
    }
  }
  ${Fragments.AvalFragments.fields}
`

export const UPDATE_AVAL = gql`
  mutation UpdateAval($id: ID!, $input: AvalInput!) {
    aval(id: $id) {
      update(input: $input) {
        ...avalFields
      }
    }
  }
  ${Fragments.AvalFragments.fields}
`


export const DELETE_AVAL = gql`
  mutation DeleteAval($id: ID!) {
    aval(id: $id) {
      destroy
    }
  }
`

export const FETCH_PROPERTIES = gql`
  query FetchProperties($query: PropertyQuery) {
    properties(query: $query) {
      ...propertyFields
    }
  }
  ${Fragments.PropertyFragments.fields}
`


// export const FETCH_PROPERTIES_WITH_INVOICES = gql`
//   query FetchPropertiesWithInvoices($query: PropertyQuery) {
//     properties(query: $query) {
//       ...propertyFields
//       invoices {
//         ...invoiceLists
//       }
//     }
//   }
//   ${Fragments.PropertyFragments.fields}
// `

export const CREATE_PROPERTY = gql`
  mutation CreateProperty($input: PropertyInput!) {
    property {
      create(input: $input) {
        ...propertyFields
      }
    }
  }
  ${Fragments.PropertyFragments.fields}
`

export const UPDATE_PROPERTY = gql`
  mutation UpdateProperty($id: ID!, $input: PropertyInput!) {
    property(id: $id) {
      update(input: $input) {
        ...propertyFields
      }
    }
  }
  ${Fragments.PropertyFragments.fields}
`


export const DELETE_PROPERTY = gql`
  mutation DeleteProperty($id: ID!) {
    property(id: $id) {
      destroy
    }
  }
`

export const FETCH_INVOICES = gql`
query FetchInvoices($query: InvoiceQuery) {
  invoices(query: $query) {
    ...invoiceFields,
    user {
      ...userFields
      clientDetail {
        id
        address {
          ...addressFields
        }
      }
    }
    address {
      ...addressFields
    }
    policy {
      ...policyFields
    }
    property {
      ...propertyFields
      tenant {
        ...tenantFields
      }
      address {
        ...addressFields
      }
    }
    payments {
      ...paymentFields
    }
  }
}
  ${Fragments.InvoiceFragments.fields}
  ${Fragments.PropertyFragments.fields}
  ${Fragments.UserFragments.fields}
  ${Fragments.AddressFragments.fields}
  ${Fragments.TenantFragments.fields}
  ${Fragments.PaymentFragments.fields}
  ${Fragments.PolicyFragments.fields}
`

export const UPDATE_INVOICE = gql`
  mutation updateInvoice($id: ID!, $input: InvoiceInput!) {
    invoice(id: $id){
      update(input: $input) {
        ...invoiceFields
      }
    }
  }
  ${Fragments.InvoiceFragments.fields}
`

export const CREATE_INVOICE = gql`
  mutation createInvoice($input: InvoiceInput!) {
    invoice {
      create(input: $input) {
        ...invoiceFields
      }
    }
  }
  ${Fragments.InvoiceFragments.fields}
`;


export const DELETE_INVOICE = gql`
  mutation DeleteInvoice($id: ID) {
    invoice(id: $id) {
      destroy
    }
  }
`;



export const CREATE_PAYMENT = gql`
  mutation createPayment($input: PaymentInput!) {
    payment {
      create(input: $input) {
        ...paymentFields
      }
    }
  }
  ${Fragments.PaymentFragments.fields}
`;


export const UPDATE_PAYMENT = gql`
  mutation updatePayment($id: ID!, $input: PaymentInput!) {
    payment(id: $id){
      update(input: $input) {
        ...paymentFields
      }
    }
  }
  ${Fragments.PaymentFragments.fields}
`

export const FETCH_PROSPECTS = gql`
query FetchProspects {
  prospects {
    name
    phone
    email
    createdAt
    rent
  }
}
`

export const CREATE_PROSPECT = gql`
  mutation createProspect($input: ProspectInput!) {
    prospect {
      create(input: $input) {
        id
        createdAt
      }
    }
  }
`


export const UPDATE_PROSPECT = gql`
  mutation createProspect($id: ID!, $input: ProspectInput!) {
    prospect(id: $id) {
      update(input: $input) {
        id
        createdAt
      }
    }
  }
`

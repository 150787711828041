import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  dialog: {
    minWidth: '30vw',
  }
}))

/*
* CustomDialog
* Allows confirmation of actions through modal
* To be placed near root, such as at the API level next to routes
*
* API
* isOpen: Boolean
* title: String
* contentText: String
* handleClose: function
* Actions: Fragment of buttons (eg. Confirmation and Cancelation)
*
* */
const CustomDialog = props => {
  const { isOpen = false, title, contentText, handleClose, Actions } = props
  const classes = useStyles()

  return (
    <Dialog 
      open={isOpen}
      onClose={handleClose}
      className={classes.dialog}
    >
        { isOpen && (
        <React.Fragment>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText> {contentText} </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Actions/>
          </DialogActions>
        </React.Fragment>
        )}
    </Dialog>
  )
}

export default CustomDialog

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from '@apollo/react-hooks';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
  LinearProgress
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';


import { UPDATE_USER } from 'constants/queries';
import { StoreContext } from 'App';
import { LoadingBar } from 'components'

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
    cursor: 'pointer'
  },
  avatarForm: {
    display: 'none'
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  }
}));

const UserProfile = props => {
  const { className, user, ...rest } = props;
  const [image, setImage] = useState();
  const [displayImage, setDisplay] = useState();
  const [isEditing, setEditing] = useState(false);
  const classes = useStyles();
  const context = useContext(StoreContext);
  const [updateAvatar, responseState] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      context.state.showAlert({
        severity: 'success',
        message: 'Foto Actualizada'
      });
      setEditing(false);
    }
  });

  const changeImage = event => {
    setImage(event.target.files[0]);
    const reader = new FileReader();
    reader.onload = e => {
      setDisplay(e.target.result);
      // setImage(e.target.result);
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  const { t, i18n } = useTranslation();


  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <form className={classes.avatarForm}>
          <input id="avatar-img" type="file" onChange={changeImage} />
        </form>
        <div className={classes.details}>
          <div>
            <Typography gutterBottom variant="h2">
              {`${user.firstName} ${user.lastName}`}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1">
              Monterrey, MEX
            </Typography>
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1">
              {moment().format('hh:mm A')} GMT-7
            </Typography>
          </div>
          <Avatar
            className={classes.avatar}
            src={isEditing ? displayImage : user.avatar}
          />
        </div>
        <div className={classes.progress}>
          {/* <Typography variant="body1">{t("complete_profile")}: 70%</Typography> */}
          {/* <LinearProgress value={70} variant="determinate" /> */}
        </div>
      </CardContent>
      <Divider />
      <CardActions>
        {!isEditing && (
          <Button
            onClick={() => {
              setEditing(true);
              document.querySelector('#avatar-img').click();
            }}>
            {t("change_photo")}
          </Button>
        )}
        {isEditing && (
          <React.Fragment>
            <Button
              onClick={() =>
                updateAvatar({
                  variables: { id: user.id, input: { avatar: image } }
                })
              }
              className={classes.uploadButton}
              disabled={responseState.loading}
              color="primary"
              variant="text">
              Guardar Foto
            </Button>
              {!responseState.loading && (
                <Button variant="text" onClick={() => setEditing(false)}>
                  Cancelar
                </Button>
              )}
          </React.Fragment>
        )}
      </CardActions>
      {responseState.loading && <LoadingBar/>}
    </Card>
  );
};

UserProfile.propTypes = {
  className: PropTypes.string
};

export default UserProfile;

export const PersonTypes = [
  {label: 'physical', value: 'physical'},
  {label: 'moral', value: 'moral'}
]

export const PropertyUse = [
  {label: 'commercial', value: 'commercial'},
  {label: 'home', value: 'home'}
]

export const PaymentFrequency = [
  {label: 'monthly', value: 'monthly'},
  {label: 'bimestral', value: 'bimestral'},
  {label: 'trimestral', value: 'trimestral'},
  {label: 'semestral', value: 'semestral'},
  {label: 'yearly', value: 'yearly'}
]

export const TaxOptions = [
  {label: '0%', value: 'no'},
  {label: '16%', value: 'yes'}
]

export const PolicyTypes = [
  {label: 'essential', value: 'essential'},
  {label: 'basic', value: 'basic'},
  {label: 'ample', value: 'ample'},
  {label: 'premium', value: 'premium'},
  {label: 'guarantee', value: 'guarantee'},
  {label: 'legal_guarantee', value: 'legal_guarantee'}
]

export const HasAval = [
  {label: 'yes', value: 'yes'},
  {label: 'no', value: 'no'}
]

export const PaymentMethods = [
  {label: 'deposit', value: 'deposit'},
  {label: 'cash', value: 'cash'},
  {label: 'transfer', value: 'transfer'}
]

export const DepositOptions = [
  {label: 'one_month', value: 'one_month'},
  {label: 'two_month', value: 'two_months'},
  {label: 'three_month', value: 'three_months'},
]

import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next'
import { useHistory } from  'react-router-dom'
import { Button} from '@material-ui/core'

import Helpers from 'lib/helpers'
import  { StoreContext } from 'App'
import { Can } from 'lib/can'
import { List, Loader } from 'components';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { FETCH_USERS, DELETE_USER } from 'constants/queries';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Actions = ({prop}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const context = useContext(StoreContext)
  const classes = useStyles()
  const [deleteUser, deletionStatus] = useMutation(DELETE_USER, {
    onCompleted: () => {
      context.state.hideDialog()
      context.state.showAlert({
        severity: 'warning',
        message: t('x_deleted', {x: t('user')})
      })
      window.location.reload();
    }
  })

  const confirmDeletion = () => {
    deleteUser({variables: {id: prop.id}})
  }

  return (
  <React.Fragment>
    <Button
      color='primary'
      variant='contained'
      style={{margin: '0.25em'}}
      onClick={() => history.push('/users/view/' + prop.id)}
    >
      {t('action_see')}
    </Button>

    <Can I='destroy' this='user'>
      <Button
        color='primary'
        variant='contained'
        style={{margin: '0.25em'}}
        onClick={ () => {
          context.state.showDialog({
           title: t('confirm_deletion'),
           contentText: t('are_you_sure_delete?'),
           actions: () => {
            return (
              <React.Fragment>
                <Button className={classes.dangerButton} disabled={deletionStatus.loading} color='primary' variant='contained' onClick={confirmDeletion}>{t('im_sure')} </Button>
                <Button  color='secondary' variant='contained' onClick={context.state.hideDialog}>{t('cancel')} </Button>
              </React.Fragment>
            )
           }
          })
        }}
      >
        {t('delete')}
      </Button>
    </Can>
  </React.Fragment>
  )
}


const CreateUser = () => {
        const history = useHistory();
        const { t } = useTranslation()
        return (
        <Can I='create' a='user'>
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.push('/users/create')}>
            {t('add_user')}
          </Button>
        </Can>
        )
}


const UserList = (props) => {
  const classes = useStyles();
  const { data } = useQuery(FETCH_USERS, { fetchPolicy: 'network-only', ...props.variables ? {variables: props.variables} : {} });
  const { t } = useTranslation()


  const headCells = [
    {
        id: 'id',
        numeric: true,
        label: t('id'),
        searchable: true,
        disablePadding: true,
        fn: p => parseInt(p.id),
        display: p => '#' + p.id
    },
    {
      id: 'fullName',
      numeric: false,
      label: t('fullName'),
      numeric: false,
      searchable: true,
      disablePadding: true,
      fn: p => p.fullName,
      display: p => p.fullName
  },
  {
      id: 'email',
      numeric: false,
      label: t('email'),
      numeric: false,
      searchable: true,
      disablePadding: true,
      fn: p => t(p.email),
      display: p => t(p.email),
  },
  {
      id: 'phone',
      numeric: false,
      label: t('phone'),
      searchable: true,
      disablePadding: true,
      fn: p => p.phone,
      display: p => p.phone
  },
    {
      id: 'credentials',
      numeric: false,
      label: t('credentials'),
      numeric: false,
      searchable: false,
      disablePadding: true,
      fn: p => Helpers.capitalize(t(p.credentials)),
      display: p => t(p.credentials)
    },
    {
      id: 'actions',
      numeric: false,
      label: t('actions'),
      disablePadding: true,
      searchable: false,
      display: prop => <Actions prop={prop}/>
    }
  ];

  if (data) {
    return (
    <List
      label={t('users')}
      subject={data.users}
      headCells={headCells}
      HeaderActions={() => <CreateUser/>}
    />
    );
  }
        return <Loader/>
};

export default UserList;

import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import ImageIcon from '@material-ui/icons/Image';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import AirlineSeatFlatIcon from '@material-ui/icons/AirlineSeatFlat';
import FaceIcon from '@material-ui/icons/Face';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { useTranslation } from 'react-i18next';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { Profile, SidebarNav, UpgradePlan } from './components';

import {StoreContext} from 'App'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;
  const { t } = useTranslation();

  const context = useContext(StoreContext)
  const classes = useStyles();

  const pages = [
    {
      title: t('myUser'),
      href: `/users/view/${context.state?.session?.id}`,
      icon: <AccountCircleIcon/>
    },
    {
      title: t('users'),
      href: '/users',
      icon: <PeopleIcon />
    },
    {
      title: t('clients'),
      href: '/clients',
      icon: <FaceIcon />
    }, {
      title: t('properties'),
      href: '/properties',
      icon: <HomeWorkIcon/>
    },
    {
      title: t('tenants'),
      href: '/tenants',
      icon: <AirlineSeatFlatIcon/>
    },
    {
      title: t('policies'),
      href: '/policies',
      icon: <AccountBalanceWalletIcon/>
    },
    {
      title: t('invoices'),
      href: '/invoices',
      icon: <AttachMoneyIcon />
    },
    {
      title: t('prospects'),
      href: '/prospects',
      icon: <ContactMailIcon/>
    },
    {
      title: t('log_in'),
      href: '/sign-in',
      icon: <LockOpenIcon />
    },
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;

import React, { useState } from 'react';
import { UserList } from 'views'

const ClientList = () => {

    const variables = {
      query: {credentials: "client"}
    }

  return (
    <UserList variables={variables}/>
  )
};

export default ClientList;

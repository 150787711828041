import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/react-hooks'

import { options } from './chart';
import palette from 'theme/palette'
import { FETCH_PRODUCTS_WITH_REQUIREMENTS } from 'constants/queries'
import { Loader } from 'components'

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestSales = props => {
  const { className, ...rest } = props;
  const { t } = useTranslation();

  const classes = useStyles();
  const { data, loading, error} = useQuery(FETCH_PRODUCTS_WITH_REQUIREMENTS)

  if(data) {
    const topFiveProducts = data.products.sort((a,b) => b.requirements.length - a.requirements.length).slice(0,5);
    let chartData = {
      labels: topFiveProducts.map(e => e.name.length > 15 ? (e.name.substr(0,12) + '...') : e.name.substr(0,15)),
      datasets: [
        {
          label: t('complete'),
          backgroundColor: palette.primary.main,
          data: topFiveProducts.map(p => p.requirements.filter(r => r.status == 'inactive').length)
        },
        {
          label: t('incomplete'),
          backgroundColor: palette.neutral,
          data: topFiveProducts.map(p => p.requirements.filter(r => r.status == 'active').length)
        }
      ]
    }
  
    return (
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <Button size="small" variant="text">
                { t('last_x_days', {num: 7}) }
                <ArrowDropDownIcon />
              </Button>
            }
            title={t('most_sought_products')}
          />
          <Divider />
          <CardContent>
            <div className={classes.chartContainer}>
              <Bar data={chartData} options={options} />
            </div>
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Button color="primary" size="small" variant="text">
                { t('overview') }
              <ArrowRightIcon />
            </Button>
          </CardActions>
        </Card>
      )
  }
  else
    return <Card>
      <CardContent>
        <Loader/>
      </CardContent>
    </Card>
};

LatestSales.propTypes = {
  className: PropTypes.string
};

export default LatestSales;

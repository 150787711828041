import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useForm, Controller, FormProvider, useFormContext } from 'react-hook-form';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Paper,
  Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next'
import { subject } from '@casl/ability';


import DefineAbilityFor from 'constants/abilities';
import { arraySub } from 'lib/helpers'
import { LoadingBar, FileBox, FileCardList, Select } from 'components';
import { StoreContext } from 'App'
import { CREATE_TENANT, UPDATE_TENANT } from 'constants/queries'
import { PersonTypes, HasAval } from 'constants/formOptions'
import { 
  AvalFields, getAvalAttributes, avalDirtyFields,
} from 'views'

const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '90%'
  },
  textField: {
    padding: '1.3em'
  }
}));

export const CWDirtyFields = [ 'writingNumber', 'notarykey', 'publicNotary', 'notaryCity', 'notaryName', 'registryData', 'legalRepresentative', 'writingDate', 'registrationDate' ]
export const getCWFields = data => ({
    writingNumber: data.writingNumber,
    publicNotary: data.publicNotary,
    notaryCity: data.notaryCity,
    notaryName: data.notaryName,
    registryData: data.registryData,
    legalRepresentative: data.legalRepresentative,
    writingDate: data.writingDate,
    registrationDate: data.registrationDate
})

export const CWFields = (params) => {
  const { register, control, tenant, fieldsEnabled, errors, setValue, classes } = params
  const { t } = useTranslation()

  // constitutive writing
  const Fields = [
    {key: 'writingNumber', type: 'text' },
    {key: 'publicNotary', type: 'text' },
    {key: 'notaryCity', type: 'text' },
    {key: 'notaryName', type: 'text' },
    {key: 'registryData', type: 'text' },
    {key: 'writingDate', type: 'date' },
    {key: 'registrationDate', type: 'date' }
  ]

  return (
    <React.Fragment>
        {Fields.map((f) => (
        <Grid item md={4} xs={6}>
          <TextField
            fullWidth
            required
            name={f.key}
            type={f.type}
            label={t(f.key)}
            defaultValue={ tenant ? tenant[f.key] : null}
            disabled={!fieldsEnabled}
            margin="dense"
            variant="outlined"
            inputRef={register({ required: true })}
            error={errors[f.key]}
            helperText={errors[f.key] && t(errors[f.key].type)}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
      ))}
    </React.Fragment>
  )
}


const attachedDocuments = [ 'renterId', 'renterResearch',  'request', 'privacy', 'constitutiveWriting', 'fiscalNumber', 'power', 'avalIdFile', 'avalResearch', 'avalProperty']
const avalDocuments = ['avalIdFile', 'avalResearch', 'avalProperty']
const moralPersonDocuments = ['constitutiveWriting', 'power', 'fiscalNumber']

export const tenantDirtyFields = [
  'personType',
  'firstName',
  'lastName',
  'socialReason',
  'phone',
  'mail',
  'rfc',
  'others',
  'hasAval',
  'legalRepresentativeFirstName',
  'legalRepresentativeLastName',
  'legalRepresentativeEmail',
  'legalRepresentativePhone',
  'legalRepresentativeRfc',
  ...attachedDocuments
]

export const getTenantFields = (data) => ({
  personType: data.personType,
  firstName: data.firstName,
  lastName: data.lastName,
  socialReason: data.socialReason,
  phone: data.phone,
  mail: data.mail,
  rfc: data.rfc,
  others: data.others,
  legalRepresentativeFirstName: data.legalRepresentativeFirstName,
  legalRepresentativeLastName: data.legalRepresentativeLastName,
  legalRepresentativeEmail: data.legalRepresentativeEmail,
  legalRepresentativePhone: data.legalRepresentativePhone,
  legalRepresentativeRfc: data.legalRepresentativeRfc,
  renterId: data.renterId,
  renterResearch: data.renterResearch,
  request: data.request,
  privacy: data.privacy,
  constitutiveWriting: data.constitutiveWriting,
  fiscalNumber: data.fiscalNumber,
  power: data.power,
  avalIdFile: data.avalIdFile,
  avalResearch: data.avalResearch,
  avalProperty: data.avalProperty,
  ...(data.hasAval == 'no' ? {avalId: null} : {}),
})


export const TenantFields = (props) => {

  const { tenant } = props
  const { register, control, fieldsEnabled, errors, setValue, watch, classes} = useFormContext()
  const { t } = useTranslation()
  const personType = watch('personType', tenant ? PersonTypes.find(e => e.value == tenant.personType).value : null)
  let documents = attachedDocuments
  const watchAll = watch()
  const context = useContext(StoreContext)
  const ability = DefineAbilityFor(context.state?.session)

  if(personType != 'moral') documents = arraySub(documents, moralPersonDocuments)

  const PhysicalFields = () => {
    return <React.Fragment>
      <Grid item xs={4}>
        <TextField
          defaultValue={tenant ? tenant.firstName : null}
          fullWidth
          disabled={!fieldsEnabled}
          label={t('firstName')}
          margin="dense"
          name="firstName"
          required
          variant="outlined"
          inputRef={register({ required: true })}
          error={errors.firstName}
          helperText={errors.firstName && t(errors.firstName.type)}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          defaultValue={tenant ? tenant.lastName : null}
          fullWidth
          disabled={!fieldsEnabled}
          label={t('firstName')}
          margin="dense"
          name="lastName"
          required
          variant="outlined"
          inputRef={register({ required: true })}
          error={errors.lastName}
          helperText={errors.lastName && t(errors.lastName.type)}
        />
      </Grid>
    </React.Fragment>
  }

  const MoralFields = () => {
    return <React.Fragment>

      <Grid item xs={12}>
        <Typography>{t('legalRepresentative')}</Typography>
      </Grid>
      <Grid item xs={3}>
        <TextField
          defaultValue={tenant ? tenant.legalRepresentativeFirstName : null}
          fullWidth
          disabled={!fieldsEnabled}
          label={t('firstName')}
          margin="dense"
          name="legalRepresentativeFirstName"
          required
          variant="outlined"
          inputRef={register({ required: true })}
          error={errors.legalRepresentativeFirstName}
          helperText={errors.legalRepresentativeFirstName && t(errors.legalRepresentativeFirstName.type)}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          defaultValue={tenant ? tenant.legalRepresentativeLastName : null}
          fullWidth
          disabled={!fieldsEnabled}
          label={t('lastName')}
          margin="dense"
          name="legalRepresentativeLastName"
          required
          variant="outlined"
          inputRef={register({ required: true })}
          error={errors.legalRepresentativeLastName}
          helperText={errors.legalRepresentativeLastName && t(errors.legalRepresentativeLastName.type)}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          defaultValue={tenant ? tenant.legalRepresentativeTelephone : null}
          fullWidth
          disabled={!fieldsEnabled}
          label={t('telephone')}
          margin="dense"
          name="legalRepresentativeTelephone"
          required
          variant="outlined"
          inputRef={register({ required: true })}
          error={errors.legalRepresentativeTelephone}
          helperText={errors.legalRepresentativeTelephone && t(errors.legalRepresentativeTelephone.type)}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          defaultValue={tenant ? tenant.legalRepresentativeEmail : null}
          fullWidth
          disabled={!fieldsEnabled}
          label={t('email')}
          margin="dense"
          name="legalRepresentativeEmail"
          required
          variant="outlined"
          inputRef={register({ required: true })}
          error={errors.legalRepresentativeEmail}
          helperText={errors.legalRepresentativeEmail && t(errors.legalRepresentativeEmail.type)}
        />
      </Grid>

    </React.Fragment>
  }

  useEffect(() => {
    attachedDocuments.forEach(register)
    register('others')

  }, [])

debugger

  return (
    <React.Fragment>
      <Grid item md={6}>
        <FormControl
          variant="outlined"
          error={errors.personType}
          style={{margin: '0', width: '100%'}}
          className={classes.formControl}>
          <Controller
            defaultValue={tenant && PersonTypes.find(e => e.value == tenant.personType).value}
            name="personType"
            control={control}
            rules={{required: true}}
            as={({ value, onChange }) => (
              <Autocomplete
                disabled={!fieldsEnabled}
                id="personType"
                value={value && value.label ? value : PersonTypes.find(e => e.value == value)}
                onChange={(_, {value}) => setValue( 'personType', value) }
                options={PersonTypes.map(p => ({...p, label: t(p.label)}))}
                getOptionLabel={option => t(option.label)}
                renderInput={params => (
                  <TextField
                    {...params}
                    disabled={!fieldsEnabled}
                    required={true}
                    error={errors.personType}
                    label={t('personType')}
                    variant="outlined"
                  />
                )}
              />
            )}
          />
          <FormHelperText>
            {errors.personType && t(errors.personType.type)}
          </FormHelperText>
        </FormControl>
      </Grid>


      <Grid item xs={6}>
        <Select 
          name='hasAval'
          fieldsDisabled={!fieldsEnabled}
          subject={{...tenant, hasAval: tenant?.hasAval ? 'yes' : 'no'}}
          control={control}
          options={HasAval.map(p => ({...p, label: t(p.label)}))}
          sv={setValue}
          label={t('hasAval')}
          errors={errors}
        />
      </Grid>


        {watchAll.personType == 'physical' && (
          <React.Fragment>
            <Grid item xs={3}>
              <TextField
                defaultValue={tenant ? tenant.firstName : null}
                fullWidth
                disabled={!fieldsEnabled}
                label={t('firstName')}
                margin="dense"
                name="firstName"
                required
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.firstName}
                helperText={errors.firstName && t(errors.firstName.type)}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                defaultValue={tenant ? tenant.lastName : null}
                fullWidth
                disabled={!fieldsEnabled}
                label={t('lastName')}
                margin="dense"
                name="lastName"
                required
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.lastName}
                helperText={errors.lastName && t(errors.lastName.type)}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                defaultValue={tenant ? tenant.mail : null}
                fullWidth
                disabled={!fieldsEnabled}
                label={t('mail')}
                margin="dense"
                name="mail"
                required
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.mail}
                helperText={errors.mail && t(errors.mail.type)}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                defaultValue={tenant ? tenant.phone : null}
                fullWidth
                disabled={!fieldsEnabled}
                label={t('phone')}
                margin="dense"
                name="phone"
                required
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.phone}
                helperText={errors.phone && t(errors.phone.type)}
              />
            </Grid>

          </React.Fragment>
        ) } 

        { watchAll.personType == 'moral' && (

          <React.Fragment>

            <Grid item xs={3}>
              <TextField
                defaultValue={tenant ? tenant.socialReason : null}
                fullWidth
                disabled={!fieldsEnabled}
                label={t('socialReason')}
                margin="dense"
                name="socialReason"
                required
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.socialReason}
                helperText={errors.socialReason && t(errors.socialReason.type)}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                defaultValue={tenant ? tenant.rfc : null}
                fullWidth
                disabled={!fieldsEnabled}
                label={t('rfc')}
                margin="dense"
                name="rfc"
                required
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.rfc}
                helperText={errors.rfc && t(errors.socialReason.type)}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography>{t('legalRepresentative')}</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                defaultValue={tenant ? tenant.legalRepresentativeFirstName : null}
                fullWidth
                disabled={!fieldsEnabled}
                label={t('firstName')}
                margin="dense"
                name="legalRepresentativeFirstName"
                required
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.legalRepresentativeFirstName}
                helperText={errors.legalRepresentativeFirstName && t(errors.legalRepresentativeFirstName.type)}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                defaultValue={tenant ? tenant.legalRepresentativeLastName : null}
                fullWidth
                disabled={!fieldsEnabled}
                label={t('lastName')}
                margin="dense"
                name="legalRepresentativeLastName"
                required
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.legalRepresentativeLastName}
                helperText={errors.legalRepresentativeLastName && t(errors.legalRepresentativeLastName.type)}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                defaultValue={tenant ? tenant.legalRepresentativePhone : null}
                fullWidth
                disabled={!fieldsEnabled}
                label={t('phone')}
                margin="dense"
                name="legalRepresentativePhone"
                required
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.legalRepresentativePhone}
                helperText={errors.legalRepresentativePhone && t(errors.legalRepresentativePhone.type)}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                defaultValue={tenant ? tenant.legalRepresentativeEmail : null}
                fullWidth
                disabled={!fieldsEnabled}
                label={t('mail')}
                margin="dense"
                name="legalRepresentativeEmail"
                required
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.legalRepresentativeEmail}
                helperText={errors.legalRepresentativeEmail && t(errors.legalRepresentativeEmail.type)}
              />
            </Grid>

          </React.Fragment>
        )}
      

        {watchAll.hasAval == 'yes' && (
        <React.Fragment>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
              { t('aval') }
            </Typography>
          </Grid>
          <AvalFields aval={tenant?.aval}/>
        </React.Fragment>
        )}
      
        {personType == 'moral' && (
        <React.Fragment>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
                { t('tenantConstitutiveWriting') }
            </Typography>
          </Grid>
          <CWFields { ...{ control, tenant, fieldsEnabled, errors, setValue, classes, register }} />
        </React.Fragment>
        )}
      <Grid item xs={12}>
        <Typography variant='subtitle1'>
            { t('tenantAttachedDocuments') }
        </Typography>
      </Grid>

      {documents.map(key => (
        <Grid item md={4} xs={6} key={key}>
          <FileBox 
            label={t(key)}
            onSave={(file) => setValue(key, file)}
            fileLink={ tenant && tenant[key] }
            fileEditable={ability.can('edit', 'file')}
            fileDeletable={ability.can('delete', 'file')}
          />
        </Grid>
      ))}

      <Grid item md={4} xs={6}>
        <FileBox
          filesLimit={3}
          label={t('others')}
          onSave={(files) => setValue('others', files)}
          fileLink={ tenant && tenant.others}
          fileEditable={ability.can('edit', 'file')}
          fileDeletable={ability.can('delete', 'file')}
        />
      </Grid>

        {tenant?.others?.length > 0  && (
          <Grid item xs={12}>
            <Paper style={{padding: '1em', backgroundColor: '#fafafa'}}>
             <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant='h4'>{t('others')}</Typography>
                </Grid>
                <FileCardList FileLinks={tenant.others}/>
              </Grid>
            </Paper>
          </Grid>
        )}

    </React.Fragment>
  )
}


const TenantForm = (props) => {
  const { tenant, onCompleted, onError, fieldsEnabled, ...rest} = props
  const classes = useStyles()
  const context = useContext(StoreContext)

  const { t } = useTranslation()
  const methods = useForm({
    defaultValues: {
      hasAval: !!tenant?.aval?.id ? 'yes' : 'no',
      personType: tenant?.personType,
      aval: {
        personType: tenant?.aval?.personType
      }
    }
  })

  console.log(methods.formState.isDirty) //making sure it's read before render to enable proxy
  const [mutate, mutationStatus] = useMutation(tenant ? UPDATE_TENANT : CREATE_TENANT, { onCompleted, onError })

  const onSubmit = ({...data}) => {

    let payload = {variables: {}}
    if(tenant) payload.variables.id = tenant.id

    if(data.hasAval == 'no') data.avalId = null;
    const cwFields = getCWFields(data)
    const avalAttributes = data.aval ? getAvalAttributes(data) : {}
    const tenantAttributes = getTenantFields(data)
    CWDirtyFields.forEach(key => delete data[key])
    tenantDirtyFields.forEach(key => delete data[key])
    delete data.aval

    payload.variables.input = { 
      ...tenantAttributes,
      ...cwFields,
      ...(tenantAttributes.avalId !== null ? {
            avalAttributes: {
              ...avalAttributes,
              ...(tenant.aval?.id ? {id: tenant.aval.id} : {})
            }
         } : {}),
      userId: context.state.session.id
    }
    mutate(payload)
  }

  return (
    <Card {...rest} className={classes.root}>
      <FormProvider {...methods} fieldsEnabled={fieldsEnabled} classes={classes}>
        <form autoComplete='off' noValidate onSubmit={methods.handleSubmit(onSubmit)}>
          <CardHeader title={`${t('tenant')} ${tenant ? '#' + tenant.id : null}`} subheader={fieldsEnabled ? t('information_editable') : ''}/>
          <Divider/>
          <CardContent>
            <Grid container spacing={3}>
              <TenantFields  tenant={tenant}/>
            </Grid>
          </CardContent>
          <Divider/>
          <CardActions>
            {fieldsEnabled && (
              <Button color='primary' variant='contained' type='submit' disabled={mutationStatus.loading}>
                  {`${tenant ? t('update') : t('create')} ${t('tenant')}`}
              </Button>
            )}
          </CardActions>
        </form>
      </FormProvider>
      { mutationStatus.loading && <LoadingBar/> }
    </Card>
  )
}

export default TenantForm

import React, { useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Button,
  Typography,
  Divider,
  Paper
} from '@material-ui/core';

import { SUBSCRIPTION_ADD_MESSAGES_TO_LOT } from 'constants/queries';
import { StoreContext } from 'App'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '1em',
    overflow: 'scroll',
    maxHeight: '30rem'
  },
  message: imAuthor =>  ({
    background: imAuthor ? theme.palette.primary.main : theme.palette.divider,
    padding: '1em',
    marginBottom: '1em',
    borderRadius: '5%',
    color: imAuthor ? theme.palette.primary.contrastText : theme.palette.black
  }),
  container: imAuthor => ({
    display: 'flex',
    flexDirection: imAuthor ? 'row-reverse' : 'inherit'
  }),
}))

const handleNewMessages = lotId => ({
  document: SUBSCRIPTION_ADD_MESSAGES_TO_LOT,
  variables: { lotId },
  updateQuery(prev, { subscriptionData }) {
    if (!subscriptionData.data) return prev;
    return {
      messages: [...prev.messages, subscriptionData.data.messageAddedToLot]
    };
  }
});

const Message = ({imAuthor, message}) => {
  const classes = useStyles(imAuthor);
  return <div className={classes.container}>
    <Typography className={classes.message} key={message.id} variant="h6">
      {message.body}
    </Typography>
  </div>
}
const MessageList = ({ lotId, data, loading, subscribeToMore }) => {
  const chatBottom = useRef(null)
  const box = useRef(null)

  useEffect(() => subscribeToMore(handleNewMessages(lotId)), [lotId]);
  useEffect(() => {
    chatBottom.current.scrollIntoView();
    // shouldScroll = box.scrollTop + box.clientHeight === box.scrollHeight;
    // box.scrollTop = box.scrollHeight;
  }, [data.messages] )
  const context = useContext(StoreContext)
  const classes = useStyles()
  return (
    <Paper className={classes.paper} ref={box}>
        {data.messages.map(m => {
          const imAuthor = m.user.id == context.state.session.id;
          return <Message message={m} imAuthor={imAuthor} />
        })}
        <div style={{float: 'left', clear: 'both'}} ref={chatBottom}/>
    </Paper>
  );
};

export default MessageList;

export const textOrNull = text => text ? text : ''

const _ = textOrNull

const stringIfExists = (obj, string) => obj ? string : ''

export const parseAddress = (address, t = (_) => _) => address ? 
`
${stringIfExists(address?.street, `${_(address?.street)}, `)}
${stringIfExists(address?.extNum, `#${_(address?.extNum)}, `)}
${stringIfExists(address?.intNum, `Int #${_(address?.intNum)}, `)}
${stringIfExists(address?.colony, `Col ${_(address?.colony)}, `)}
${stringIfExists(address?.city, `${_(address?.city)}, `)}
${stringIfExists(address?.state, `${t(address?.state)}, `)}
${stringIfExists(address?.zipCode, `${t(address?.zipCode)}`)}
`
  : _('no_address')


export const sortByDateFn = (a, b) =>
  new Date(b.createdAt) - new Date(a.createdAt);


export const arraySub = (arr1, arr2) =>
  arr1.filter( el => !arr2.includes(el) )

export const parseCurrency = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

export default {
  parseCurrency: x => (
      x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  ),
  numberWithCommas: x => (
      x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  ),
  translateStatus: words => {
    if (!words) return;
    const capitalize = word => word.charAt(0).toUpperCase() + word.substring(1);
    return words
      .match(/[A-Za-z][a-z]*/g)
      .map(capitalize)
      .join(' ');
  },
  createKeyHandler: options => e => {
    if (!options.targets.some(i => i === e.target)) return;
    e.preventDefault();
    options[e.which] && options[e.which](e);
  },
  sortByDate: array => array.splice().sort(sortByDateFn),
  localeDateString: date => {
    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
    return date.toLocaleDateString('es-MX', options);
  },
  capitalize: string =>
    string && string.replace(/^\w/, chr => chr.toUpperCase()),
  phoneRegex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
  emailRegex: /\w*@\w*\.\w*/,
  getHelpChatName: (userId) => `${userId}_help_chat`,
  truncate: (string, len) =>
    string.length > len ? string.substr(0, len-3) + '...' : string
};

export class Invoice {

  constructor(invoice) {
    this.import = invoice.subtotal
    this.discount = invoice.discount
    this.tax = invoice.tax / 100;
    this.riskInterest = invoice.riskInterest

  }

  getImport() {
    return this.import;
  }

  getDiscount() {
    return this.discount
  }

  getSubtotal() {
    return this.import - this.discount + this.riskInterest;
  }

  getTaxedAmount() {
    return this.getSubtotal()*this.tax
  }

  getTotal() {
    return (this.getSubtotal() + this.getTaxedAmount()).toFixed(2)
  }
  
}
// export Invoice

export const calculateYearlyPolicyCost = (type, cost) => {
  switch(type) {
    case 'essential':
      return  cost <= 10000 ? 2500 : cost <= 30000 ? 3600 : cost * 0.13;
    case 'basic':
      return  cost <= 10000 ? 4500 : cost <= 30000 ? 6200 : cost * 0.21;
    case 'ample':
      return  cost <= 10000 ? 6400 : cost <= 30000 ? 9200 : cost * 0.28;
    case 'premium':
      return  cost <= 10000 ? 8200 : cost <= 30000 ? 11900 : cost * 0.44;
    case 'guarantee':
      return  cost * 0.06 * 12
    case 'legal_guarantee':
      return  cost <= 12000 ? 5200 : cost <= 21000 ? 7100 : cost <= 30000 ? 9100 : cost * 0.40;
    default: 
      return 999999;
  }
}

import React, { useContext, useState, useEffect } from 'react';
import gql from 'graphql-tag'
import { makeStyles } from '@material-ui/styles';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useForm, Controller, FormProvider, useFormContext} from 'react-hook-form';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next'
import { DropzoneArea, DropzoneDialog } from 'material-ui-dropzone';
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { LoadingBar, Loader, FileCard, Select} from 'components';
import {
AddressFields,
getAddressAttributes,
addressDirtyFields,
TenantFields, getTenantFields, tenantDirtyFields,
AvalFields, getAvalAttributes, avalDirtyFields,
CWDirtyFields, getCWFields,
} from 'views'
import { StoreContext } from 'App'
import { CREATE_PROPERTY, UPDATE_PROPERTY } from 'constants/queries'
import {
  PersonTypes,
  PropertyUse,
  PaymentFrequency,
  TaxOptions,
  PolicyTypes,
  DepositOptions,
  PaymentMethods
} from 'constants/formOptions'
import * as Fragments from 'constants/fragments'


const useStyles = makeStyles(theme => ({
  root: {},
  dropZoneButton: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      bockgroundColor: theme.palette.success.contrastText
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '90%'
  },
  textField: {
    padding: '1.3em'
},
  centeringWrapper: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  }
}));

const FETCH_CREATION_DATA = gql`
  query FetchCreationData($userQuery: UserQuery) {
    users(query: $userQuery) {
      ...userFields
    }
    tenants {
      ...tenantFields
    }
  }
  ${Fragments.TenantFragments.fields}
  ${Fragments.UserFragments.fields}
`

const TextFieldGenerator = ({property, name, register, errors, type, fieldsEnabled}) =>{
    const { t } = useTranslation()
    return <TextField
      fullWidth
      required
      defaultValue={property ? property[name] : null}
      disabled={!fieldsEnabled}
      label={t(name)}
      margin="dense"
      name={name}
      type={type}
      variant="outlined"
      inputRef={register({ required: true })}
      error={errors[name]}
      helperText={errors[name] && t(errors[name].type)}
      InputLabelProps={{
        shrink: true
      }}
    />
}

export const propertyDirtyFields = [ 'region', 'city', 'colony', 'extNum', 'intNum', 'zipCode', 'street' ]

export const getPropertyAttributes = data => ({
    state: data.region,
    city: data.city,
    colony: data.colony,
    extNum: data.extNum,
    intNum: data.intNum,
    zipCode: data.zipCode,
    street: data.street,
  }
)

export const policyDirtyFields = ['policy.policyType', 'policy.startDate', 'policy.endDate', 'policy' ]


export const getPolicyAttributes = data => ({
  policyType: data.policy.policyType,
  startDate: data.policy.startDate,
  endDate : data.policy.endDate,
})


const PropertyPolicyFields = params => {
  const classes = useStyles()
  const {t} = useTranslation()
  const [rentUpdated, setRentUpdated] = useState(false)
  const {policy} = params
  const {
    register,
    control,
    setValue,
    errors,
    watch,
    fieldsEnabled,
  } = useFormContext()
  const watchAll = watch()


  return (
    <React.Fragment>
        <Grid item xs={6}>
          <Select
            name='policy.policyType'
            fieldsDisabled={!fieldsEnabled}
            getDefaultMatcher={(option, subject) => option == subject.policyType}
            subject={policy}
            control={control}
            options={PolicyTypes.map(p => ({...p, label: t(p.label)}))}
            sv={setValue}
            errors={errors}
            label={t('policyType')}
            required={true}
          />
        </Grid>

        <Grid item xs={6}></Grid>


        <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              defaultValue={policy ? moment(policy.startDate).format('YYYY-MM-DD') : null}
              disabled={!fieldsEnabled}
              label={t('startDate') + ' (DD/MM/YYYY)'}
              margin='dense'
              name='policy.startDate'
              type='date'
              format='DD/MM/YYYY'
              inputRef={register({required: true })}
              error={errors['policy.startDate']}
              helperText={errors['policy.startDate'] && t(errors['policy.startDate'].type)}
              variant='outlined'
              InputLabelProps={{
                shrink: true
              }}
            />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            defaultValue={policy ? moment(policy.endDate).format('YYYY-MM-DD') : null}
            disabled={!fieldsEnabled}
            label={t('endDate') + ' (DD/MM/YYYY)'}
            margin='dense'
            name='policy.endDate'
            type='date'
            format='DD/MM/YYYY'
            inputRef={register({required: true })}
            error={errors['policy.endDate']}
            helperText={errors['policy.endDate']  && t(errors['policy.endDate'].type)}
            variant='outlined'
            InputLabelProps={{
              shrink: true,
            }}
          />
      </Grid>

    </React.Fragment>
  )
}


export const contractDirtyFields = [
  'contract.deposit',
  'contract.monthlyRent',
  'contract.paymentFrequency',
  'contract.paymentDate',
  'contract.tax',
  'contract.startDate',
  'contract.endDate',
  'contract.paymentMethod',
  'contract.accountOwner',
  'contract.bank',
  'contract.cardNum',
  'contract.clabe',
  'contract'
]

export const getContractAttributes = data => ({
  deposit: data.contract.deposit,
  monthlyRent: parseFloat(data.contract.monthlyRent),
  paymentFrequency: data.contract.paymentFrequency,
  paymentDate: parseInt(data.contract.paymentDate),
  tax: data.contract.tax,
  startDate: data.contract.startDate,
  endDate: data.contract.endDate,
  paymentMethod: data.contract.paymentMethod,
  accountOwner: data.contract.accountOwner,
  bank: data.contract.bank,
  cardNum: data.contract.cardNum,
  clabe: data.contract.clabe,
})

export const ContractFields = props => {
  const {
    contract,
  } = props

  const {
    register,
    fieldsEnabled,
    errors,
    setValue,
    control,
    watch
  } = useFormContext()

  const [rentUpdated, setRentUpdated] = useState(false)
  const { t } = useTranslation()
  const classes = useStyles()
  const watchAll = watch()
  console.log('contract fields rendered')

  const calculateTotal = () => {
    const tax = watchAll.contract?.tax == 'yes' ? 1.16 : 1;


    if(contract) {
      if(rentUpdated) return parseInt(watchAll.contract?.monthlyRent) * tax
      return contract.monthlyRent * tax;
    }
    else if(watchAll.contract?.monthlyRent)
      return parseInt(watchAll.contract?.monthlyRent) * tax
  }

  const total = parseFloat(calculateTotal()).toFixed(2)

  return (

    <React.Fragment>
      <Grid item xs={6} md={4}>
        <Select
          name='contract.deposit'
          fieldsDisabled={!fieldsEnabled}
          getDefaultMatcher={(option, subject) => option == subject.deposit}
          required={true}
          subject={contract}
          control={control}
          options={DepositOptions.map(p => ({...p, label: t(p.label)}))}
          errors={errors}
          sv={setValue}
          label={t('deposit')}
        />
      </Grid>


      <Grid item xs={6} md={4}>
        <TextField
          fullWidth
          required
          defaultValue={contract ? contract.paymentDate : null}
          inputProps={{max: 31, min: 1}}
          disabled={!fieldsEnabled}
          label={t('paymentDate')}
          margin="dense"
          name="contract.paymentDate"
          variant="outlined"
          type='number'
          inputRef={register({ required: true, min: 0, max: 31 })}
          error={errors.contract?.paymentDate}
          helperText={errors.contract?.paymentDate && t(errors.contract?.paymentDate?.type)}
        />
      </Grid>

      <Grid item xs={6} md={4}>
        <Select 
          name='contract.paymentFrequency'
          subject={contract}
          fieldsDisabled={!fieldsEnabled}
          errors={errors}
          control={control}
          getDefaultMatcher={(option, subject) => option == subject.paymentFrequency}
          options={PaymentFrequency.map(p => ({...p, label: t(p.label)}))}
          sv={setValue}
          label={t('paymentFrequency')}
          required={true}
        />
      </Grid>


      <Grid item md={4} xs={6}>
        <Select 
          name='contract.tax'
          subject={contract ? contract : null}
          fieldsDisabled={!fieldsEnabled}
          errors={errors}
          control={control}
          getDefaultMatcher={(option, subject) => option == subject.tax}
          options={TaxOptions.map(p => ({...p, label: t(p.label)}))}
          sv={setValue}
          label={t('tax')}
          required={true}
        />
      </Grid>

      <Grid item xs={6} md={4}>
        <TextField
          required
          fullWidth
          type='number'
          margin="dense"
          variant="outlined"
          label={t('monthlyRent')}
          disabled={!fieldsEnabled}
          name="contract.monthlyRent"
          error={errors.contract?.monthlyRent}
          inputRef={register({ required: true })}
          onChange={() => setRentUpdated(true)}
          defaultValue={contract ? contract.monthlyRent : null}
          helperText={errors.contract?.monthlyRent && t(errors.contract?.monthlyRent?.type)}
        />
      </Grid>

      <Grid item xs={6} md={4}>
        <TextField
          fullWidth
          disabled={true}
          label={t('total')}
          margin="dense"
          name="contract.total"
          type='number'
          variant="outlined"
          value={total}
        />
      </Grid>

      <Grid item xs={12} md={6}>

        <Select 
          name='contract.paymentMethod'
          subject={contract ? contract : null}
          fieldsDisabled={!fieldsEnabled}
          errors={errors['contract']}
          control={control}
          getDefaultMatcher={(option, subject) => option == subject.paymentMethod}
          options={PaymentMethods.map(p => ({...p, label: t(p.label)}))}
          sv={setValue}
          label={t('paymentMethod')}
          required={true}
        />

      </Grid>

      <Grid item xs={12} md={6}></Grid>


      { ['transfer', 'deposit'].includes(watchAll.contract?.paymentMethod) && (
        <React.Fragment>
          <Grid item xs={4}>
            <TextField
              fullWidth
              required
              defaultValue={contract ? contract.accountOwner : null}
              disabled={!fieldsEnabled}
              label={t('accountOwner')}
              margin='dense'
              name='contract.accountOwner'
              type='text'
              inputRef={register({required: true })}
              error={errors.contract?.accountOwner}
              helperText={errors.contract?.accountOwner && t(errors.contract?.accountOwner.type)}
              variant='outlined'
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              required
              defaultValue={contract ? contract?.bank : null}
              disabled={!fieldsEnabled}
              label={t('bank')}
              margin='dense'
              name='contract.bank'
              type='text'
              inputRef={register({required: true })}
              error={errors.contract?.bank}
              helperText={errors.contract?.bank && t(errors.contract?.bank.type)}
              variant='outlined'
            />
          </Grid>
        </React.Fragment>
      )}


      {watchAll.contract?.paymentMethod == 'deposit' && (
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            defaultValue={contract ? contract.cardNum : null}
            disabled={!fieldsEnabled}
            label={t('cardNum')}
            margin='dense'
            name='contract.cardNum'
            type='text'
            inputRef={register({required: true })}
            error={errors.contract?.cardNum}
            helperText={errors.contract?.cardNum && t(errors.contract?.cardNum?.type)}
            variant='outlined'
          />
        </Grid>
      )}


      {watchAll.contract.paymentMethod == 'transfer' && (
        <Grid item xs={4}>
          <TextField
            fullWidth
            required
            defaultValue={contract ? contract.clabe : null}
            disabled={!fieldsEnabled}
            label={t('clabe')}
            margin='dense'
            name='contract.clabe'
            type='text'
            inputRef={register({required: true })}
            error={errors.contract?.clabe}
            helperText={errors.contract?.clabe && t(errors.contract?.clabe?.type)}
            variant='outlined'
          />
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          defaultValue={contract ? moment(contract.startDate).format('YYYY-MM-DD') : null}
          disabled={!fieldsEnabled}
          label={t('contractStartDate') + ' (DD/MM/YYYY)'}
          margin='dense'
          name='contract.startDate'
          type='date'
          format='MM/DD/YYYY'
          inputRef={register({required: true })}
          error={errors['contract.startDate']}
          helperText={errors['contract.startDate'] && t(errors['contract.startDate'].type)}
          variant='outlined'
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          defaultValue={contract ? moment(contract.endDate).format('YYYY-MM-DD') : null}
          disabled={!fieldsEnabled}
          label={t('contractEndDate') + ' (DD/MM/YYYY)'}
          margin='dense'
          name='contract.endDate'
          type='date'
          format='DD/MM/YYYY'
          inputRef={register({required: true })}
          error={errors['contract.endDate']}
          helperText={errors['contract.endDate'] && t(errors['contract.endDate'].type)}
          InputLabelProps={{ shrink: true }}
          variant='outlined'
        />
      </Grid>

    </React.Fragment>
    
  )

}

const MemoContractFields = React.memo(ContractFields)

export const PropertyFields = (params) => {
  const {
    isLetterOpen, setLetterOpen,
    isContractOpen, setContractOpen,
    fieldsEnabled 
  } = params
  const { property, address, clients, control, errors, register, setValue, client, tenant } = useFormContext()
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const [hasTenant, setHasTenant] = useState(property?.tenant?.id)

  return (
    <React.Fragment>

      <Grid item md={6}>
        <FormControl
          variant="outlined"
          error={errors.client}
          style={{margin: '0', width: '100%'}}
          className={classes.formControl}>
          <Controller
            defaultValue={property ? clients.find(a => a.id == property.user.id) : client}
            name="client"
            control={control}
            rules={{required: true}}
            as={({ value, onChange }) => (
              <Autocomplete
                disabled={!fieldsEnabled}
                id="client"
                value={value}
                onChange={(_, value ) => setValue('client', value) }
                options={clients.map(p => ({...p, label: t(p.label)}))}
                getOptionLabel={option => option.fullName}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    error={errors.client}
                    label={t('client')}
                    variant="outlined"
                  />
                )}
              />
            )}
          />
          <FormHelperText>
            {errors.address && t(errors.address.type)}
          </FormHelperText>
        </FormControl>
      </Grid>


      <Grid item md={6}>
            <Grid item xs={12}>
              <Select 
                required={true}
                fieldsDisabled={!fieldsEnabled}
                subject={property ? property : null}
                name='propertyUse'
                label={t('propertyUse')}
                control={control}
                options={PropertyUse.map(p => ({...p, label: t(p.label)}))}
                sv={setValue}
                errors={errors}
              />
            </Grid>
      </Grid>


      <Grid item xs={12}>
        <Paper style={{padding: '1em'}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>{t('address')}</Typography>
            </Grid>
            <AddressFields address={address} />
          </Grid>
        </Paper>
      </Grid>
      
      
      <Grid item xs={12}>
        <Paper style={{padding: '1em'}}>
          <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>{t('policy')}</Typography>
              </Grid>
              <PropertyPolicyFields policy={property?.lastPolicy} />
          </Grid>
        </Paper>
      </Grid>



      <Grid item xs={12}>
         <Paper style={{padding: '1em'}}>
          <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>Contrato de Arrendamiento</Typography>
              </Grid>
              <MemoContractFields contract={property?.lastContract} />
          </Grid>
        </Paper>
      </Grid>
      
      <Grid item xs={12}>
        <Paper style={{padding: '1em'}}>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <Typography variant='subtitle1'>{t('tenant')}</Typography>
              <FormControlLabel
                label={t('hasTenant')}
                control={
                  <Checkbox
                    disabled={!fieldsEnabled}
                    checked={hasTenant}
                    onChange={(e) => setHasTenant(e.target.checked)}
                  />
                }
              />
            </Grid>
            {hasTenant && ( <TenantFields tenant={tenant}/>)}
          </Grid>
        </Paper>
      </Grid>


      <Grid item md={3}>
          {property && (
            <Button fullWidth className={classes.dropZoneButton} variant="contained" color="primary" onClick={() => history.push(`/document/${property.lastPolicy.policyType}/${property.user.id}`)}>
                {t('welcome_letter')}
            </Button>
          )}
      </Grid>

      <Grid item md={3}>
          {property && (
          <Button fullWidth className={classes.dropZoneButton} variant="contained" color="primary" onClick={() => history.push(`/document/claim/${property.user.id}`)}>
              {t('reclamation_letter')}
          </Button>
          )}
      </Grid>

      <Grid item md={3}>
          {/* <FileCard fileLink={null} title={t('contract')}/> */}
            {property && (
              <Button fullWidth className={classes.dropZoneButton} variant="contained" color="primary" onClick={() => history.push(`/document/td/${property.user.id}`)}>
                  {t('terms_and_conditions')}
              </Button>
            )}
        </Grid>

        <Grid item md={3}>
          {/* <FileCard fileLink={null} title={t('contract')}/> */}
            {property && (
              <Button fullWidth className={classes.dropZoneButton} variant="contained" color="primary" onClick={() => history.push(`/document/tac/${property.user.id}/${property.id}`)}>
                  {t('cover_terms_and_conditions')}
              </Button>
            )}
        </Grid>
    </React.Fragment>
  )
}

const PropertyForm = (props) => {
  const { property, onCompleted, onError, fieldsEnabled, clientId, ...rest} = props
  console.log('property form rendered')

  const methods = useForm({
    defaultValues: {
      'contract.paymentMethod': property?.lastContract?.paymentMethod,
      'contract.tax': property?.lastContract?.tax,
      'personType': property?.tenant?.personType,
      'aval.personType': property?.tenant?.aval?.personType,
      'hasAval': property?.tenant?.aval?.id ? 'yes' : 'no'
    }
  })

  const { data } = useQuery(FETCH_CREATION_DATA, {
    fetchPolicy: 'network-only'
  })
  const { t } = useTranslation()
  const history = useHistory()

  const [isLetterOpen, setLetterOpen] = useState(false)
  const [isContractOpen, setContractOpen] = useState(false)

  const [mutate, mutationStatus] = useMutation(property ? UPDATE_PROPERTY : CREATE_PROPERTY, { onCompleted, onError })

  const classes = useStyles()
  const context = useContext(StoreContext)
  const currentUser = context.state.session
  const onSubmit = ({tenant, client, ...data}) => {
    let payload = {variables: {}}
    if(property) payload.variables.id = property.id
    if(tenant) payload.variables.tenantId = tenant.id

    const addressAttributes = getAddressAttributes(data)
    addressDirtyFields.forEach(key => delete data[key])
    const avalAttributes = data.aval ? getAvalAttributes(data) : {}
    const tenantAttributes = getTenantFields(data)
    const cwAttributes = getCWFields(data)
    const policyAttributes = getPolicyAttributes(data)
    const contractAttributes = getContractAttributes(data)
    avalDirtyFields.forEach(key => delete data[key])
    addressDirtyFields.forEach(key => delete data[key])
    CWDirtyFields.forEach(key => delete data[key])
    tenantDirtyFields.forEach(key => delete data[key])
    contractDirtyFields.forEach(key => delete data[key])
    policyDirtyFields.forEach(key => delete data[key])
    const hasTenant = !Object.keys(tenantAttributes).every(attr => !tenantAttributes[attr])

    payload.variables.input = {
      ...data,
      userId: client.id,
      createdById: currentUser.id,
      ...(property && property.lastPolicy) ? {
        policiesAttributes: {
          ...policyAttributes,
          id: property.lastPolicy.id
        }
      } : {},
      contractsAttributes: [{
        ...contractAttributes,
        ...(property && property.lastContract ) ? {id: property.lastContract.id} : {},
        ...(property && property.lastPolicy) ? {} : {
          policyAttributes: {
            ...policyAttributes,
          }
        }
      }],
      addressAttributes: {
        ...addressAttributes,
        ...(property && property.address) ? {id: property.address.id} : {}
      },
      ...(!!hasTenant ? {tenantAttributes: {
        ...cwAttributes,
        ...tenantAttributes,
        ...(property && property.tenant) ? {id: property.tenant.id} : {},
        ...(tenantAttributes.avalId !== null ? {
              avalAttributes :{
                ...avalAttributes,
                ...(property && property.tenant && property.tenant.aval) ? {id: property.tenant.aval.id} : {}
              }
           } : {})
      }} : {})
    }


    mutate(payload)
  }

  console.log(methods.formState.isDirty) //making sure it's read before render to enable proxy


  if(data) {
    const client =  data.users.find(c => c.id == clientId) 
    return (
      <Card {...rest} className={classes.root}>
        <FormProvider {...methods} 
          classes={classes}
          clients={data.users} 
          client={client}
          addresses={data.addresses}
          address={ property ? property.address : null}
          tenants={data.tenants}
          tenant={property?.tenant}
          property={property}
          fieldsEnabled={fieldsEnabled}
        >
          <form autoComplete='off' noValidate onSubmit={methods.handleSubmit(onSubmit)}>
            <CardHeader title={t('property')} subheader={fieldsEnabled ? t('information_editable') : ''}/>
            <Divider/>
            <CardContent>
              <Grid container spacing={3}>
                <PropertyFields { ...{ 
                  isLetterOpen, setLetterOpen,
                  isContractOpen, setContractOpen,
                  fieldsEnabled }} 
                />
              </Grid>
            </CardContent>
            <Divider/>
            <CardActions>
              {fieldsEnabled && (
                <Button color='primary' variant='contained' type='submit' disabled={mutationStatus.loading}>
                    {`${property ? t('update') : t('create')} ${t('property')}`}
                </Button>
              )}
            </CardActions>
          </form>
        </FormProvider>
          { mutationStatus.loading &&  (
            <div className={classes.centeringWrapper}>
              <LoadingBar/>
            </div>
          )}
      </Card>
    )
  }
  else
    return <Loader/>

}

export default PropertyForm

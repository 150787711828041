import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { PropertyForm } from './components';
import { useQuery } from '@apollo/react-hooks';
import { FETCH_PROPERTIES } from 'constants/queries';
import DefineAbilityFor from 'constants/abilities'  
import { StoreContext } from 'App'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Property = props => {
  const context = useContext(StoreContext)
  const ability = DefineAbilityFor(context.state.session)
  const history = useHistory()
  const { t } = useTranslation()
  const { clientId } = useParams();
  const { className, requirement, ...rest } = props;
  const classes = useStyles();

    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <PropertyForm 
              clientId={clientId}
              fieldsEnabled={ability.can('edit', 'properties')}
              onCompleted={ (data) => {
                context.state.showAlert({
                  severity: 'success',
                  message: `${t('property')} ${t('created')}`
                });
                setTimeout(() => history.push('/properties'), 1000)
              }}
              onError={ e => {
                context.state.showAlert({
                  severity: 'error',
                  message: e.message
                })
              }}
            />
          </Grid>
          <Grid item lg={4} md={6} xl={4} xs={12} />
        </Grid>
      </div>
    )
};

export default Property;



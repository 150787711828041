import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  TableSortLabel
} from '@material-ui/core';

import { getInitials } from 'helpers';
import Helpers from 'lib/helpers';
import { StoreContext } from 'App';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  filterScope: {
    borderTop: `1px solid ${theme.palette.primary.main}`
  }
}));

function descendingComparator(a, b, orderBy) {
  const sortFn = orderBy.sortFn || orderBy.fn;
  if (sortFn(b) < sortFn(a)) {
    return -1;
  }
  if (sortFn(b) > sortFn(a)) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const ListTable = props => {
  const {
    className,
    headCells,
    rows,
    setFilterScope,
    filterScope,
    Actions,
    defaultOrderByDir = 'asc',
    defaultOrderByIndex = 0,
    ...rest
  } = props;
  const { t, i18n } = useTranslation();


  const classes = useStyles();
  const history = useHistory();
  const context = useContext(StoreContext);
  const handleDelSuccess = () =>
    context.state.showAlert({
      severity: 'warning',
      message: 'Usuario Eliminado'
    });
  const [selectedRows, setselectedRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = React.useState(defaultOrderByDir);
  const [orderBy, setOrderBy] = React.useState(headCells[defaultOrderByIndex]);

  const handleSelectAll = event => {
    let selectedRows;

    const { rows } = props;

    if (event.target.checked) {
      selectedRows = rows.map(row => row.id);
    } else {
      selectedRows = [];
    }

    setselectedRows(selectedRows);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newselectedRows = [];

    if (selectedIndex === -1) {
      newselectedRows = newselectedRows.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newselectedRows = newselectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newselectedRows = newselectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newselectedRows = newselectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setselectedRows(newselectedRows);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const handleRequestSort = (event, row) => {
    const isAsc = orderBy === row && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(row);
  };

  const createSortHandler = row => event => {
    handleRequestSort(event, row);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedRows.length === rows.length}
                      color="primary"
                      indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length < rows.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  {headCells.map(headCell => (
                    <TableCell
                      key={headCell.id}
                      className={filterScope && (filterScope.id == headCell.id ? classes.filterScope : null)}
                      onClick={() => filterScope && (headCell.searchable && setFilterScope(headCell))}
                      key={headCell.id}
                      align={'left'}
                      padding={headCell.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy.id === headCell.id ? order : false}>
                      <TableSortLabel
                        active={orderBy.id === headCell.id}
                        direction={orderBy.id === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell)}>
                        {headCell.label}
                        {orderBy.id === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage)
                  .map(row => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={row.id}
                      selected={selectedRows.indexOf(row.id) !== -1}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedRows.indexOf(row.id) !== -1}
                          color="primary"
                          onChange={event => handleSelectOne(event, row.id)}
                          value="true"
                        />
                      </TableCell>
                      {headCells.filter(e => !e.hidden).map(e => (
                        <TableCell key={`${e.id}-${row.id}`}> {e.display(row)} </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={rows.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage={t('rows_per_page')}
          labelDisplayedRows={({ from, to, count }) =>
            t('from_to_count', { from, to, count })
          }
        />
      </CardActions>
    </Card>
  );
};

export default ListTable;

import { subject } from '@casl/ability';
import React, { useState, useContext, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from  'react-i18next'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText
} from '@material-ui/core';


import { StoreContext } from 'App';
import { UPDATE_REQUIREMENT, FETCH_PRODUCTS } from 'constants/queries';
import { Can } from 'lib/can';
import DefineAbilityFor from 'constants/abilities';
import { LoadingBar } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    minWidth: 120,
    width: '100%'
  },

}));

const RequirementDetails = props => {
  const { className, requirement, ...rest } = props;
  const history = useHistory();
  const classes = useStyles();
  const context = useContext(StoreContext);
  const ability = DefineAbilityFor(context.state.session);
  const fieldsEnabled = ability.can(
    'update',
    subject('requirement', requirement)
  ) && requirement.status == 'active';

  const { t } = useTranslation();
  const { register, errors, handleSubmit, setValue, control, watch } = useForm();
  const {data, loading, error} = useQuery(FETCH_PRODUCTS)
  const product =  useRef({})
  product.current = watch('product', requirement.product)

  const [updateRequirement, mutationStatus] = useMutation(UPDATE_REQUIREMENT, {
    onCompleted: () =>
      context.state.showAlert({
        severity: 'success',
        message: `${t('requirement_updated')}`
      })
  });

  let formattedDeliveryDate = moment(new Date(requirement.deliveryDate)).format(
    'YYYY-MM-DD'
  );

  useEffect(() => register('product'), [register])

  const onSubmit = ({product, ...data}) => 
    updateRequirement({
      variables: {
        id: requirement.id,
        input: {
          ...data,
          productId: product.id,
          units: parseInt(data.units),
        }
      }
    });

  if(data) {
    const currentProductData = data.products.filter(e => e.id == product.current.id)[0]
    return (
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
          <CardHeader
            subheader={ fieldsEnabled && t('this_information_is_editable') }
            title={`${t("requirement")}: ${requirement.name}`}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={4} xs={6}>
                <TextField
                  fullWidth
                  required
                  label={t("name")}
                  margin="dense"
                  name="name"
                  defaultValue={requirement.name}
                  disabled={!fieldsEnabled}
                  variant="outlined"
                  inputRef={register({ required: true })}
                  error={errors.name}
                  helperText={errors.name && t(errors.name.type)}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  disabled={!fieldsEnabled}
                  margin="dense"
                  label={t("closing_contract")}
                  type="date"
                  name="deadline"
                  variant="outlined"
                  defaultValue={requirement.deadline}
                  inputRef={register({required: true})}
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={errors.deadline}
                  helperText={
                    errors.deadline && t(errors.deadline.type)
                  }
                />
              </Grid>
              <Grid item md={4} xs={6}>
                <TextField
                  id="datetime-local"
                  fullWidth
                  margin="dense"
                  disabled={!fieldsEnabled}
                  name="deliveryDate"
                  label={t("delivery_date")}
                  type="date"
                  defaultValue={requirement.deliveryDate}
                  variant="outlined"
                  error={errors.deliveryDate}
                  helperText={ errors.deliveryDate && t(errors.deliveryDate.type) }
                  inputRef={register({required: true})}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item md={4} xs={6}>
                <FormControl
                  variant="outlined"
                  error={errors.product}
                  className={classes.formControl}
                >
                  <Controller
                    name='product'
                    control={control}
                    rules={{required: true}}
                    defaultValue={requirement.product}
                    as={({value, onChange}) => {
                      return <Autocomplete
                        id="product-id-select"
                        value={value}
                        options={data.products}
                        disabled={!fieldsEnabled}
                        getOptionLabel={option => option.name}
                        getOptionSelected={(option, value) => option.id == value.id}
                        onChange={e => setValue('product', data.products[e.target.dataset.optionIndex])}
                        renderInput={(params) => <TextField {...params} error={errors.product} label={t("product")} variant='outlined'/>}
                      >
                      </Autocomplete>
                    }}
                  />
                  <FormHelperText>
                    {errors.product && t(errors.product.type)}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={6}>
                <FormControl
                  variant='outlined'
                  className={classes.formControl}
                  error={errors.productPresentationId}
                >
                  <InputLabel id='product-presentation-select'>
                      {t('product_presentation')}
                  </InputLabel>
                  <Select
                    native
                    label={t('product_presentation')}
                    inputProps={{
                      name: 'productPresentationId',
                      id: 'product-presentation'
                    }}
                    inputRef={register({required: true})}
                    error={errors.productPresentationId}
                    defaultValue={requirement.productPresentation.id}
                  >
                    {
                      product.current && (
                        currentProductData.productPresentations.map(pp => (
                          <option value={pp.id} key={pp.id}>{pp.description}</option>
                        ))
                      )
                    }

                  </Select>
                </FormControl>
              </Grid>



              <Grid item md={3} xs={6}>
                <TextField
                  fullWidth
                  disabled={!fieldsEnabled}
                  defaultValue={requirement.units}
                  label={t("required_quantity")}
                  margin="dense"
                  name="units"
                  required
                  variant="outlined"
                  inputRef={register({ required: true })}
                  error={errors.units}
                  helperText={errors.units && t(errors.units.type)}
                />
              </Grid>
              <Grid item md={4} xs={6}>
                <FormControl 
                  variant="outlined" 
                  className={classes.formControl}
                  error={errors.priceDetail}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t("price_detail")}
                  </InputLabel>
                  <Select
                    native
                    name='priceDetail'
                    defaultValue={requirement.priceDetail}
                    inputRef={register({required: true})}
                    disabled={!fieldsEnabled}
                    label="Detalle de Precio"
                    inputProps={{
                      name: 'priceDetail',
                      id: 'outlined-price-detail-native-simple'
                    }}>
                    <option aria-label="None" value="" />
                    <option value={'CIF'}>CIF</option>
                    <option value={'FOB'}>FOB</option>
                  </Select>
                  <FormHelperText>
                      {errors.priceDetail && t(errors.priceDetail.type)}
                  </FormHelperText>
                </FormControl>
              </Grid>
              
              
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t("comments")}
                  margin="dense"
                  name="comments"
                  disabled={!fieldsEnabled}
                  multiline
                  rows={5}
                  defaultValue={requirement.comments}
                  variant="outlined"
                  inputRef={register({ required: true })}
                  error={errors.comments}
                  helperText={errors.comments && t(errors.comments.type)}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Can I="update" this="requirement">
              <Button
                color="primary"
                variant="contained"
                disabled={mutationStatus.loading || !fieldsEnabled}
                type="submit"
                >
                {t("save_changes")}
              </Button>
            </Can>
            <Can I="create" this="lot">
              <Button
                color="primary"
                variant="contained"
                disabled={!fieldsEnabled}
                onClick={() => {
                  history.push('/lots/create/' + requirement.id);
                }}>
                {t("create_lot")}
              </Button>
            </Can>
          </CardActions>
          {mutationStatus.loading && <LoadingBar />}
        </form>
      </Card>
    )
  }

  return <div></div>
};

export default RequirementDetails;

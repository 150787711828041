
import React, { useEffect, useContext } from 'react'
import { Widget, addResponseMessage, addUserMessage } from 'react-chat-widget'
import 'react-chat-widget/lib/styles.css'
import 'assets/scss/rcw_override.scss'
import { useQuery, useMutation, useSubscription } from '@apollo/react-hooks'

import { StoreContext } from 'App'
import { useTranslation } from 'react-i18next'
import { SUBSCRIPTION_CHAT_UPDATED, FETCH_CHAT_ROOM, POST_CHAT_MESSAGE } from 'constants/queries'
import Helpers from 'lib/helpers'

const GlobalChat = props => {
  const { t } = useTranslation();
  const context = useContext(StoreContext)

  const {loading, error, data, subscribeToMore} = useQuery(FETCH_CHAT_ROOM, {
    variables: {query: {name: Helpers.getHelpChatName(context.state.session.id)}}
  })

  const [sendMessage, sendState] = useMutation(POST_CHAT_MESSAGE)

  useEffect(() => {
    if(!data) return
    addResponseMessage(t('welcome_to_chat'))
    data.chatRooms[0].chatMessages.forEach(message => {
      if(message.user.id == context.state.session.id)
        addUserMessage(message.body)
      else
        addResponseMessage(message.body)
    })
    const unsubscribe = subscribeToMore({
      document: SUBSCRIPTION_CHAT_UPDATED,
      variables: {roomId: data.chatRooms[0].id},
      updateQuery(prev, {subscriptionData}) {
        if(!subscriptionData.data) return prev;
        const newMessage = subscriptionData.data.messageAddedToRoom;
        if(newMessage.user.id != context.state.session.id)
          addResponseMessage(newMessage.body)
      }
    })
    return unsubscribe
  }, [data ? data.chatRooms[0].id : null])

  const handleMessagePost = body => {
    console.log(t('new_message_incoming'), body)
    sendMessage({
      variables: {
        input: {
          userId: context.state.session.id,
          chatRoomId: data.chatRooms[0].id,
          body
        }
      }
    })
  }

  return (
    <Widget
      title={'Navio'}
      subtitle={t('chat_with_an_admin')}
      handleNewUserMessage={handleMessagePost}
    />
  )
}

export default GlobalChat

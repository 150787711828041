import React, { useState, useContext } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar,
  Toolbar,
  Badge,
  Hidden,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  MenuItem,

} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TranslateIcon from '@material-ui/icons/Translate';
import { Can } from 'lib/can';
import { useTranslation } from 'react-i18next'


import { StoreContext } from 'App';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  backButton: {
    cursor: 'pointer'
  },
  formControl: {
    color: theme.palette.primary.contrastText,
    margin: theme.spacing(1),
    minWidth: 120,
    width: '90%',
    borderColor: theme.palette.primary.contrastText,
  },
  select: {
    borderColor: theme.palette.primary.contrastText,
  },
  selectInput: {
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,
  },
  icon: {
      fill: theme.palette.primary.contrastText,
  }
}));


const LanguageSelect = props => {
  const classes = useStyles();
  const {t, i18n} = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
  }

  return (
    <form>
      <FormControl variant='outlined' className={classes.formControl}>
        <Select
          className={classes.select}
          value={i18n.language}
          onChange={ e => changeLanguage(e.target.value)}
          label="Language"
          inputProps={{
            className: classes.selectInput,
            classes: {
              icon: classes.icon
            },
            name: 'language',
            id: 'language-select'
          }}>
          <MenuItem value={'en'}>English</MenuItem>
          <MenuItem value={'es'}>Español</MenuItem>
        </Select>
      </FormControl>
    </form>
  )

}

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();
  const context = useContext(StoreContext);
  const history = useHistory();
  const [notifications] = useState([]);


  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
        </RouterLink>
        <div className={classes.flexGrow} />
        <LanguageSelect/>
        <ArrowBackIosIcon
          className={classes.backButton}
          onClick={() => history.goBack()}
        />
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton className={classes.signOutButton} color="inherit" onClick={() => context.state.logOut()}>
            <InputIcon/>
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;

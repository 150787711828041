import * as Actions from './actions';
import { setAuthTokenCookie } from './Authentication';

const InitialSession = {
  fetching: false,
  firstName: null,
  lastName: null,
  email: null,
  id: null,
  credentials: null,
  loading: false,
};

const InitialStore = {
  session: InitialSession
};

const StoreReducer = (state, action) => {
  switch (action.type) {
    case Actions.FETCHING_SESSION: {
      const new_state = {
        ...state,
        session: { ...InitialSession, fetching: true }
      };
      return new_state;
    }
    case Actions.SESSION_FETCHED: {
      const new_state = {
        ...state,
        session: { ...InitialSession, loading: false }
      };
      return new_state;
    }
    case Actions.SET_SESSION: {
      const new_state = { ...state, session: {fetching: false, ...action.payload} };
      return new_state;
    }
    case Actions.LOG_IN_SUCCESS: {
      const { firstName, lastName, email, id, credentials } = action.payload;
      const session = {
        firstName,
        lastName,
        email,
        id,
        credentials,
        loading: false
      };
      const new_state = { ...state, session };
      setAuthTokenCookie(action.payload.authToken);
      window.location.href = '/';
      return new_state;
    }
    case Actions.LOG_OUT: {
      const new_state = { ...state, session: InitialSession };
      setAuthTokenCookie(null);
      window.location.href = '/sign-in';
      return new_state;
    }
    case Actions.SHOW_ALERT: {
      const new_state = {
        ...state,
        alertIsOpen: true,
        severity: action.payload.severity,
        message: action.payload.message
      };
      return new_state;
    }

    case Actions.HIDE_ALERT: {
      const new_state = {
        ...state,
        alertIsOpen: false
      };
      return new_state;
    }

    case Actions.SHOW_DIALOG: {
      const new_state = {
        ...state,
        dialog: {
          isOpen: true,
          title: action.payload.title,
          contentText: action.payload.contentText,
          actions: action.payload.actions,
        }
      }
      return new_state
    }


    case Actions.HIDE_DIALOG: {
      const new_state = {
        ...state,
        dialog: {
          isOpen: false
        }
      }
      return new_state
    }
  }

  return state;
};

export { StoreReducer, InitialStore };

import React, { useContext } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import { StoreContext } from 'App';

import {
  Dashboard as DashboardView,
  ProductList as ProductListView,
  UserList as UserListView,
  ClientList as ClientListView,
  UserView,
  UserCreation,
  RequirementView,
  RequirementCreation,
  RequirementList as RequirementListView,
  LotView,
  LotCreation,
  LotList as LotListView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  TenantList as TenantListView,
  TenantCreationView,
  TenantView,
  PropertyList,
  PropertyCreate,
  PropertyView,
  ClientAttention,
  InvoiceForm, InvoiceView,
  PrototypingView,
  DocumentView,
  InvoiceList,
  Calculator as CalculatorView,
  ProspectList,
  PolicyList,
  PolicyDocumentEditor,
} from './views';

export const SignInRoute = (props) => {
return <RouteWithLayout
    component={SignInView}
    exact
    layout={MinimalLayout}
    path="/sign-in"
  />
}

const Routes = () => {
  const context = useContext(StoreContext);

  return (
    <Switch>
      <Redirect exact from="/" to="/users" />

      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />


      <RouteWithLayout
        component={ProspectList}
        exact
        layout={MainLayout}
        path="/prospects"
      />

      <Route
        component={DocumentView}
        // layout={MainLayout}
        path="/document/:docId/:userId/:propertyId?"
      />

      <RouteWithLayout
        exact
        component={PrototypingView}
        layout={MainLayout}
        path="/prototype"
      />

      <RouteWithLayout
        component={UserCreation}
        exact
        layout={MainLayout}
        path="/users/create"
      />
       <RouteWithLayout
        component={ClientListView}
        exact
        layout={MainLayout}
        path="/clients"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={TenantListView}
        exact
        layout={MainLayout}
        path='/tenants'
      />
      <RouteWithLayout
        component={TenantCreationView}
        exact
        layout={MainLayout}
        path='/tenants/create'
      />
      <RouteWithLayout
        component={TenantView}
        exact
        layout={MainLayout}
        path='/tenants/view/:tenantId'
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={UserView}
        exact
        layout={MainLayout}
        path="/users/view/:userId"
      />
      <RouteWithLayout
        component={RequirementListView}
        exact
        layout={MainLayout}
        path="/requirements"
      />
      <RouteWithLayout
        component={RequirementView}
        exact
        layout={MainLayout}
        path="/requirements/view/:reqId"
      />
      <RouteWithLayout
        component={RequirementCreation}
        exact
        layout={MainLayout}
        path="/requirements/create"
      />

      <RouteWithLayout
        component={InvoiceView}
        exact
        layout={MainLayout}
        path="/invoices/view/:invoiceId"
      />

      <RouteWithLayout
        component={PolicyList}
        exact
        layout={MainLayout}
        path="/policies"
      />


      <RouteWithLayout
        component={PolicyDocumentEditor}
        exact
        layout={MainLayout}
        path="/policyContract/:id"
      />

      <RouteWithLayout
        component={InvoiceList}
        exact
        layout={MainLayout}
        path="/invoices"
      />
      <RouteWithLayout
        component={LotListView}
        exact
        layout={MainLayout}
        path="/lots"
      />
      <RouteWithLayout
        component={LotView}
        exact
        layout={MainLayout}
        path="/lots/view/:lotId"
      />
      <RouteWithLayout
        component={LotCreation}
        exact
        layout={MainLayout}
        path="/lots/create/:reqId"
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
      />
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={ClientAttention}
        exact
        layout={MainLayout}
        path="/admin/clientAttention"
      />


      <Route 
        exact
        path='/calculator'
        component={CalculatorView}
      />

      <RouteWithLayout
        component={PropertyCreate}
        exact
        layout={MainLayout}
        path="/properties/create/:clientId?"
      />

      <RouteWithLayout
        component={PropertyList}
        exact
        layout={MainLayout}
        path="/properties"
      />

     <RouteWithLayout
        component={PropertyView}
        exact
        layout={MainLayout}
        path="/properties/view/:propertyId"
      />

      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <SignInRoute/>
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;

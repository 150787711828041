import { defineAbility } from '@casl/ability';

export default function defineAbilityFor(user) {
  return defineAbility((can, cannot) => {
    if (!user || !user.id) {
      can('visit', '/sign-in');
      return;
    }

    can('view', 'header');
    can('read', 'user', { id: user.id });
    // can('update', 'user', { id: user.id });
    // can('destroy', 'user', { id: user.id });
    can('view', 'user', 'create');
    // can('visit', 'all');
    cannot('visit', '/admin/clientAttention' )

    if (user.credentials == 'client') {
      // can(['update'], 'user', { 'id': { $eq: user.id } });
      // can('update', 'tenant', {'tenant.user_id': {$eq: user.id}});
      can('visit', '/properties')
      can('visit', '/policies')
      can('visit', '/tenants')
      can('visit', '/invoices')
      can('visit', '/users/view/' + user.id)

    }

    cannot('update', 'user', 'credentials');
    if (user.credentials == 'admin') {
      can('manage', 'all');
      can('visit', '/admin/clientAttention' )
      cannot('have', 'clientDetail')
      cannot('view', 'help_chat')
      cannot('update', 'clientDetail', { 'user_id': { $ne: user.id } });
      cannot('destroy', 'clientDetail', { 'user_id': { $ne: user.id } });
  
    }
    cannot('choose for', 'requirement', { 'requirement.status': { $ne: 'inactive'}})
    cannot('visit', '/sign-in');
    cannot('visit', '/settings');
  });
}

import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useForm, Controller, useFormContext, FormProvider } from 'react-hook-form';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next'

import { LoadingBar } from 'components';
import { StoreContext } from 'App'
import { CREATE_ADDRESS, UPDATE_ADDRESS } from 'constants/queries'
import  MexicoStates from 'constants/mexico_states.js'

const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '90%'
  },
  textField: {
    padding: '1.3em'
  }
}));

export const addressDirtyFields = [ 'region', 'city', 'colony', 'extNum', 'intNum', 'zipCode', 'street' ]

export const getAddressAttributes = data => ({
    state: data.region,
    city: data.city,
    colony: data.colony,
    extNum: data.extNum,
    intNum: data.intNum,
    zipCode: data.zipCode,
    street: data.street,
  }
)

export const Fields = (params) => {
  const { address } = params
  const { register, control, fieldsEnabled, errors, setValue, classes } = useFormContext()
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Grid item md={6}>
        <FormControl
          variant="outlined"
          error={errors.region}
          style={{margin: '0', width: '100%'}}
          className={classes.formControl}>
          <Controller
            defaultValue={address && MexicoStates.find(e => e.value == address.state)?.value}
            name="region"
            control={control}
            rules={{required: true}}
            as={({ value, onChange }) => (
              <Autocomplete
                disabled={!fieldsEnabled}
                id="state"
                value={value && value.label ? value : MexicoStates.find(e => e.value == value)}
                onChange={(_, data) => setValue( 'region', data ? data.value : null) }
                options={MexicoStates}
                getOptionLabel={option => option.label}
                renderInput={params => (
                  <TextField
                    {...params}
                    required={true}
                    error={errors.region}
                    label={t('state')}
                    variant="outlined"
                  />
                )}
              />
            )}
          />
          <FormHelperText>
            {errors.state && t(errors.state.type)}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
          <TextField
            // className={classes.textField}
            defaultValue={address ? address.city : null}
            fullWidth
            disabled={!fieldsEnabled}
            label={t('city')}
            margin="dense"
            name="city"
            required
            variant="outlined"
            inputRef={register({ required: true })}
            error={errors.city}
            helperText={errors.city && t(errors.city.type)}
          />
      </Grid>
      <Grid item md={4} xs={6}>
          <TextField
            fullWidth
            defaultValue={address ? address.zipCode : null}
            disabled={!fieldsEnabled}
            label={t('zip_code')}
            margin="dense"
            name="zipCode"
            type='number'
            required
            variant="outlined"
            inputRef={register({ required: true })}
            error={errors.zipCode}
            helperText={errors.zipCode && t(errors.zipCode.type)}
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <TextField
            fullWidth
            defaultValue={address ? address.colony : null}
            disabled={!fieldsEnabled}
            label={t('colony')}
            margin="dense"
            name="colony"
            required
            variant="outlined"
            inputRef={register({ required: true })}
            error={errors.colony}
            helperText={errors.colony && t(errors.colony.type)}
          />
      </Grid>
      <Grid item md={4} xs={6}>
          <TextField
            fullWidth
            defaultValue={address ? address.street : null}
            disabled={!fieldsEnabled}
            label={t('street')}
            margin="dense"
            name="street"
            required
            variant="outlined"
            inputRef={register({ required: true })}
            error={errors.street}
            helperText={errors.street && t(errors.street.type)}
          />
      </Grid>
      <Grid item md={4} xs={6}>
          <TextField
            fullWidth
            defaultValue={address ? address.extNum : null}
            disabled={!fieldsEnabled}
            label={t('ext_num')}
            margin="dense"
            name="extNum"
            required
            variant="outlined"
            inputRef={register({ required: true })}
            error={errors.extNum}
            helperText={errors.extNum && t(errors.extNum.type)}
          />
      </Grid>
      <Grid item md={4} xs={6}>
          <TextField
            fullWidth
            defaultValue={address ? address.intNum : null}
            disabled={!fieldsEnabled}
            label={t('int_num')}
            margin="dense"
            name="intNum"
            variant="outlined"
            inputRef={register()}
            error={errors.intNum}
            helperText={errors.intNum && t(errors.intNum.type)}
          />
      </Grid>
    </React.Fragment>
)
}

const AddressForm = (props) => {
  const { address, addressableId, addressableType, onCompleted, fieldsEnabled, ...rest} = props
  const classes = useStyles()
  const context = useContext(StoreContext)

  const { t } = useTranslation()
  const methods = useForm()
  const { register, errors, handleSubmit, setValue, control } = methods
  const [mutate, mutationStatus] = useMutation(address ? UPDATE_ADDRESS : CREATE_ADDRESS, { onCompleted })

  const onSubmit = ({region, ...data}) => {
    let payload = {variables: {}}
    if(address) payload.variables.id = address.id
    
    payload.variables.input = {
      addressableId,
      addressableType,
      state: region, //state is a keyword so cant have a named field
      ...data
    }

    mutate(payload)
  }


  return (
    <Card {...rest} className={classes.root}>
      <FormProvider {...methods} classes={classes} fieldsEnabled={fieldsEnabled}>
        <form autoComplete='off' noValidate onSubmit={handleSubmit(onSubmit)}>
          <CardHeader title={t('address')} subheader={fieldsEnabled ? t('information_editable') : ''}/>
          <Divider/>
          <CardContent>
            <Grid container spacing={3}>
              <Fields { ...{ control, address, fieldsEnabled, errors, setValue, classes, register }} />
            </Grid>
          </CardContent>
          <Divider/>
          <CardActions>
            {fieldsEnabled && (
              <Button color='primary' variant='contained' type='submit' disabled={mutationStatus.loading}>
                  {`${address ? t('update') : t('create')} ${t('address')}`}
              </Button>
            )}
          </CardActions>
        </form>
      </FormProvider>
      { mutationStatus.loading && <LoadingBar/> }
    </Card>
  )

}

export default AddressForm

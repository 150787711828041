import React, {useContext, useState} from 'react';
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import { parseCurrency } from 'lib/helpers'

const CalculatorCard = props => {
  const { tagline, title, cost, subtitle, info, points} = props;
  const [active, setActive] = useState(false)

  return (
    <div className={clsx('ccard', cost && 'active')} onClick={() => {setActive(true)}}>
      <div className="ccard__tagline">{tagline}</div>
      <div style={{width: '100%'}}>
        <div className="ccard__subtitle">{title}</div>
      </div>
      <div className="ccard__cost">
        <div className="ccard__title">
            <span>$</span>
            {parseCurrency(cost)}
        </div>
        <div className='sub-header'>{subtitle}</div>
      </div>
      <div className="ccard__info" >{info}</div>
      <div className={clsx('ccard--secondary', active && 'ccard--secondary-active')} onClick={(e) => {e.stopPropagation(); setActive(false)}}>
          {!props?.first && (
            <span style={{
              fontSize: '12px',
              color: '#005090',
              marginBottom: '1em'
            }}>Todo lo de la póliza anterior más.</span>
          )}
          {points.map(p => (
            <Typography variant='body' style={{margin: '0.5em 0'}}>{p}</Typography>
          ))}
      </div>
    </div>
  )
};

export default CalculatorCard;

import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next'

import { LoadingBar, Select }  from 'components'
import { StoreContext } from 'App';
import { CREATE_USER } from 'constants/queries';
import { UserInitial } from 'views/UserView/data';
import Helpers from 'lib/helpers'
import {
  ClientDetailMoralFields,
  ClientDetailFileFields,
  ClientDetailFields,
  getAllClientDetailAttributes,
  allClientDetailDirtyFields,
  AddressFields,
  getAddressAttributes,
  addressDirtyFields
} from 'views'


const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '90%'
  },
}));

const UserCreationForm = props => {
  const classes = useStyles();
  const context = useContext(StoreContext);

  const { t } = useTranslation();
  const { className, user, onCompleted, onError, ...rest } = props;
  const methods = useForm();
  const { register, errors, handleSubmit, setValue, watch, control } = methods
  const watchAll = watch()
  const [createUser, mutationStatus] = useMutation(CREATE_USER, { onCompleted, onError });

  const credentials = [
    {
      value: 'client',
      label: t('client')
    },
    {
      value: 'admin',
      label: t('administrator')
    }
  ];

  const onSubmit = data => {
    const addressAttributes = getAddressAttributes(data)
    const clientDetailAttributes = getAllClientDetailAttributes(data)
    addressDirtyFields.forEach(key => delete data[key])
    allClientDetailDirtyFields.forEach(key => delete data[key])

    createUser({
      variables: {
        input: {
          ...data,
          ...(data.credentials == 'client' ? {clientDetailAttributes: {
            ...clientDetailAttributes,
            addressAttributes
          }} : {})
        }
      }
    });
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <FormProvider {...methods} classes={classes} fieldsEnabled={true}>
        <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
          <CardHeader subheader= {t("information_editable")} title={t('profile')} />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>



              <Grid item xs={12} md={6}>
                <Select 
                  required={true}
                  name='credentials'
                  label={t('user_credentials')}
                  control={control}
                  options={credentials}
                  sv={setValue}
                  errors={errors}
                />
              </Grid>

                {watchAll.credentials != 'admin' && (
                  <ClientDetailFields
                    { ...methods}
                    fieldsEnabled={true}
                  />
                )}


                {watchAll.credentials == 'admin' && (
                  <Grid item md={6} xs={0}>
                  </Grid>
                )}

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('first_name')}
                  margin="dense"
                  name="firstName"
                  required
                  variant="outlined"
                  inputRef={register({ required: true })}
                  error={errors.firstName}
                  helperText={errors.firstName && t(errors.firstName.type)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('last_name')}
                  margin="dense"
                  name="lastName"
                  required
                  variant="outlined"
                  inputRef={register({ required: true })}
                  error={errors.lastName}
                  helperText={errors.lastName && t(errors.lastName.type)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('phone')}
                  margin="dense"
                  name="phone"
                  required
                  variant="outlined"
                  inputRef={register({ required: true })}
                  error={errors.phone}
                  helperText={errors.phone && t(errors.phone.type)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('mail')}
                  margin="dense"
                  name="email"
                  required
                  variant="outlined"
                  inputRef={register({ required: true, pattern: Helpers.emailRegex })}
                  error={errors.email}
                  helperText={errors.email && t(errors.email.type)}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  required
                  label={t('password')}
                  margin="dense"
                  name="password"
                  variant="outlined"
                  type="password"
                  inputRef={register({required: true})}
                  error={errors.password}
                  helperText={errors.password && t(errors.password.type)}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  required
                  fullWidth
                  label={t('confirmation')}
                  margin="dense"
                  name="passwordConfirmation"
                  variant="outlined"
                  type="password"
                  inputRef={register({ 
                    required: true,
                    validate: value =>
                      value === watchAll.password || 'password_mismatch'
                  })}
                  error={errors.passwordConfirmation}
                  helperText={errors.passwordConfirmation && t(errors.passwordConfirmation.type)}
                />
              </Grid>


            {watchAll.credentials != 'admin' && (
              <React.Fragment>


                <Grid item xs={12}>
                  <Typography variant='h3'>{t('address')}</Typography>
                </Grid>

                <AddressFields/>


                {watchAll.personType == 'moral' && (
                  <React.Fragment>

                    <Grid item xs={12}>
                      <Typography variant='h3'>{t('constitutiveWriting')}</Typography>
                    </Grid>
                    <ClientDetailMoralFields {...methods} fieldsEnabled={true} />

                  </React.Fragment>
                )}


                  <Grid item xs={12}></Grid>


                  <ClientDetailFileFields
                    {...methods}
                    fieldsEnabled={true}
                   />
                </React.Fragment>
            )}

            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button color="primary" variant="contained" type="submit" disabled={mutationStatus.loading}>
              Crear Usuario
            </Button>
          </CardActions>
        </form>
      </FormProvider>
      { mutationStatus.loading && <LoadingBar/> }
    </Card>
  );
};

export default UserCreationForm;

import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Button,
  Typography,
  Divider,
  TextField
} from '@material-ui/core';

import { StoreContext } from 'App';
import { Loader } from 'components';
import { GET_MESSAGES_FOR_LOT, ADD_MESSAGE_TO_LOT } from 'constants/queries';
import { MessageList } from './components';

const useStyles = makeStyles(theme => ({
  sendBtn: {
    height: '100%'
  }
}))

const ChatBox = ({ lotId }) => {
  const { t } = useTranslation();
  const [body, setBody] = useState('');
  const context = useContext(StoreContext);
  const userId = context.state.session.id;
  const classes = useStyles();

  const queryParams = useQuery(GET_MESSAGES_FOR_LOT, {
    variables: { query: { lotId } }
  });
  const [sendMessage, sendState] = useMutation(ADD_MESSAGE_TO_LOT, {
    onCompleted: () => setBody('')
  });

  if (queryParams.data) {
    return (
      <Card>
        <CardHeader title={`${t('chat')} `} />
        <Divider />
        <CardContent>
          <form action="" onSubmit={(e) => {
            e.preventDefault();
            sendMessage({
              variables: {
                input: {
                  userId,
                  lotId,
                  body
                }
              }
            })
          }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MessageList lotId={lotId} {...queryParams} />
              </Grid>
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  variant='outlined'
                  name="message"
                  label={t('message')}
                  value={body}
                  onChange={e => setBody(e.target.value)}
                />
              </Grid>
              <Grid item xs={1}>
                <Button
                  className={classes.sendBtn}
                  variant="contained"
                  color="primary"
                  type='submit'
                  >
                  {t('send_message')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    );
  } else return <Loader />;
};

export default ChatBox;

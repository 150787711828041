
import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider,
 Grid,
 Card,
 CardHeader,
 CardContent,
 Button,
 CardActions,
 Typography
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import moment from 'moment'

import { parseAddress, parseCurrency, Invoice } from 'lib/helpers'
import {
  EssentialItems,
  BasicItems,
  AmpleItems,
  PremiumItems,
  GuaranteeItems,
  LegalGuaranteeItems
} from 'constants/PolicyTypes'

const policyTypes = {
  'essential': EssentialItems,
  'basic': BasicItems,
  'ample': AmpleItems,
  'premium': PremiumItems,
  'guarantee': GuaranteeItems,
  'legal_guarantee': LegalGuaranteeItems,
}

const compressedPolicyTypes = ['basic', 'ample', 'premium', 'guarantee', 'legal_guarantee']

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    padding: 0
  },
  textField: {
    minHeight: '10em',
    height: '100%',
    border: '2px solid',
    borderColor: ({highlight}) => highlight ? theme.palette.navio.red : theme.palette.navio.blue,
    position: 'relative',
    padding: '0 1em 1em',
    display: 'flex',
    flexWrap: 'wrap',
  },
  chevron: {
    position: 'absolute',
    width: ({size}) => `${size}em`,
    height: '3em',
    left: '3px'
  },
  subtitle: {
    position: 'absolute',
    height: '3em',
    left: ({size}) => `${size-2}em`,
    color: 'black',
    fontSize: '24px',
    textTransform: 'uppercase',
    top: '0.5em',
  },
  parallelogram: {
    backgroundColor: ({ highlight }) => highlight ? theme.palette.navio.red : theme.palette.navio.blue,
    width: '90%',
    height: '100%',
    transform: 'skew(20deg)',
    position: 'absolute',
    top: '0',
    left: '5px'
  },
  title: {
    fontSize: '24px',
    position: 'absolute',
    top: '0.5em',
    left: '1em',
    textTransform: 'uppercase',
    color: theme.palette.white,
  },
  text: {
    margin: '4em 0 0 0'
  },
  bold: {
    fontWeight: 'bold'
  },
  textPair: {
    flex: ({textColumns}) => `1 0 ${100 / parseInt(textColumns) - 3}%`,
  },
  termsTextField: {
    height: '13em',
  },
  termsContainer: {
    marginTop: '-1em',
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateRows: 'repeat(6, 1fr)',
    gridTemplateColumns: 'repeat(3, 1fr)'
  },
  termsItem: {
    fontSize: '8px',
    lineHeight: '10px',
    padding: '6px 0 0 3px',
  }
}));



const TextField = ({title, subtitle, height, width, text, className, highlight, children, style, textColumns}) => {
  const classes = useStyles({highlight, size: title.length + 2, textColumns})

  const Chevron = ({highlight}) => (
    <div className={classes.chevron}>
        <div className={classes.parallelogram}/>
        <Typography className={classes.title}>{title}</Typography>
    </div>
  )
  
  return (
    <Grid item xs={width}>
      <div className={clsx([classes.textField, className])} style={style}>
        <Chevron title='test'/>
          {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
        <div style={{marginTop: '4em', width: '100%', flexWrap: 'wrap', display: 'flex'}}>
          {children}
        </div>
      </div>
    </Grid>
  )
}

const TextPair = ({name, value, textColumns}) => {
  const classes = useStyles({textColumns})
  return (
    <Typography className={classes.textPair}>
        <span style={{fontWeight: 'bold'}}>{name} </span>
        {value}
    </Typography>
  )
}

const TermsAndConditions = props => {
  const { client, property } = props
  const { t } = useTranslation()
  const policyType = property.lastPolicy.policyType
  const isCompressed = compressedPolicyTypes.includes(policyType)
  const classes = useStyles({isCompressed});

  const invoiceHelper = new Invoice(property.lastPolicy.lastInvoice)

  return (
    <div className={clsx(classes.root, 'document')}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <div>
            <img src={process.env.PUBLIC_URL + '/images/navio-logo.png'} style={{width: '12em'}}/>
          </div>
        </Grid>
        <Grid item xs={4}>
        </Grid>
        <Grid item xs={4} style={{display: 'flex !important', flexDirection: 'column', justifyContent: 'center'}}>
            <Typography variant='h5'>info@navio.mx</Typography>
            <Typography style={{fontWeight: 'bold'}} variant='h3'>www.navio.mx</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" style={{textTransform: 'uppercase'}}>
              Carátula de los términos y condiciones
          </Typography>
          <Typography variant="subtitle1">
              A la presente carátula se le anexan las condiciones generales y/o particulares que forman parte integrante de la presente póliza.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={0}>
              <TextField title='Póliza:' subtitle={t(property.lastPolicy.policyType)} width={8} highlight textColumns={2}>
                <TextPair name={'Asesor:'} value={property.createdBy.fullName} textColumns={1}/>
                <TextPair name={'Vigencia:'} value={`${moment(property.lastPolicy.startDate).format('DD/MM/YYYY')}-${moment(property.lastPolicy.endDate).format('DD/MM/YYYY')}`} textColumns={2}/>
              </TextField>

              <TextField title='Costo:' width={4}>
                <TextPair name={'Importe: '} value={'$' + parseCurrency(invoiceHelper.getImport())} textColumns={1}/>
                  {invoiceHelper.discount > 0 && <TextPair name={'Descuento: '} value={'$' + parseCurrency(invoiceHelper.getDiscount())} textColumns={1}/>}
                  {invoiceHelper.riskInterest > 0 && <TextPair name={'Interes por Riesgo: '} value={'$' + parseCurrency(invoiceHelper.riskInterest)} textColumns={1}/>}
                <TextPair name={'Sub-total: '} value={'$' + parseCurrency(invoiceHelper.getSubtotal().toFixed(2))} textColumns={1}/>
                <TextPair name={'IVA: '} value={'$' + parseCurrency(invoiceHelper.getTaxedAmount().toFixed(2))} textColumns={1}/>
                <TextPair name={'Total: '} value={'$' + parseCurrency(invoiceHelper.getTotal())} textColumns={1}/>
              </TextField>
              <TextField title='Cliente:' width={12}>
                <TextPair name={'Nombre: '} value={client.fullName} textColumns={2}/>
                <TextPair name={'Dirección: '} value={parseAddress(client.clientDetail?.address, t)} textColumns={2}/>

                <TextPair name={'RFC: '} value={client.clientDetail?.rfc} textColumns={2}/>
                <TextPair name={'Email: '} value={client.email} textColumns={2}/>

                <TextPair name={'Tel:'} value={client.phone} textColumns={2}/>
              </TextField>
              <TextField title='Inmueble arrendado:' width={6}>
                <TextPair name={'Uso:'} value={t(property.propertyUse)} textColumns={1}/>
                <TextPair name={'Dirección:'} value={parseAddress(property.address, t)} textColumns={1}/>
              </TextField>
              <TextField title='Arrendatario:' width={6}>
                <TextPair name={'Nombre:'} value={property.tenant?.canonicName} textColumns={1}/>
                <TextPair name={'Tel:'} value={property.tenant?.phone} textColumns={1}/>
                <TextPair name={'Email:'} value={property.tenant?.mail} textColumns={1}/>
                <TextPair name={'Representante Legal Tel:'} value={property.tenant?.legalRepresentativePhone} textColumns={1}/>
                <TextPair name={'Representante Legal Email:'} value={property.tenant?.legalRepresentativeEmail} textColumns={1}/>
              </TextField>
              <TextField title='Servicios Incluidos:' width={12} className={clsx([isCompressed && classes.termsTextField])}>
                <div className={clsx(['terms__container', isCompressed && classes.termsContainer])}>
                  {policyTypes[property.lastPolicy.policyType].map( e => (
                        <Typography variant='body1' className={clsx('terms__item', isCompressed && classes.termsItem)}>{e}</Typography>
                  ))}
                </div>
              </TextField>

          <Grid item xs={12}>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>
              <Typography variant='h3' style={{color: '#cd294b' }}>FOLIO: #{property.lastPolicy.id}</Typography>
              <Typography variant='h6' style={{color: "#0005"}}>
                  Para más información acerca de tu póliza, comunicate con nosotros.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='h6' style={{color: "#0005", textAlign: 'right'}}>
                Desde Monterrey:
            </Typography>

            <Typography variant='h6' style={{fontWeight: 'bold', textAlign: 'right'}}>
                {t('navio_phone')}
            </Typography>
          </Grid>
          </Grid>
        </Grid>

      </Grid>
    </div>
  )
};

export default TermsAndConditions;

import React, { useContext } from 'react'
import gql from 'graphql-tag'
import {
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  Button,
  InputLabel,
  FormHelper,
  FormHelperText,
  FormControl,
  CardActions,
  OutlinedInput,
  InputAdornment
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { CREATE_PAYMENT, FETCH_INVOICES } from 'constants/queries'
import { StoreContext } from 'App'
import { PaymentMethods } from 'constants/formOptions.js'
import { Select, LoadingBar } from 'components'
import {InvoiceFragments, PaymentFragments} from 'constants/fragments'

const useStyles = makeStyles(theme => ({
  margin: {
    // margin: theme.spacing(1),
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center'
  },
  classSummary: {
    flex: '1',
    flexGrow: '5',
  },
  textSecondary: {
    color: theme.palette.text.secondary,
    textTransform: 'capitalize'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%'
  },
  selectFormControl: {
    margin: '0',
    width: '100'
  }
}))

const PaymentForm = (props) => {
  const {invoice, payment} = props;
  const classes = useStyles()
  const context = useContext(StoreContext)
  const fieldsEnabled = true
  
  const { t } = useTranslation()
  const { register, errors, handleSubmit, control, setValue, watch } = useForm()
  const [ mutate, mutationStatus ] = useMutation(CREATE_PAYMENT, {
    onCompleted: () => {
      context.state.showAlert({
        severity: 'success',
        message: `${t('payment')} ${t('created')}`
      })
    },
    onError: (e) => {
      context.state.showAlert({
        severity: 'error',
        message: e.message
      })
    },
    update(cache, {data}) {
      const query = gql`
        fragment Invoice on Invoice {
          id
          payments {
            ...paymentFields
          }
        }
        ${PaymentFragments.fields}
      `;
      const newPayment = data.payment.create
      const invoice = cache.readFragment({ id: `Invoice:${newPayment.invoiceId}`, fragment: query, fragmentName: 'Invoice' });
      cache.writeFragment({
        id: `Invoice:${invoice.id}`,
        fragment: query,
        fragmentName: 'Invoice',
        data: {
          payments: [...invoice.payments, newPayment]
        },
      });
    }
  })

  const watchAll = watch()

  const onSubmit = (data) => {
    let payload = {variables: {}}
    if(payment) payload.variables.id = payment.id
    payload.variables.input = {
      ...data,
      invoiceId: invoice.id,
      qty: parseFloat(data.qty),
    }

    mutate(payload)
  }

  return (
    <form autoComplete='off' noValidate onSubmit={handleSubmit(onSubmit)}>
      <Card style={{margin: '1rem 0', borderRadius: '1rem'}}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <Select 
                errors={errors}
                name={'paymentMethod'}
                control={control}
                options={PaymentMethods.map(p => ({...p, label: t(p.label)}))}
                sv={setValue}
                formControlProps={{
                  className: classes.selectFormControl
                }}
              />

                {['deposit', 'transfer'].includes(watchAll.paymentMethod) && (
                 <TextField
                    fullWidth
                    margin="dense"
                    name="reference"
                    variant="outlined"
                    label={t('reference')}
                    error={errors.reference}
                    inputRef={register({ required: true })}
                    helperText={errors.reference && t(errors.reference.type)}
                  />
                )}


               {/* {watchAll.paymentMethod == 'card' && ( */}
               {/*  <TextField */}
               {/*    fullWidth */}
               {/*    margin="dense" */}
               {/*    name="cardNum" */}
               {/*    variant="outlined" */}
               {/*    label={t('cardNum')} */}
               {/*    error={errors.cardNum} */}
               {/*    inputRef={register({ required: true })} */}
               {/*    helperText={errors.cardNum && t(errors.cardNum.type)} */}
               {/*  /> */}
               {/* )} */}
            
            </Grid>
            <Grid item xs={4}>

              <FormControl fullWidth className={classes.margin} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">{t('amount')}</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  inputRef={register({required: true})}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  name='qty'
                  labelWidth={60}
                />
              </FormControl>
            
            </Grid>
          </Grid>

          <div className={classes.classSummary}></div>

          <div style={{flex: '1', textAlign: 'right'}}></div>
        </CardContent>
        <CardActions>
            {fieldsEnabled && (
              <Button color='primary' variant='contained' type='submit' disabled={mutationStatus.loading}>
                  {`${payment ? t('update') : t('create')} ${t('payment')}`}
              </Button>
            )}
        </CardActions>
        {mutationStatus.loading && <LoadingBar/>}
      </Card>
    </form>
  )
}
export default PaymentForm

import React, { useState, useContext, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Paper
} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import ErrorIcon from '@material-ui/icons/Error';
import { useTranslation } from 'react-i18next';

import { StoreContext } from 'App';
import { CREATE_LOT, FETCH_REQUIREMENTS } from 'constants/queries';
import { DropzoneArea } from 'material-ui-dropzone';
import { LoadingBar } from 'components';
import Helpers from 'lib/helpers'

const useStyles = makeStyles(theme => ({
  root: {},
  paper: {
    padding: '1rem'
  },
  paperError: {
    padding: '1rem',
    borderColor: theme.palette.error.main
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '90%'
  }
}));

const LotCreation = props => {
  const classes = useStyles();
  const context = useContext(StoreContext);
  const inAdvance = useRef({})
  const againstDocs = useRef({})
  const onDelivery = useRef({})


  const { reqId } = useParams();
  const { t, i18n } = useTranslation();
  const { className, ...rest } = props;
  const { register, errors, handleSubmit, setValue, watch } = useForm();

  inAdvance.current = watch('inAdvance');
  againstDocs.current = watch('againstDocs');
  onDelivery.current = watch('onDelivery');
  
  const paymentSchemeTotal = parseInt(inAdvance.current) + parseInt(againstDocs.current) + parseInt(onDelivery.current)

  const paymentSchemeAddsUp = () => (
    paymentSchemeTotal == 100
  )

  const { data } = useQuery(FETCH_REQUIREMENTS, {
    variables: { query: { id: reqId } }
  });
  const [createLot, mutationStatus] = useMutation(CREATE_LOT, {
    onCompleted: () =>
      context.state.showAlert({
        severity: 'success',
        message: `${t('lot')} ${t('created')}`
      })
  });

  useEffect(() => {
    register('certifications')
  }, [register])

  useEffect( () => {
    setValue('contactEmail', context.state.session.email)
    setValue('contactPhone', context.state.session.phone)
  } , [context])

  const onSubmit = data =>
    createLot({
      variables: {
        input: {
          ...data,
          requirementId: reqId, 
          providerId: context.state.session.id,
          pricePerUnit: parseInt(data.pricePerUnit),
          inAdvance: parseInt(data.inAdvance),
          onDelivery: parseInt(data.onDelivery),
          againstDocs: parseInt(data.againstDocs)
        }
      }
    })


  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardHeader
          subheader={`${t('provider')}: ${context.state.session.firstName} ${
            context.state.session.lastName
          }`}
          title={`${t('lot_for')} ${data && data.requirements[0].name}`}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                label={t('price_per_unit')}
                margin="dense"
                name="pricePerUnit"
                required
                type="number"
                step="0.01"
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.pricePerUnit}
                helperText={errors.pricePerUnit && t(errors.pricePerUnit.type)}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper className={errors.inAdvance ? classes.paperError : classes.paper} variant='outlined'>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant='body2'>
                          {t('payment_scheme')}
                      </Typography>
                      <Typography variant='caption'>
                          {t('payment_scheme_explanation')} 
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        error={errors.inAdvance}
                        label={Helpers.capitalize(t('in_advance'))}
                        margin='dense'
                        name='inAdvance'
                        inputRef={register({
                          required: true,
                          validate: () => paymentSchemeAddsUp()
                        })}
                        type='number'
                        variant='outlined'
                      >
                      </TextField>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        error={errors.againstDocs}
                        label={Helpers.capitalize(t('against_docs'))}
                        margin='dense'
                        name='againstDocs'
                        inputRef={register({required: true})}
                        type='number'
                        variant='outlined'
                      >
                      </TextField>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        error={errors.onDelivery}
                        label={Helpers.capitalize(t('on_delivery'))}
                        margin='dense'
                        name='onDelivery'
                        inputRef={register({required: true})}
                        type='number'
                        variant='outlined'
                      >
                      </TextField>
                    </Grid>
                      {
                        errors.inAdvance && (
                          <Typography variant='caption' color='error'>
                              {t('payment_scheme_addition_error')} ({paymentSchemeTotal})
                          </Typography>
                        )
                      }
                  </Grid>
              </Paper>
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControl variant="outlined" className={classes.formControl} error={errors.priceDetail}>
                <InputLabel id="demo-simple-select-outlined-label">
                  {t('price_detail')}
                </InputLabel>
                <Select
                  native
                  label="Detalle de Precio"
                  inputProps={{
                    name: 'priceDetail',
                    id: 'outlined-age-native-simple'
                  }}
                  inputRef={register({ required: true })}
                  error={errors.priceDetail}
                  helperText={errors.priceDetail && t(errors.priceDetail.type)}>
                  <option aria-label="None" value="" />
                  <option value={'CIF'}>CIF</option>
                  <option value={'FOB'}>FOB</option>
                </Select>
                <FormHelperText>{errors.priceDetail && t(errors.priceDetail.type)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                margin="dense"
                label={t('delivery_date')}
                type="date"
                name="deliveryDate"
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.deliveryDate}
                helperText={errors.deliveryDate && t(errors.deliveryDate.type)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                margin="dense"
                name="expirationDate"
                label={t('expiration_date')}
                type="date"
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.expirationDate}
                helperText={
                  errors.expirationDate && t(errors.expirationDate.type)
                }
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                margin="dense"
                name="contactEmail"
                defaultValue={'hellothere'}
                label={t('contact_email')}
                type="text"
                variant="outlined"
                inputRef={register({ required: true, pattern: Helpers.emailRegex })}
                error={errors.contactEmail}
                helperText={errors.contactEmail && t(errors.contactEmail.type)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                margin="dense"
                name="contactPhone"
                label={t('contact_phone')}
                type="text"
                variant="outlined"
                inputRef={register({ required: true, pattern: Helpers.phoneRegex })}
                error={errors.contactPhone}
                helperText={errors.contactPhone && t(errors.contactPhone.type)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                rows={5}
                fullWidth
                multiline
                margin="dense"
                name="comments"
                variant="outlined"
                label={t('comments')}
                inputRef={register({required: true})}
                error={errors.comments}
                helperText={errors.comments && t(errors.comments.type)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>{t('photos')}</Typography>
              <DropzoneArea
                onChange={files => setValue('certifications', files)}
                dropzoneText={t('packaging_photo')}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            disabled={mutationStatus.loading}
            type="submit" >
            {t('create_lot')}
          </Button>
        </CardActions>
      </form>
      {mutationStatus.loading && <LoadingBar />}
    </Card>
  );
};

export default LotCreation;

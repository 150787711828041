import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

const C_Alert = props => {
  const { isOpen, handleClose, severity, message, ...rest } = props;
  return (
    <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
      <Alert variant="filled" severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default C_Alert;

import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { UserCreationForm, UserProfile } from './components';
import { useQuery } from '@apollo/react-hooks';
import { FETCH_USERS } from 'constants/queries';
import { UserInitial } from './data';
import { StoreContext } from 'App'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const UserCreation = props => {
  const { userId } = useParams();
  const { className, user, ...rest } = props;
  const { data } = useQuery(FETCH_USERS, {
    variables: { query: { id: userId } }
  });
  const classes = useStyles();
  const context = useContext(StoreContext)
  const history = useHistory();
  const { t } = useTranslation()

  if (data)
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <UserCreationForm user={UserInitial} 
              onCompleted={( {user}) => {
                context.state.showAlert({
                  severity: 'success',
                  message: `${t('user')} ${t('created')}`
                })
                history.push('/properties/create/' + user.create.id )
              }}
              onError={(e) => {
                context.state.showAlert({
                  severity: 'error',
                  message: e.message
                })
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  return <div></div>;
};

export default UserCreation;

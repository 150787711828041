import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { LotProfile, LotDetails } from './components';
import { useQuery } from '@apollo/react-hooks';
import { FETCH_LOTS } from 'constants/queries';
import { LotCreationForm } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Lot = props => {
  const { reqId } = useParams();
  const { className, lots, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <LotCreationForm reqId={reqId} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Lot;

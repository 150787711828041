import React, { useEffect, useState, useContext, useReducer } from 'react';
import { Router, useHistory, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes, { SignInRoute } from './Routes';
import { StoreReducer, InitialStore } from 'lib/storeReducer';
import SessionHandler from './lib/SessionHandler';
import * as Actions from 'lib/actions';
import { AbilityContext } from 'lib/can';
import DefineAbilityFor from 'constants/abilities';
import { GlobalChat } from 'components'
import { Can } from 'lib/can'
import { Loader, Alert, Dialog } from 'components'
const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

export const StoreContext = React.createContext();
const hasToken = SessionHandler.hasToken();

const unprotectedViews = ['/calculator', '/sign-in']

const App = () => {
  
  const [state, dispatch] = useReducer(StoreReducer, InitialStore);
  const showAlert = ({ severity, message }) => {
    dispatch({ type: Actions.SHOW_ALERT, payload: { severity, message } });
  };

  const showDialog = ({title, contentText, actions}) => {
    dispatch({ type: Actions.SHOW_DIALOG, payload: {title, contentText, actions} });
  };


  const hideDialog = () => {
    dispatch({ type: Actions.HIDE_DIALOG });
  };

  const context = useContext(StoreReducer);
  const handleAlertClose = () => dispatch({ type: Actions.HIDE_ALERT });

  useEffect(() => {
    SessionHandler.fetchCurrentUser().then(user => {
         dispatch({ type: Actions.SET_SESSION, payload: user });
    });
  }, []);

  const logOut = () => {
    dispatch({ type: Actions.LOG_OUT });
  };


  return (
    <StoreContext.Provider
      value={{ state: { ...state, showAlert, showDialog, hideDialog, logOut }, dispatch }}>
      <StoreContext.Consumer>
        {context => {
          return <AbilityContext.Provider
            value={DefineAbilityFor(context.state.session)}>
            <ThemeProvider theme={theme}>
              <Router history={browserHistory}>
                <Route render={(props) => {
                  if(!hasToken && !unprotectedViews.includes(props.location.pathname))
                    return <Redirect to="/sign-in" />
                  else
                    return <Routes/>
                }}/>
              </Router>
              <Alert
                isOpen={state.alertIsOpen}
                handleClose={handleAlertClose}
                severity={state.severity}
                message={state.message}
              />
              <Dialog 
                isOpen={state.dialog?.isOpen}
                handleClose={state.dialog?.handleClose}
                title={state.dialog?.title}
                contentText={state.dialog?.contentText}
                Actions={state.dialog?.actions}
              />
              <Can I='view' this='help_chat'>
                <GlobalChat/>
              </Can>
            </ThemeProvider>
          </AbilityContext.Provider>
        }}
      </StoreContext.Consumer>
    </StoreContext.Provider>
  );
};
export default App;

import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/styles';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardContent,
  Typography,
  Grid

} from '@material-ui/core'

import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import ErrorIcon from '@material-ui/icons/Error';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { DELETE_FILE  } from 'constants/queries'
import { StoreContext } from 'App'
import Helpers from 'lib/helpers'

const useStyles = makeStyles((theme) => ({
  fileCard: {
    minHeight: '8em',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto, sans-serif',
    padding: '1rem',
    transition: 'all 0.3s',
    border: '1px solid',
    borderColor: props => props.hasFile ? theme.palette.success.light : 'white',
    '&:hover': {
      boxShadow: '0 3px 6px rgba(0,0,0,0.5)'
    }
  },
  deleteFileIcon: {
    color: 'red'
  },
  fileCardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fileCardIcon: {
    marginRight: '1rem'
  },
  clickableIcon: {
    cursor: 'pointer'
  }
}))

const FileCard = ({fileLink, title, onClickEdit, fileEditable = true, fileDeletable = true}) => {
  const [hasFile, setHasFile] = useState(false)
  const classes = useStyles({hasFile});
  const context = useContext(StoreContext)
  const { t } = useTranslation();

  const [deleteFile, deletionProgress] = useMutation(DELETE_FILE, {
    onCompleted: ({deleteFile}) => {
      if(deleteFile.result)
        context.state.showAlert({
          severity: 'success',
          message: `${t('file_deleted')}`
        })
      else
        context.state.showAlert({
          severity: 'error',
          message: `${t('file_deletion_failure')}`
        })
    }
  })

  return (
  <Card className={classes.fileCard}>
      <CardContent className={classes.fileCardContent} style={{width: '100%'}}>

            {/* <Grid item xs={1}> */}
            {/*   <div className={classes.fileCardIcon}> */}
            {/*     <DescriptionIcon /> */}
            {/*   </div> */}
            {/* </Grid> */}



          <Grid container spacing={3} style={{flexGrow: 1}}>
            <Grid item xs={8}>
              <Typography variant="subtitle1">{title}</Typography>
            </Grid>

            <Grid item xs={1}>
              <div className={classes.clickableIcon}>
                {fileLink?.link && <a href={fileLink.link}>
                  <VisibilityIcon />
                </a>}
              </div>
            </Grid>

          {fileEditable && (
            <Grid item xs={1}>
              <div className={classes.clickableIcon}
                onClick={e => {
                  e.preventDefault();
                  onClickEdit()
                  setHasFile(true)
                }}>
                <EditIcon />
              </div>
            </Grid>
          )}

          {fileEditable && (
            <Grid item xs={1}>
              <div
                className={classes.deleteFileIcon}
                onClick={e => {
                  e.preventDefault();
                  deleteFile({
                    variables: { id: fileLink.attachmentId }
                  });
                }}>
                <ClearIcon />
              </div>
            </Grid>
          )}

          </Grid>
      </CardContent>
    </Card>
  )
}

export default FileCard

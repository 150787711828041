import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next'

import { FETCH_REQUIREMENTS } from 'constants/queries';
import { Loader } from 'components'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

const Budget = props => {
  const { className, ...rest } = props;
  const { data, loading, error } = useQuery(FETCH_REQUIREMENTS); 
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  if(data) {
      let count = {active: 0, inactive: 0};
      data.requirements.forEach(r => {
        if(r.status == 'active')
          count.active += 1;
        else
          count.inactive += 1;
      })
      return (
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardContent>
            <Grid container justify="space-between">
              <Grid item>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                  variant="body2">
                  {t('requirement')}
                </Typography>
                <Typography variant="h3">
                    {`${count.active}/${count.inactive}`}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar}>
                  <MoneyIcon className={classes.icon} />
                </Avatar>
              </Grid>
            </Grid>
            <div className={classes.difference}>
              <ArrowDownwardIcon className={classes.differenceIcon} />
              <Typography className={classes.differenceValue} variant="body2">
                12%
              </Typography>
              <Typography className={classes.caption} variant="caption">
                {t('since_last_month')}
              </Typography>
            </div>
          </CardContent>
        </Card>
      );
  }
    else
    return (
        <Card>
          <CardContent>
            <Loader></Loader>
          </CardContent>
        </Card>
      )

};

Budget.propTypes = {
  className: PropTypes.string
};

export default Budget;


import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import ListTable from './ListTable'
import ListToolbar from './ListToolbar'
import { useQuery } from '@apollo/react-hooks';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));


const List = (props) => {
  const {
    Actions,
    HeaderActions,
    headCells,
    subject,
    label,
    defaultOrderByDir = 'desc',
    defaultOrderByIndex = 0,
  } = props
  const classes = useStyles();
  const filter = useState('')
  const [ filterScope, setFilterScope ] = useState(headCells[defaultOrderByIndex])
  const filteredRows = subject.filter(e => {
    const val = filterScope.fn(e)
    if(filterScope.numeric) {
      return val.toString().match(new RegExp(filter[0], 'i'))
    } else {
      return val.match(new RegExp(filter[0], 'i'))
    }
  })

  return (
    <div className={classes.root}>
      <ListToolbar
        label={label}
        filter={filter}
        filterScope={filterScope}
        HeaderActions={HeaderActions}
      />
      <div className={classes.content}>
        <ListTable
          headCells={headCells}
          rows={filteredRows}
          setFilterScope={setFilterScope}
          filterScope={filterScope}
          Actions={Actions}
          defaultOrderByDir={defaultOrderByDir}
          defaultOrderByIndex={defaultOrderByIndex}
        />
      </div>
    </div>
  );
  
}
export default List

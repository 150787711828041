export const EssentialItems = [
  'Asistencia legal durante la vigencia de la cobertura',
  'Investigación judicial del inquilino',
  'Creación de Contrato de Arrendamiento',
  'Intervención extrajudical por incumplimiento del Contrato de Arrendamiento'

]

export const BasicItems = [
  'Asistencia legal durante la vigencia de la cobertura.',
  'Investigación judicial del inquilino.',
  'Creación del Contrato de Arrendamiento.',
  'Intervención extrajudicial por incumplimiento del Contrato de Arrendamiento.',
  'Recuperación judicial del inmueble por incumplimiento del Contrato de Arrendamiento.',
  'Elaboración del Convenio para la terminación contractual y devolución del bien inmueble.',
  'Honorarios de abogados y gastos legales de los procedimientos legales.',
  'Protección contra la ley de extinción de dominio.',
  'Herramienta de Administración para tus Inmuebles.'
]

export const AmpleItems = [
  'Asistencia legal durante la vigencia de la cobertura.',
  'Investigación judicial de inquilino.',
  'Creación del Contrato de Arrendamiento.',
  'Ratificación del Contrato de Arrendamiento ante fedatario público.',
  'Acompañamiento a la firma del Contrato de Arrendamiento.',
  'Periodo de gracia, una vez vencida la vigencia del Contrato de Arrendamiento, para la recuperación del bien inmueble. ',
  'Intervención extrajudicial por incumplimiento del Contrato de Arrendamiento.',
  'Recuperación judicial del inmueble por incumplimiento del Contrato de Arrendamiento.',
  'Elaboración del Convenio para la terminación contractual y devolución del bien inmueble.',
  'Honorarios de abogados y gastos legales de los procedimientos legales.',
  'Protección contra la ley de extinción de dominio.',
  'Herramienta de Administración para tus Inmuebles.'
]

export const PremiumItems = [
  'Asistencia legal durante la vigencia de la cobertura.',
  'Investigación judicial del inquilino.',
  'Investigación judicial del fiador.',
  'Investigación del fiador en el Registro Público de la Propiedad y del Comercio.',
  'Creación del Contrato de Arrendamiento.',
  'Ratificación del Contrato de Arrendamiento ante fedatario público.',
  'Acompañamiento a la firma del Contrato de Arrendamiento.',
  'Periodo de gracia, una vez vencida la vigencia del Contrato de Arrendamiento, para la recuperación del bien inmueble. ',
  'Intervención extrajudicial por incumplimiento del Contrato de Arrendamiento.',
  'Recuperación judicial del inmueble por incumplimiento del Contrato de Arrendamiento.',
  'Cobranza judicial por adeudos a causa de rentas y servicios vencidos.',
  'Cobranza judicial por daños al inmueble por parte del inquilino.',
  'Elaboración del Convenio para la terminación contractual y devolución del bien inmueble.',
  'Honorarios de abogados y gastos legales de los procedimientos legales.',
  'Protección contra la ley de extinción de dominio.',
  'Herramienta de Administración para tus Inmuebles.'
]

export const GuaranteeItems = [
  'Asistencia legal durante la vigencia de la cobertura.',
  'Investigación judicial del inquilino.',
  'Investigación judicial del fiador.',
  'Investigación del fiador en el Registro Público de la Propiedad y del Comercio.',
  'Creación del Contrato de Arrendamiento.',
  'Ratificación del Contrato de Arrendamiento ante fedatario público.',
  'Acompañamiento a la firma del Contrato de Arrendamiento.',
  'Periodo de gracia, una vez vencida la vigencia del Contrato de Arrendamiento, para la recuperación del bien inmueble. ',
  'Intervención extrajudicial por incumplimiento del Contrato de Arrendamiento.',
  'Recuperación judicial del inmueble por incumplimiento del Contrato de Arrendamiento.',
  'Cobranza judicial por adeudos a causa de rentas y servicios vencidos.',
  'Cobranza judicial por daños al inmueble por parte del inquilino.',
  'Elaboración del Convenio para la terminación contractual y devolución del bien inmueble.',
  'Honorarios de abogados y gastos legales de los procedimientos legales.',
  'Protección contra la ley de extinción de dominio.',
  'Herramienta de Administración para tus Inmuebles.',
  'Garantia Navio'
]


export const LegalGuaranteeItems = [
  'Asistencia legal durante la vigencia de la cobertura.',
  'Investigación judicial del inquilino.',
  'Investigación judicial del fiador.',
  'Investigación del fiador en el Registro Público de la Propiedad y del Comercio.',
  'Creación del Contrato de Arrendamiento.',
  'Ratificación del Contrato de Arrendamiento ante fedatario público.',
  'Acompañamiento a la firma del Contrato de Arrendamiento.',
  'Periodo de gracia, una vez vencida la vigencia del Contrato de Arrendamiento, para la recuperación del bien inmueble. ',
  'Intervención extrajudicial por incumplimiento del Contrato de Arrendamiento.',
  'Recuperación judicial del inmueble por incumplimiento del Contrato de Arrendamiento.',
  'Cobranza judicial por adeudos a causa de rentas y servicios vencidos.',
  'Cobranza judicial por daños al inmueble por parte del inquilino.',
  'Elaboración del Convenio para la terminación contractual y devolución del bien inmueble.',
  'Honorarios de abogados y gastos legales de los procedimientos legales.',
  'Protección contra la ley de extinción de dominio.',
  'Herramienta de Administración para tus Inmuebles.',
  'Garantia Navio'
]

import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, Grid, Card, CardHeader, CardContent, Button, CardActions } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import {
  UserProfile,
  UserDetails,
  UserPassword,
  UserProperties,
  UserProducts,
  ClientDetailForm,
} from './components';
import { TenantsTable } from '../TenantList'
import { useQuery } from '@apollo/react-hooks';
import { FETCH_USER_FULL } from 'constants/queries';
import { Can } from 'lib/can'
import { subject } from '@casl/ability';

import { StoreContext } from 'App'
import DefineAbilityFor from 'constants/abilities';
import { AddressForm } from 'views/AddressView'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    padding: 0
  }
}));

const User = props => {
  const { t } = useTranslation()
  const { userId } = useParams();
  const { className, user, ...rest } = props;
  const { data } = useQuery(FETCH_USER_FULL, {
    variables: { query: { id: userId } }
  });

  const classes = useStyles();
  const context = useContext(StoreContext)
  const ability = DefineAbilityFor(context.state.session)
  const history = useHistory()
  

  if (data && data.users[0]) {
    const user = data.users[0]
    const tenants = user.tenants
    const properties = user.properties
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={4} md={6} xl={4} xs={12}>
            <UserProfile user={user} />
          </Grid>
          <Grid item lg={8} md={6} xl={8} xs={12}>
            <UserDetails user={user} />
          </Grid>

          <Grid item md={4} xs={12}/>
          <Grid item lg={8} md={6} xl={8} xs={12}>
              {ability.can('update', subject(user)) && <UserPassword user={user} /> }
          </Grid>
            {user.credentials == 'client' && (
              <Grid item xs={12}>
                <ClientDetailForm user={user}/>
              </Grid>
            )}
          {user.credentials == 'client' && (
          <React.Fragment>
            <Grid item xs={12}>
              <AddressForm 
                address={user.clientDetail?.address}
                addressableId={user.clientDetail?.id}
                addressableType={'ClientDetail'}
                fieldsEnabled={user.clientDetail?.address? ability.can('update', subject('address', user.clientDetail?.address)) : true }
                onCompleted={
                  () => context.state.showAlert({
                    severity: 'success',
                    message: `${t('address')} ${user.clientDetail?.address? t('updated') : t('created')}`
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <UserProperties properties={properties} />
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader title={t('tenants')}/>
                <Divider />
                <CardContent className={classes.contexnt}>
                  <TenantsTable tenants={tenants} />
                </CardContent>
                <Can I='create' a='tenant'>
                  <CardActions>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => history.push('/tenants/create')}>
                      {t('add_x', {x: t('tenant')})}
                    </Button>
                  </CardActions>
                </Can>
              </Card>
            </Grid>
          </React.Fragment>
          )}
        </Grid>
      </div>
    );
  }
  return <div></div>;
};

export default User;

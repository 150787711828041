import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/styles';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardContent,
  Typography,
  Grid

} from '@material-ui/core'

import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import ErrorIcon from '@material-ui/icons/Error';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { DELETE_FILE  } from 'constants/queries'
import { StoreContext } from 'App'
import Helpers from 'lib/helpers'

const useStyles = makeStyles((theme) => ({
  fileCard: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto, sans-serif',
    padding: '1rem',
    transition: 'all 0.3s',
    border: '1px solid',
    borderColor: props => props.hasFile ? theme.palette.success.light : 'white',
    '&:hover': {
      boxShadow: '0 3px 6px rgba(0,0,0,0.5)'
    }
  },
  deleteFileIcon: {
    color: 'red'
  },
  fileCardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fileCardIcon: {
    marginRight: '1rem'
  },
  clickableIcon: {
    cursor: 'pointer'
  }
}))

const FileCard = ({fileLink}) => {
  const classes = useStyles();
  const context = useContext(StoreContext)
  const { t } = useTranslation();

  const [deleteFile, deletionProgress] = useMutation(DELETE_FILE, {
    onCompleted: ({deleteFile}) => {
      if(deleteFile.result)
        context.state.showAlert({
          severity: 'success',
          message: `${t('file_deleted')}`
        })
      else
        context.state.showAlert({
          severity: 'error',
          message: `${t('file_deletion_failure')}`
        })
    }
  })

  return (
    <Grid item xs={4} key={fileLink.name}>
      <a href={fileLink.link}>
        <Card className={classes.fileCard}>
          <CardContent className={classes.fileCardContent}>
            <div className={classes.fileCardIcon}>
              <DescriptionIcon />
            </div>
            <Typography variant="subtitle1">{fileLink.name}</Typography>
          </CardContent>
          <div
            className={classes.deleteFileIcon}
            onClick={e => {
              e.preventDefault();
              deleteFile({
                variables: { id: fileLink.attachmentId }
              });
            }}>
            <ClearIcon />
          </div>
        </Card>
      </a>
    </Grid>
  )
}

export default FileCard

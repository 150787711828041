export const ErrorTypes = {COMMUNICATION: 0, BUSINESS: 1, PRESENTATION: 2}
export const ErrorLevels = {CRASH: 0, CONTINUE: 1}

class ErrorObject {
  constructor({message, errorType, functionCall, incidentTime, level = ErrorLevels.CRASH}) {
    this.message = message
    this.errorType = errorType
    this.functionCall = functionCall
    this.incidentTime = incidentTime || new Date()
    this.level = level
  }
}

export default ErrorObject

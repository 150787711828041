import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { useForm, Controller } from 'react-hook-form';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Typography,
  Paper,
  Chip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { LoadingBar } from 'components';
import { StoreContext } from 'App'
import { UPDATE_INVOICE } from 'constants/queries'
import { PersonTypes } from 'constants/formOptions'
import Helpers, { parseAddress, Invoice } from 'lib/helpers'

const { parseCurrency } = Helpers


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  highlight: {
    padding: theme.spacing(3),
    borderRadius: '1rem',
    marginTop: '-6rem',
    display: 'flex',
    marginBottom: '2rem',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '90%'
  },
  textField: {
    padding: '1.3em'
  },
  spikes: {
    position: 'relative',
    background: 'white',
    backgroundImage: 'linear-gradient(135deg, white 25%, transparent 25%), linear-gradient(225deg, white 25%, transparent 25%)',
    height: '3vh',
    '&::after': {
      content: '""',
      position: 'absolute',
      right: '0',
      left: '-0%',
      top: '100%',
      zIndex: '10',
      display: 'block',
      height: '50px',
      backgroundSize: '52px 100%',
      backgroundImage: 'linear-gradient(135deg, white 25%, transparent 25%), linear-gradient(225deg, white 25%, transparent 25%)',
      backgroundPosition: '0 0'
    }
  },
  chip: {
    backgroundColor: theme.palette.error.light
  },
  paddedText: {
    padding: '0.5rem'
  },
  discreteField: {
    width: '3em',
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      transition: 'border-color: 0.3s',
      borderColor: 'transparent'
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'inherit'
    }
  }

}));

export const getInvoiceAttributes = data => ({
  discount: data.discount
})

export const invoiceDirtyFields = [ 'discount' ]

export const InvoiceFields = (props) => {
  const { register, control, invoice, fieldsEnabled, errors, setValue, classes } = props
  const { t } = useTranslation()

  return (
    <React.Fragment>

    </React.Fragment>
  )
}

const InvoiceHighlight = (params) => {
  const { invoice, ...props} = params
  const classes = useStyles()
  const { t } = useTranslation()
  const client = invoice.user
  const history = useHistory()

  return (
    <Paper className={classes.highlight}>
      <div style={{display: 'flex', flexDirection: 'column', flex: '2', justifyContent: 'center'}}> 
        <Typography variant='h6' style={{textTransform: 'uppercase', color: '#9ca3a8'}} >
            {t('invoice_for')}
        </Typography>
        <Typography variant='h5' style={{margin: '0.25rem 0', cursor: 'pointer'}} onClick={() => history.push('/clients/view/' + client.id)}>
            {client.fullName}
        </Typography>
        <Typography variant='h6' style={{color: '#9ca3a8', maxWidth: '80%'}}>
            {client.clientDetail.address ? parseAddress(client.clientDetail.address) : 'Sin dirección'}
        </Typography>
      </div>

      <Paper elevation={0} style={{border: '1px solid #e4e2e2', flex: '1', display: 'flex', flexDirection: 'column', backgroundColor: '#f4f7fc', padding: '1em'}} >
        <Typography variant='subtitle1' style={{textTransform: 'uppercase', color: '#9ca3a8'}}>
            {t('amount_due')}
        </Typography>
        <Typography variant='h3'>
            <span style={{color: '#9ca3a8'}}>$</span>{parseCurrency(props.total)}
        </Typography>
        <Typography variant='subtitle1'>
            {moment(invoice.assignedDate).format('MMM DD, YYYY')}
        </Typography>
      </Paper>

    </Paper>
  )
}

const InvoiceHeader = ({invoice}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const tenant = invoice.property.tenant;

  return (
    <div className={classes.root} style={{background: '#0e163c', borderRadius: '1em', borderBottomRightRadius: '2em', borderBottomLeftRadius: '2em', paddingTop: '5rem', paddingBottom: '5rem'}}>
        {invoice.isLate && (
          <Chip label={t('late')} className={classes.chip}/>
        )}
      <div>
          {tenant ? (
            <Typography variant='h1' style={{color: 'white', cursor: 'pointer'}} onClick={() => history.push('/tenants/view/' + tenant.id)}>
                {tenant.fullName}
            </Typography>
          ) : (
              <Typography variant='h1' style={{color: 'white', cursor: 'pointer'}} >
                Inquilino no registrado
            </Typography>
          )}
        <Typography variant='h3' style={{color: 'white', cursor: 'pointer'}} onClick={() => history.push('/properties/view/' + invoice.property.id)}>
            {parseAddress(invoice.property.address, t)}
        </Typography>
      </div>
    </div>
  )
}

const InvoiceSummary =({invoice, taxedAmount, discount, risk, total, onSubmit, dirty}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const invoiceHelper = new Invoice({subtotal: invoice.subtotal, tax: 16, discount: discount[0], riskInterest: risk[0]})
  const updateField = (fn, val) => {
    dirty[1](true)
    fn[1](val)
  }



  return (
  <form className={classes.InvoiceSummary} onSubmit={onSubmit}>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <div>
        <Typography variant='h5' className={classes.paddedText} style={{textTransform: 'uppercase', color: '#9ca3a8'}}>
            {t('task')}
        </Typography>

        <Typography variant='h5' className={classes.paddedText}>
            {t('import_price')}
        </Typography>

        <Typography variant='h5' className={classes.paddedText}>
            {t('discount')}
        </Typography>

        <Typography variant='h5' className={classes.paddedText}>
            {t('riskInterest')}
        </Typography>

        <Typography variant='h5' className={classes.paddedText}>
            {t('subtotal')}
        </Typography>

        <Typography variant='h5' className={classes.paddedText}>
            {t('tax')}
        </Typography>
      </div>

      <div>
        <Typography variant='h5'  className={classes.paddedText} style={{textTransform: 'uppercase', color: '#9ca3a8' }}>
            {t('total')}
        </Typography>
        <Typography variant='h5'  className={classes.paddedText} style={{textAlign: 'right'}}>
            <span style={{color: '#9ca3a8'}}>$</span>{parseCurrency(invoiceHelper.getImport())}
        </Typography>
        <Typography variant='h5'  className={classes.paddedText} style={{textAlign: 'right'}}>
          <span style={{color: '#9ca3a8', marginRight: '1em'}}>{parseInt(discount[0]/invoice.subtotal*100)}%</span>
          <span style={{color: '#9ca3a8'}}>$</span>
          <input type="number" style={{width: '5em', textAlign: 'right'}} value={discount[0]} onChange={e => updateField( discount, parseFloat(e.target.value))} />
        </Typography>

        <Typography variant='h5'  className={classes.paddedText} style={{textAlign: 'right'}}>
          <span style={{color: '#9ca3a8', marginRight: '1em'}}>{parseInt(risk[0]/invoice.subtotal*100)}%</span>
          <span style={{color: '#9ca3a8'}}>$</span>
          <input type="number" style={{width: '5em', textAlign: 'right'}} value={risk[0]} onChange={e => updateField(risk, parseFloat(e.target.value))} />
        </Typography>

        <Typography variant='h5' style={{textAlign: 'right'}} className={classes.paddedText}>
            {parseCurrency(invoiceHelper.getSubtotal())}
        </Typography>

        <Typography variant='h5' style={{textAlign: 'right'}} className={classes.paddedText}>
            {parseCurrency(taxedAmount)}
        </Typography>
      </div>
    </div>

    <Divider/>

    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <div>
          <Button disabled={!dirty[0]} color='primary' variant='contained' type='submit'>{t('update')}</Button>
      </div>
      <div>
        <Typography variant='h5' style={{color: '#9ca3a8'}} className={classes.paddedText}>
          {t('total_amount')}
        </Typography>
        <Typography variant='h3' className={classes.paddedText} style={{textAlign: 'right'}}>
          <span style={{color: '#9ca3a8'}}>$</span>{invoiceHelper.getTotal()}
        </Typography>
      </div>
    </div>
  </form>

  )
}

const InvoiceForm = (props) => {
  const { invoice, onCompleted, fieldsEnabled, ...rest} = props
  const classes = useStyles()
  const context = useContext(StoreContext)
  const discount = useState(invoice.discount || 0)
  const risk = useState(invoice.riskInterest || 0)
  const dirty  = useState(false)


  const subtotal = (invoice.subtotal + risk[0] - discount[0])
  const tax = parseFloat((subtotal * 0.16).toFixed(2))
  const total = parseFloat((subtotal + tax).toFixed(2))


  const { t } = useTranslation()
  const form = useForm()
  const [mutate, mutationStatus] = useMutation(UPDATE_INVOICE, { onCompleted })
  const { formState } = form

  const onSubmit = (e) => {
    e.preventDefault()
    let payload = {variables: {}}
    if(invoice) payload.variables.id = invoice.id
    payload.variables.input = { discount: discount[0], riskInterest: risk[0] }
    mutate(payload)
  }


  return (
    <Paper style={{flexDirection: 'column', backgroundColor: 'white'}} elevation={1}>
      <InvoiceHeader invoice={invoice}/>
      <div className={classes.root}>
        <InvoiceHighlight invoice={invoice} total={total}/>
        <InvoiceSummary invoice={invoice} taxedAmount={tax}  total={total}
          discount={discount}
          risk={risk}
          onSubmit={onSubmit}
          dirty={dirty}
        />
      </div>
      <section className={classes.spikes}></section>
    </Paper>
  )

}

export default InvoiceForm

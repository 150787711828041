import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { LotsToolbar, LotsTable } from './components';
import { useQuery } from '@apollo/react-hooks';
import { FETCH_LOTS } from 'constants/queries';
import mockData from './data';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const LotList = () => {
  const classes = useStyles();
  const { data } = useQuery(FETCH_LOTS);
  const filter = useState('');
  if (data) {
    const lots = data.lots.filter(lot =>
      (lot.provider.firstName + lot.provider.lastName).match(
        new RegExp(filter[0], 'i')
      )
    );

    return (
      <div className={classes.root}>
        <LotsToolbar filter={filter} />
        <div className={classes.content}>
          <LotsTable lots={lots} />
        </div>
      </div>
    );
  }
  return <div></div>;
};

export default LotList;

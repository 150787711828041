import React from 'react'
import { Grid } from '@material-ui/core'
import {FileCard} from 'components'

const FileCardList = ( {FileLinks = []} ) => {
  return (
    <React.Fragment>
      {FileLinks.map(e => (
      <Grid item xs={12} md={6}>
        <FileCard fileLink={e} title={e.name} canEdit={false} />
      </Grid>
      ))}
    </React.Fragment>
  )
}

export default FileCardList

import React, { useContext } from 'react'
import {
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  Button,
  InputLabel,
  FormHelper,
  FormHelperText,
  FormControl
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Controller } from 'react-hook-form'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%'
  },
}))

const Select = (props) => {
  const {
    name,
    subject,
    control,
    options,
    fieldsDisabled,
    sv,
    errors,
    formControlProps,
    label,
    multiple = false,
    required = false,
    getDefaultMatcher = (option, subject) => option == subject[name]
  } = props
  const { t } = useTranslation()
  const classes = useStyles()
  let defaultValue = null

  const traverseObject = (obj, string) => string.split('.').reduce((o,i, _, arr) => {
      const ifObjectExists = o => !!o
      if(!ifObjectExists(o)) {arr.splice(1); return null}
      return o[i]
    }, obj
  )

  try {
    defaultValue = subject ? options.find(e => getDefaultMatcher(e.value, subject)).value : null
  } catch(e) {
    defaultValue = null
  }

  return (
    <FormControl
      fullWidth
      variant='outlined'
      errors={traverseObject(errors, name)}
      style={{margin: '0', width: '100'}}
      className={clsx(classes.formControl, props.className)}
      {...formControlProps}
    >
      <Controller
        defaultValue={defaultValue}
        name={name}
        control={control}
        rules={{required: required}}
        as={({value, onChange}) => (
          <Autocomplete
            multiple={multiple}
            disabled={fieldsDisabled}
            id={name}
            value={ multiple ? (value ? value : []) : (value && value.label ? value : options.find(e => e.value == value) )}
            onChange={(_, input) => {sv(name, multiple ? input : input.value)}}
            options={options}
            getOptionLabel={option => option.label}
            renderInput={params => {return (
              <TextField
                {...params}
                required={required}
                error={traverseObject(errors, name)}
                label={label ? label : t(name)}
                variant='outlined'
              />
             )}}
          />
        )}
      />
      <FormHelperText>
          {traverseObject(errors, name) && t(traverseObject(errors, name).type)}
      </FormHelperText>
    </FormControl>
  )
}

export default Select

import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useForm, Controller, useFormContext } from 'react-hook-form';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next'

import { LoadingBar, Select } from 'components';
import { StoreContext } from 'App'
import { CREATE_AVAL, UPDATE_AVAL } from 'constants/queries'
import { PersonTypes } from 'constants/formOptions'



const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '90%'
  },
  textField: {
    padding: '1.3em'
  }
}));

export const getAvalAttributes = data => ({
    personType: data.aval.personType,
    socialReason: data.aval.socialReason,
    firstName: data.aval.firstName,
    lastName: data.aval.lastName,
    phone: data.aval.phone,
    mail: data.aval.mail,
    rfc: data.aval.rfc,
    legalRepresentativeFirstName: data.aval.legalRepresentativeFirstName,
    legalRepresentativeLastName: data.aval.legalRepresentativeLastName,
    legalRepresentativeEmail: data.aval.legalRepresentativeEmail,
    legalRepresentativePhone: data.aval.legalRepresentativePhone,
})

  export const avalDirtyFields = [
    'aval.personType',
    'aval.socialReason',
    'aval.firstName',
    'aval.lastName',
    'aval.phone',
    'aval.mail',
    'aval.rfc',
    'aval.legalRepresentativeFirstName',
    'aval.legalRepresentativeLastName',
    'aval.legalRepresentativeEmail',
    'aval.legalRepresentativePhone',
    'aval',
  ]

export const AvalFields = (props) => {
  const { aval } = props
  const { register, control, watch, errors, setValue, classes, fieldsEnabled } = useFormContext()
  const { t } = useTranslation()
  console.log('aval form rendered')
  const watchAll = watch()

  return (
    <React.Fragment>
      <Grid item xs={6}>
        <Select 
          name='aval.personType'
          getDefaultMatcher={(option, subject) => option == subject.personType}
          required={true}
          subject={aval}
          control={control}
          options={PersonTypes.map(p => ({...p, label: t(p.label)}))}
          errors={errors}
          sv={setValue}
          label={t('personType')}
        />
      </Grid>

      <Grid item xs={6}>
      </Grid>


        {watchAll.aval?.personType == 'physical' && (
          <React.Fragment>
            <Grid item xs={6} md={3}>
                <TextField
                  defaultValue={aval ? aval.firstName : null}
                  fullWidth
                  disabled={!fieldsEnabled}
                  label={t('firstName')}
                  margin="dense"
                  name="aval.firstName"
                  required
                  variant="outlined"
                  inputRef={register({ required: true })}
                  error={errors.aval?.firstName}
                  helperText={errors.aval?.firstName && t(errors.aval?.firstName?.type)}
                />
            </Grid>
            <Grid item xs={6} md={3}>
                <TextField
                  fullWidth
                  defaultValue={aval ? aval.lastName : null}
                  disabled={!fieldsEnabled}
                  label={t('lastName')}
                  margin="dense"
                  name="aval.lastName"
                  required
                  variant="outlined"
                  inputRef={register({ required: true })}
                  error={errors.aval?.lastName}
                  helperText={errors.aval?.lastName && t(errors.aval?.lastName?.type)}
                />
              </Grid>
            <Grid item md={3} xs={6}>
                <TextField
                  fullWidth
                  defaultValue={aval ? aval.phone : null}
                  disabled={!fieldsEnabled}
                  label={t('phone')}
                  margin="dense"
                  name="aval.phone"
                  required
                  variant="outlined"
                  inputRef={register({ required: true })}
                  error={errors.aval?.phone}
                  helperText={errors.aval?.phone && t(errors.aval?.phone?.type)}
                />
            </Grid>
            <Grid item md={3} xs={6}>
                <TextField
                  fullWidth
                  defaultValue={aval ? aval.mail : null}
                  disabled={!fieldsEnabled}
                  label={t('mail')}
                  margin="dense"
                  name="aval.mail"
                  required
                  variant="outlined"
                  inputRef={register({ required: true })}
                  error={errors.aval?.mail}
                  helperText={errors.aval?.mail && t(errors.aval?.mail?.type)}
                />
            </Grid>
          </React.Fragment>
        )}
          

        {watchAll.aval?.personType == 'moral' && (
          <React.Fragment>

            <Grid item xs={3}>
              <TextField
                defaultValue={aval ? aval.socialReason : null}
                fullWidth
                disabled={!fieldsEnabled}
                label={t('socialReason')}
                margin="dense"
                name="aval.socialReason"
                required
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.aval?.socialReason}
                helperText={errors.aval?.socialReason && t(errors.aval?.socialReason?.type)}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                defaultValue={aval ? aval.rfc : null}
                fullWidth
                disabled={!fieldsEnabled}
                label={t('rfc')}
                margin="dense"
                name="aval.rfc"
                required
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.aval?.rfc}
                helperText={errors.aval?.rfc && t(errors.aval?.rfc?.type)}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography>{t('avalLegalRepresentative')}</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                defaultValue={aval ? aval.legalRepresentativeFirstName : null}
                fullWidth
                disabled={!fieldsEnabled}
                label={t('firstName')}
                margin="dense"
                name="aval.legalRepresentativeFirstName"
                required
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.aval?.legalRepresentativeFirstName}
                helperText={errors.aval?.legalRepresentativeFirstName && t(errors.aval?.legalRepresentativeFirstName?.type)}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                defaultValue={aval ? aval.legalRepresentativeLastName : null}
                fullWidth
                disabled={!fieldsEnabled}
                label={t('lastName')}
                margin="dense"
                name="aval.legalRepresentativeLastName"
                required
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.aval?.legalRepresentativeLastName}
                helperText={errors.aval?.legalRepresentativeLastName && t(errors.aval?.legalRepresentativeLastName?.type)}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                defaultValue={aval ? aval.legalRepresentativePhone : null}
                fullWidth
                disabled={!fieldsEnabled}
                label={t('phone')}
                margin="dense"
                name="aval.legalRepresentativePhone"
                required
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.aval?.legalRepresentativePhone}
                helperText={errors.aval?.legalRepresentativePhone && t(errors.aval?.legalRepresentativePhone?.type)}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                defaultValue={aval ? aval.legalRepresentativeEmail : null}
                fullWidth
                disabled={!fieldsEnabled}
                label={t('mail')}
                margin="dense"
                name="aval.legalRepresentativeEmail"
                required
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.aval?.legalRepresentativeEmail}
                helperText={errors.aval?.legalRepresentativeEmail && t(errors.aval?.legalRepresentativeEmail?.type)}
              />
            </Grid>

          </React.Fragment>
        )}
    </React.Fragment>
  )
}

const AvalForm = (props) => {
  const { aval, onCompleted, fieldsEnabled, ...rest} = props
  const classes = useStyles()
  const context = useContext(StoreContext)

  const { t } = useTranslation()
  const { register, errors, handleSubmit, setValue, control } = useForm()
  const [mutate, mutationStatus] = useMutation(aval ? UPDATE_AVAL : CREATE_AVAL, { onCompleted })

  const onSubmit = ({region, ...data}) => {
    let payload = {variables: {}}
    if(aval) payload.variables.id = aval.id
    payload.variables.input = { ...data, userId: context.state.session.id }
    mutate(payload)
  }


  return (
    <Card {...rest} className={classes.root}>
      <form autoComplete='off' noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title={t('aval')} subheader={fieldsEnabled ? t('information_editable') : ''}/>
        <Divider/>
        <CardContent>
          <Grid container spacing={3}>
            <AvalFields { ...{ control, aval, fieldsEnabled, errors, setValue, classes, register }} />
          </Grid>
        </CardContent>
        <Divider/>
        <CardActions>
          {fieldsEnabled && (
            <Button color='primary' variant='contained' type='submit' disabled={mutationStatus.loading}>
                {`${aval ? t('update') : t('create')} ${t('aval')}`}
            </Button>
          )}
        </CardActions>
      </form>
      { mutationStatus.loading && <LoadingBar/> }
    </Card>
  )

}

export default AvalForm

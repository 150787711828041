import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button
} from '@material-ui/core';
import { subject } from '@casl/ability';

import { getInitials } from 'helpers';
import Helpers from 'lib/helpers';
import { DELETE_LOT, FETCH_LOTS } from 'constants/queries';
import { StoreContext } from 'App';
import { Can } from 'lib/can'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LotsTable = props => {
  const { className, lots, ...rest } = props;
  const { t } = useTranslation()

  const classes = useStyles();
  const history = useHistory();
  const context = useContext(StoreContext);
  const handleDelSuccess = () =>
    context.state.showAlert({ severity: 'warning', message: 'Lote Eliminado' });
  const [deleteLot] = useMutation(DELETE_LOT, {
    onCompleted: handleDelSuccess,
    update: (cache, { data }) => {
      const { lot } = cache.readQuery({ query: FETCH_LOTS });
      cache.writeQuery({
        query: FETCH_LOTS,
        data: { lots: lots.filter(l => l.id != data.lot.destroy) }
      });
    }
  });

  const [selectedLots, setSelectedLots] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {
    const { lots } = props;

    let selectedLots;

    if (event.target.checked) {
      selectedLots = lots.map(lot => lot.id);
    } else {
      selectedLots = [];
    }

    setSelectedLots(selectedLots);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedLots.indexOf(id);
    let newSelectedLots = [];

    if (selectedIndex === -1) {
      newSelectedLots = newSelectedLots.concat(selectedLots, id);
    } else if (selectedIndex === 0) {
      newSelectedLots = newSelectedLots.concat(selectedLots.slice(1));
    } else if (selectedIndex === selectedLots.length - 1) {
      newSelectedLots = newSelectedLots.concat(selectedLots.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedLots = newSelectedLots.concat(
        selectedLots.slice(0, selectedIndex),
        selectedLots.slice(selectedIndex + 1)
      );
    }

    setSelectedLots(newSelectedLots);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedLots.length === lots.length}
                      color="primary"
                      indeterminate={
                        selectedLots.length > 0 &&
                        selectedLots.length < lots.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>{ t('requirement') }</TableCell>
                  <TableCell>{ t('units') }</TableCell>
                  <TableCell>{ t('price_per_unit') }</TableCell>
                  <TableCell>{ t('expiration_date') }</TableCell>
                  <TableCell>{ t('delivery_date') }</TableCell>
                  <TableCell>{ t('actions') }</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lots.slice(0, rowsPerPage).map(lot => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={lot.id}
                    selected={selectedLots.indexOf(lot.id) !== -1}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedLots.indexOf(lot.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, lot.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          {lot.requirement.name}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>{lot.requirement.units}</TableCell>
                    <TableCell>{lot.pricePerUnit}</TableCell>
                    <TableCell>
                      {moment(lot.expirationDate).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      {moment(lot.deliveryDate).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => history.push('/lots/view/' + lot.id)}>
                        { t('action_see') }
                      </Button>
                      {'  /  '}
                      <Can I='destroy' this={subject('lot', lot)}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() =>
                            deleteLot({ variables: { id: lot.id } })
                          }>
                          { t('action_delete') }
                        </Button>
                      </Can>
                      {'  /  '}

                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={lots.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

LotsTable.propTypes = {
  className: PropTypes.string,
  lots: PropTypes.array.isRequired
};

export default LotsTable;

import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Divider, Grid, Card, CardHeader, CardContent, Button, CardActions } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { PremiumItems } from 'constants/PolicyTypes'

const useStyles = makeStyles(theme => ({
  normalText: {
    color: theme.palette.primary.main,
  },
  root: {
    padding: '4em',
  },
  content: {
    padding: 0
  },
  heading: {
    textTransform: 'uppercase'
  },
  highlight: {
    color: theme.palette.navio.red
  },
  swoosh: {
    backgroundImage: 'url(/images/navio-swoosh.png)'
  },
  paragraph: {
    margin: '1em 0'
  },
  leftCol: {
    paddingRight: '3em !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

const Items = PremiumItems

const WelcomeLetterPremium = props => {
  const { client } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const Item =({children}) => (
  <div style={{display: 'flex', alignItems: 'center', margin: '1em 0'}}>
      <img src={process.env.PUBLIC_URL + '/images/navio-swoosh.png'} style={{width: '4em'}}/>
      <Typography variant='subtitle1'>{children}</Typography>
    </div>
  )

  return (
    <div className={clsx(classes.root, 'document')}>
      <Grid container spacing={3}>
        <Grid item xs={4} className={classes.leftCol}>
          <div>
            <Typography style={{fontWeight: 'bold', textTransform: 'uppercase'}}>
              DIRECCIÓN:
            </Typography>
            <Typography>
              Avenida Roble 660,
            </Typography>
            <Typography>
              Col. Valle del Campestre, 66265 San Pedro Garza García, Monterrey, N.L.
            </Typography>
          </div>
          <div>
            <div style={{margin: '2em 0'}}>
              <Typography>
                Para más información acerca de tu Póliza, comunícate con nosotros:
              </Typography>
            </div>
            <div style={{margin: '0.5em 0'}}>
              <Typography>
                Desde Monterrey:
              </Typography>
              <Typography style={{fontWeight: 'bold'}}>
                  {t('navio_phone')}
              </Typography>
            </div>
            <div>
              <Typography>
                info@navio.mx
              </Typography>
              <Typography style={{fontWeight: 'bold'}}>
                www.navio.mx
              </Typography>
            </div>

          </div>
        </Grid>

        <Grid item xs={8}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <img src={process.env.PUBLIC_URL + '/images/leftTab.png'} style={{width: '4em'}}/>
            <Typography variant='h1'>Cobertura Premium</Typography>
          </div>
            <Typography variant='h5' className={clsx(classes.highlight)} style={{margin: '1em 0 2em 0'}}> Estimado Cliente: <span className={classes.normalText}>{client.fullName}</span> </Typography>
            <Typography className={classes.paragraph}>
              Te extendemos nuestro más amplio agradecimiento por la confianza que nos otorgas al haber adquirido el blindaje legal para tu bien inmueble con <span style={{textTransform: 'uppercase', fontWeight: 'bold'}}>Navío </span>
              por medio de nuestra Póliza Jurídica Inmobiliaria.
            </Typography>
            <Typography className={classes.paragraph}>
              La cobertura Premium para tu inmueble comenzará a partir de la Fecha Efectiva mencionada en la Carátula e incluye:
            </Typography>
              {
                Items.map(e => <Item>{e}</Item>)
              }
            <Typography className={classes.paragraph}>
              La presente carta de bienvenida es de carácter informativo; todas las condiciones generales de la Póliza Jurídica Inmobiliaria Básica contratada se establecen en los Términos y Condiciones.
            </Typography>
            <Typography className={classes.paragraph}>
              Agradecemos tu preferencia y la confianza que nos brindaste, déjanos brindarte la tranquilidad que te mereces al estar protegido con <span style={{textTransform: 'uppercase', fontWeight: 'bold'}}>Navío</span>.
            </Typography>
        </Grid>
      </Grid>
    </div>
  )
};

export default WelcomeLetterPremium;

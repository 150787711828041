import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  Paper,
} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import ClearIcon from '@material-ui/icons/Clear';
import ErrorIcon from '@material-ui/icons/Error';
import { useTranslation } from 'react-i18next';
import { subject } from '@casl/ability';

import { StoreContext } from 'App';
import { Can } from 'lib/can';
import DefineAbilityFor from 'constants/abilities';
import { LoadingBar, FileCard, FileCardList } from 'components';
import Helpers from 'lib/helpers'
import { PersonTypes } from 'constants/formOptions'
import { FileBox } from 'components'
import { DELETE_FILE, UPDATE_CLIENT_DETAIL } from 'constants/queries'


const useStyles = makeStyles((theme) => ({
root: {},
  dropZoneButton: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      bockgroundColor: theme.palette.success.contrastText
    }
  },
  fileCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Roboto, sans-serif',
    padding: '1rem',
    transition: 'all 0.3s',
    '&:hover': {
      boxShadow: '0 3px 6px rgba(0,0,0,0.5)'
    }
  },
  deleteFileIcon: {
    color: 'red'
  },
  fileCardContent: {
    display: 'flex',
    alignItems: 'center'
  },
  fileCardIcon: {
    marginRight: '1rem'
  }
}));


const TextFieldGenerator = ({property, name, register, errors, type, fieldsEnabled}) =>{
    const { t } = useTranslation()
    return <TextField
      fullWidth
      required
      defaultValue={property ? property[name] : null}
      disabled={!fieldsEnabled}
      label={t(name)}
      margin="dense"
      name={name}
      type={type}
      variant="outlined"
      inputRef={register({ required: true })}
      error={errors[name]}
      helperText={errors[name] && t(errors[name].type)}
      InputLabelProps={{
        shrink: true
      }}
    />
}


const constitutiveWritingTexts = [
  {key: 'writingNumber', type: 'text'},
  {key: 'notaryName', type: 'text'},
  {key: 'publicNotary', type: 'text'},
  {key: 'notaryCity', type: 'text'},
  {key: 'registryData', type: 'text'},
  {key: 'legalRepresentative', type: 'text'},
  {key: 'writingDate', type: 'date'},
  {key: 'registrationDate', type: 'date'},
]

export const ClientDetailFiles = {
  physical: ['ine'],
  moral: [
    'ine',
    'constitutiveWriting',
    'fiscalId',
    'power',
  ]
}
const allClientDetailFiles = [...ClientDetailFiles['physical'], ...ClientDetailFiles['moral']]


export const allClientDetailDirtyFields = [
  'others',
  'ine',
  'constitutiveWriting',
  'fiscalId',
  'power',
  'rfc',
  'socialReason',
  'personType',
  'writingNumber',
  'notaryName',
  'publicNotary',
  'notaryCity',
  'registryData',
  'legalRepresentative',
  'writingDate',
  'registrationDate',
]



export const getAllClientDetailAttributes = data => ({
    ine: data.ine,
    constitutiveWriting: data.constitutiveWriting,
    fiscalId: data.fiscalId,
    power: data.power,
    others: data.others,
    rfc: data.rfc,
    socialReason: data.socialReason,
    personType: data.personType,
    writingNumber: data.writingNumber,
    notaryName: data.notaryName,
    publicNotary: data.publicNotary,
    notaryCity: data.notaryCity,
    registryData: data.registryData,
    legalRepresentative: data.legalRepresentative,
    writingDate: data.writingDate,
    registrationDate: data.registrationDate,
  }
)

export const ClientDetailMoralFields = props => {
  const {
    clientDetail, watchAll, register,
    property, fieldsEnabled, errors, setValue
  } = props 
  const { t } = useTranslation()

  return (
    <React.Fragment>
        {constitutiveWritingTexts.map(e => {
          return (
            <Grid item md={4} xs={6} key={e.key}>
              <TextField
                required
                fullWidth
                margin='dense'
                variant='outlined'
                name={e.key}
                type={e.type}
                error={errors[e.key]}
                label={t(e.key)}
                disabled={!fieldsEnabled}
                defaultValue={clientDetail && clientDetail[e.key]}
                helperText={errors[e.key] && t(errors[e.key].type)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          )
        })}
    </React.Fragment>
  )
}


export const ClientDetailFileFields = props => {
  const {
    clientDetail, watch, control, register,
    property, fieldsEnableds, errors, setValue
  } = props 
  const watchAll = watch()
  const context = useContext(StoreContext);
  const ability = DefineAbilityFor(context.state?.session)

  const { t } = useTranslation()
  useEffect(() => {
    if(watchAll.personType)
      ClientDetailFiles[watchAll.personType].forEach(e => register(e))
  }, [watchAll.personType])

  useEffect(() => {
    register('others')
  }, [])

  if(watchAll.personType) {
    return (
      <React.Fragment>
          {ClientDetailFiles[watchAll.personType].map(f => (
            <Grid item xs={6}>
              <FileBox
                label={t(f)}
                onSave={(file) => {setValue(f, file)}}
                fileLink={ clientDetail && clientDetail[f] }
                fileEditable={ability.can('edit', 'file')}
                fileDeletable={ability.can('delete', 'file')}
              />
            </Grid>
          ))}
          <Grid item xs={6}>
            <FileBox
              filesLimit={3}
              label={t('others')}
              fileEditable={ability.can('edit', 'file')}
              fileDeletable={ability.can('delete', 'file')}
              onSave={(files) => setValue('others', files)}
            />
          </Grid>
      </React.Fragment>
    )
  }

  else
    return <div/>
}


export const ClientDetailFields = props => {
  const {
    clientDetail, watch, register, control,
    property, fieldsEnableds, errors, setValue
  } = props 
  const { t } = useTranslation()
  const classes = useStyles();
  const watchAll = watch()
  const context = useContext(StoreContext);
  const ability = DefineAbilityFor(context.state?.session)
  const fieldsEnabled = ability.can('update', subject('user', props.user))

  return (
  <React.Fragment>
    <Grid item md={12}>
      <FormControl
        variant="outlined"
        error={errors.personType}
        style={{margin: '0', width: '100%'}}
        className={classes.formControl}>
        <Controller
          defaultValue={clientDetail && clientDetail.personType && PersonTypes.find(e => e.value == clientDetail.personType).value}
          name="personType"
          control={control}
          rules={{required: true}}
          as={({ value, onChange }) => (
            <Autocomplete
              id="personType"
              disabled={!fieldsEnabled}
              value={value && value.label ? value : PersonTypes.find(e => e.value == value)}
              onChange={(_, {value}) => setValue('personType', value)}
              options={PersonTypes}
              getOptionLabel={option => t(option.label)}
              renderInput={params => (
                <TextField
                  {...params}
                  required={true}
                  error={errors.personType}
                  label={t('personType')}
                  variant="outlined"
                />
              )}
            />
          )}
        />
        <FormHelperText>
          {errors.personType && t(errors.personType.type)}
        </FormHelperText>
      </FormControl>
    </Grid>

      {watchAll.personType == 'physical' && (
        <Grid item xs={6}></Grid>
      )}

      {watchAll.personType == 'moral' && (
       <Grid item xs={6}>
          <TextField
            fullWidth
            required
            disabled={!fieldsEnabled}
            label={t("socialReason")}
            margin="dense"
            name="socialReason"
            variant="outlined"
            defaultValue={clientDetail && clientDetail.socialReason}
            inputRef={register({ required: true })}
            error={errors.socialReason}
            helperText={errors.socialReason && t(errors.socialReason.type)}
          />
        </Grid>   
      )}

    

    <Grid item xs={6}>
      <TextField
        fullWidth
        required
        helperText={t("RFC")}
        label={t("RFC")}
        disabled={!fieldsEnabled}
        margin="dense"
        name="rfc"
        variant="outlined"
        defaultValue={clientDetail && clientDetail.rfc}
        inputRef={register({ required: true })}
        error={errors.rfc}
        helperText={errors.rfc && t(errors.rfc.type)}
      />
    </Grid>

  </React.Fragment>

  )
}

const ClientDetail = props => {
  const { className, user, ...rest } = props;
  const classes = useStyles();
  const context = useContext(StoreContext);
  const ability = DefineAbilityFor(context.state.session)
  const fieldsEnabled = ability.can('update', subject('user', props.user))
  const history = useHistory()
  const clientDetail = user.clientDetail;

  const [termOpen, setTermOpen] = useState(false)
  const [letterOpen, setLetterOpen] = useState(false)


  const { t, i18n } = useTranslation();
  const form = useForm({
    defaultValues: {
      personType: clientDetail?.personType
    }
  })
  const { register, errors, handleSubmit, setValue, control, watch } = form
  const [deleteFile, deletionStatus] = useMutation(DELETE_FILE, {
    onCompleted: ({ deleteFile }) => {
      if (deleteFile.result)
        context.state.showAlert({
          severity: 'success',
          message: `${t('file_deleted')}`
        });
      else
        context.state.showAlert({
          severity: 'error',
          message: `${t('file_deletion_failure')}`
        });
    }
  });
  const [updateClientDetail, mutationStatus] = useMutation(
    UPDATE_CLIENT_DETAIL,
    {
      onCompleted: () =>
        context.state.showAlert({
          severity: 'success',
          message: `${t('client_updated')}`
        })
    }
  );
  const watchAll = watch()

  useEffect(() => {
    register('reclamationLetter')
    register('termsAndConditions')
  }, [register])


  const onSubmit = data => {
      updateClientDetail({
        variables: {
          id: clientDetail.id,
          input: {
            ...data
          }
        }
      });
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardHeader
          subheader={fieldsEnabled && t("information_editable")}
          title={t("client_details")}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>

            <ClientDetailFields 
              { ...{
                clientDetail,
                watch,
                fieldsEnabled,
                errors,
                setValue,
                classes,
                register,
                control
              }} />


              {
                watchAll.personType == 'moral' && (
                  <ClientDetailMoralFields
                    { ...{
                      clientDetail,
                      watchAll,
                      fieldsEnabled,
                      errors,
                      setValue,
                      classes,
                      register,
                      control
                    }} />
                )

              }

              <ClientDetailFileFields 
                clientDetail={clientDetail}
                fieldsEnabled={fieldsEnabled}
                {...form}
              />


                {clientDetail.others.length > 0 &&  (
                  <Grid item xs={12}>
                    <Paper style={{padding: '1em', backgroundColor: '#fafafa'}}>
                     <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography variant='h4'>{t('others')}</Typography>
                        </Grid>
                        <FileCardList FileLinks={clientDetail.others}/>
                      </Grid>
                    </Paper>
                  </Grid>
                )}

              <Grid item xs={12}></Grid>
              {/* <Can I='update' this={subject('clientDetail', clientDetail)}> */}
              <Grid item md={6} xs={12}>
                <Button fullWidth className={classes.dropZoneButton} variant="contained" color="primary" onClick={() => history.push(`/document/claim/${user.id}`)}>
                    {t('reclamation_letter')}
                </Button>
              </Grid>
              <Grid item md={6} xs={12}>
                <Button fullWidth className={classes.dropZoneButton} variant="contained" color="primary" onClick={() => history.push(`/document/td/${user.id}`)}>
                    {t('terms_and_conditions')}
                </Button>
              </Grid>
            {/* </Can> */}
          </Grid>
        </CardContent>
        <Divider />
          {fieldsEnabled && (
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                disabled={mutationStatus.loading}
                type="submit"
                >
                {t("save_changes")}
              </Button>
            </CardActions>
          )}
      </form>
      {mutationStatus.loading && <LoadingBar />}
    </Card>
  );
};
export default ClientDetail


import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  Chip
} from '@material-ui/core';

import { getInitials } from 'helpers';
import { Can } from 'lib/can'
import { subject } from '@casl/ability';
import Helpers from 'lib/helpers';
import { UPDATE_REQUIREMENT } from 'constants/queries'
import { StoreContext } from 'App'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LotsTable = props => {
  const { t } = useTranslation();
  const { className, requirement, lots, ...rest } = props;

  const classes = useStyles();
  const context = useContext(StoreContext);
  const history = useHistory();

  const [ updateRequirement, mutationStatus ] = useMutation(UPDATE_REQUIREMENT, {
    onCompleted: () => context.state.showAlert({
      severity: 'success',
      message: 'Lote Elegido'
    })
  })
  const [selectedLots, setSelectedLots] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {
    const { lots } = props;

    let selectedLots;

    if (event.target.checked) {
      selectedLots = lots.map(lot => lot.id);
    } else {
      selectedLots = [];
    }

    setSelectedLots(selectedLots);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedLots.indexOf(id);
    let newSelectedLots = [];

    if (selectedIndex === -1) {
      newSelectedLots = newSelectedLots.concat(selectedLots, id);
    } else if (selectedIndex === 0) {
      newSelectedLots = newSelectedLots.concat(selectedLots.slice(1));
    } else if (selectedIndex === selectedLots.length - 1) {
      newSelectedLots = newSelectedLots.concat(selectedLots.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedLots = newSelectedLots.concat(
        selectedLots.slice(0, selectedIndex),
        selectedLots.slice(selectedIndex + 1)
      );
    }

    setSelectedLots(newSelectedLots);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={t("lot")} />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("contact")}</TableCell>
                  <TableCell>{t("price_per_unit")}</TableCell>
                  <TableCell>{t("expiration_date")}</TableCell>
                  <TableCell>{t("user_actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lots.slice(0, rowsPerPage).map(lot => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={lot.id}
                    selected={selectedLots.indexOf(lot.id) !== -1}>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1" style={{marginRight: '1em'}}>
                          {lot.contactEmail}
                        </Typography>
                          {lot.accepted && <Chip color='primary' label={ t('accepted') }/>}
                      </div>
                    </TableCell>
                    <TableCell>{lot.pricePerUnit}</TableCell>
                    <TableCell>
                      {moment(lot.expirationDate).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => history.push('/lots/view/' + lot.id)}>
                        { t('action_see') }
                      </Button>
                      {'  /  '}
                      <Button
                        color='primary'
                        variant='contained'
                        disabled={requirement.status == 'inactive'}
                        onClick={() =>
                          updateRequirement({
                            variables: {
                              id: requirement.id,
                              input: {
                                acceptedLotId: lot.id,
                                status: 'inactive'
                              }
                            }
                          })
                        }
                      >
                          { t('choose_lot') }
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={lots.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

LotsTable.propTypes = {
  className: PropTypes.string,
  lots: PropTypes.array.isRequired
};

export default LotsTable;

import React, {useContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/styles';
import { Typography,
 Divider,
 Grid,
 Card,
 CardHeader,
 CardContent,
 Button,
CardActions,
TextField,
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks'
import clsx from 'clsx'
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CircularProgress from '@material-ui/core/CircularProgress';

import Helpers, { calculateYearlyPolicyCost } from 'lib/helpers'
import { CalculatorCard } from 'views'
import debounce from 'lib/debounce'
import '../../assets/scss/hidenav.scss'
import {CREATE_PROSPECT, UPDATE_PROSPECT} from 'constants/queries'
import { StoreContext } from 'App'

// const SEND_CALC_REQUEST = gql`
// mutation CalculationEmail($name: String!, $email: String!, $phone: String!)  {
//     sendCalculationEmail(email: $email, phone: $phone, name: $name)
// }
// `


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    overflow: 'visible',
  },
  content: {
    padding: 0
  },
}));

const Policies = [
{ title: 'Esencial',
  tagline: 'Confianza',
  subtitle: 'Precio Mensual',
  info: 'Más Información',
  fn: cost => Math.round(calculateYearlyPolicyCost('essential', cost)/12),
  first: true,
  points: [
    'Asistencía Legal',
    'Investigación',
    'Contrato de Arrendamiento',
    'Intermediacion Extrajudicia',
  ]
},

{ title: 'Básica',
  tagline: 'Seguridad',
  subtitle: 'Precio Mensual',
  info: 'Más Información',
  fn: cost => Math.round(calculateYearlyPolicyCost('basic', cost)/12),
  points: [
    'Protección contra la ley de extinción de dominio',
    'Herramienta Administrativa',
    'Convenio de terminación',
    'Juicio de desalojo',
  ]
},

{ title: 'Amplia',
  tagline: 'Certeza',
  subtitle: 'Precio Mensual',
  info: 'Más Información',
  fn: cost => Math.round(calculateYearlyPolicyCost('ample', cost)/12),
  points: [
    'Ratificación ante Notario',
    'Acompañamiento a firma',
    'Periodo de gracia',
  ]
},

{ title: 'Premium',
  tagline: 'Tranquilidad',
  subtitle: 'Precio Mensual',
  info: 'Más Información',
  fn: cost => Math.round(calculateYearlyPolicyCost('premium', cost)/12),
  points: [
    'Investigación Judicial de fiador',
    'Protección contra daños materiales*',
    'Recuperación de rentas perdidas y servicios vencidos',
  ]
}, {
  title: 'Garantía Navio',
  tagline: 'Tranquilidad',
  subtitle: 'Precio Mensual',
  info: 'Más Información',
  fn: cost => Math.round(calculateYearlyPolicyCost('guarantee', cost)/12),
  points: [
    'Garantia Navío',
]}, {
  title: 'Garantía Jurídica',
  tagline: 'Tranquilidad',
  subtitle: 'Precio Anual',
  info: 'Más Información',
  fn: cost => Math.round(calculateYearlyPolicyCost('legal_guarantee', cost)),
  points: [
    'Te garantizamos la renta',
  ]

}]

const Calculator = props => {
  const [displaySpinner, setDSpinner] = useState(false)
  const [rent, setRent] = useState(0)
  const [loadedRent, loadRent] = useState(false)
  const [loadedZip, loadZip] = useState(false)
  const [prospectId, setProspectId] = useState(false)
  const [registeredEmail, setRegisteredEmail] = useState(false)
  const [sent, setSent] = useState(false)
  const context = useContext(StoreContext)

  const { t } = useTranslation()
  const { register, errors, handleSubmit, watch } = useForm()
  const [mutation, status] = useMutation(!prospectId ? CREATE_PROSPECT : UPDATE_PROSPECT, {
    onError: () => {console.log('Registration incomplete')}, 
    onCompleted: e => { setProspectId(e.prospect.create.id) }
  })

  const classes = useStyles()
  const watchAll = watch();

  const onSubmit = (data) => {
    if(prospectId) 
    mutation({variables: {id: prospectId, input: {...data}}})
    else {
        if(sent) return
          mutation({variables: { input: {...data}}})
    }
    setSent(true)
  }

  const Prices = Policies.map(p => <CalculatorCard {...p} cost={p.fn(rent)}/>)
  console.log(loadedRent, loadedZip)
  const stepTwoActive = !!loadedRent && !!loadedZip
  const stepThreeActive = !!rent;
  const contactButton = watchAll.name && watchAll.email && watchAll.phone;

  const calculate = () => {
      // if(!stepThreeActive) return

      setTimeout(() => {
        setDSpinner(false)
          onSubmit({
            rent: parseInt(loadedRent)
          })
      }, 1000)
      setRent(loadedRent)
      setDSpinner(true)
  }

  const registerEmail = () => {
      if(registeredEmail == watchAll.email) return 
      setTimeout(() => {
        setRegisteredEmail(watchAll.email)
        context.state.showAlert({
          severity: 'success',
          message: 'Gracias, te contactaremos pronto!'
        })
        setDSpinner(false)
        onSubmit({
          name: watchAll.name,
          phone: watchAll.phone,
          email: watchAll.email
        })
      }, 1000)
  }

  // useEffect(() => {
  // }, [rent])

  useEffect(() => {
    const stepOne = document.querySelector('.card__step:nth-of-type(1)')
    const stepTwo = document.querySelector('.card__step:nth-of-type(3)')
    const stepThree = document.querySelector('.card__step:nth-of-type(4)')
    const stOneHeight = stepOne.offsetHeight;
    const stTwoHeight = stepTwo.offsetHeight;
    const stThreeHeight = stepThree.offsetHeight;
    stepOne.style.setProperty('--height', (stOneHeight + stTwoHeight / 2 ) * 1.2 + 'px')
    // if(stepThreeActive)
    //   stepTwo.style.setProperty('--height', (stTwoHeight / 2 + stThreeHeight / 2 ) + 'px')
    // else
      stepTwo.style.setProperty('--height', (stTwoHeight / 2 + stThreeHeight / 2 ) + 'px')
  })

  return (
  <div className={classes.root} style={{paddingLeft: '4em'}}>

    <Card className={clsx('card__step', !stepTwoActive && 'disabled')}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid md={6} xs={12} item>
              <TextField 
                fullWidth
                variant='outlined'
                type='number'
                name='rent'
                label='Renta Mensual de inmueble'
                value={loadedRent}
                onChange={(e) => loadRent(e.target.value)}
              />
          </Grid>

          <Grid md={6} xs={12} item>
            <TextField
              variant='outlined'
              fullWidth
              label="Código Postal"
              name="zip_code"
              type="text"
              inputRef={register({required: true})}
              onChange={e => loadZip(e.target.value)}
              error={errors.zip_code}
              helperText={errors.zip_code && t(errors.zip_code.type)}
            />
          </Grid>
          
        </Grid>
      </CardContent>
    </Card>

    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
      <Button
        disabled={!(loadedRent && loadedRent > 0) || !loadedZip}
        color="primary"
        variant="contained"
        type="submit"
        onClick={calculate}>
        Cotizar
      </Button>
    </div>

    <Card className={clsx('card__step', !stepThreeActive && 'disabled')}>
        {stepTwoActive && stepThreeActive && (
           <CardContent style={{display: 'flex', alignItems: 'center'}}>
               { displaySpinner ? (
                <div className='progress--container'>
                  <CircularProgress/>
                </div>
               ) : (
                <div item xs={12} style={{display: 'flex', flexWrap: 'wrap', width: '100%' }} classList='price-container'>
                  {stepThreeActive && Prices}
                </div>
               )}
          </CardContent>   
        )}
    </Card>

        <Card className={clsx('card__step', !stepThreeActive && 'disabled')}>
            { (stepThreeActive && !displaySpinner) && (
              <CardContent>
                  <Grid container spacing={4}>
                    <Grid xs={12} item>
                      <Typography variant='h6'>
                          Para brindarte más información y la atención personalizada que te mereces, por favor compártenos tus datos de contacto:
                      </Typography>
                    </Grid>
                    <Grid md={4} xs={12} item>
                      <TextField
                        variant='outlined'
                        fullWidth
                        label="Nombre"
                        name="name"
                        type="text"
                        inputRef={register({required: true})}
                        error={errors.name}
                        helperText={errors.name && t(errors.name.type)}
                      />
                    </Grid>


                    <Grid md={4} xs={12} item>
                      <TextField
                        variant='outlined'
                        fullWidth
                        label="Correo electrónico"
                        name="email"
                        type="text"
                        inputRef={register({required: true, pattern: Helpers.emailRegex})}
                        error={errors.email}
                        helperText={errors.email && t(errors.email.type)}
                      />
                    </Grid>

                    <Grid md={4} xs={12} item>
                      <TextField
                        variant='outlined'
                        fullWidth
                        label="Teléfono"
                        name="phone"
                        type="number"
                        inputRef={register({required: true, pattern: Helpers.phoneRegex})}
                        error={errors.phone}
                        helperText={errors.phone && t(errors.phone.type)}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <Button
                        disabled={!contactButton || (registeredEmail == watchAll.email)}
                        color="primary"
                        variant="contained"
                        type="submit"
                        onClick={ () => registerEmail() }>
                        Enviar
                      </Button>
                    </Grid>
                  </Grid>
              </CardContent>
            )}
        </Card>
    <div>
      <a href="https://navio.mx/coberturas" target="_blank">
        <Button style={{marginRight: '1em'}} variant='contained' color='primary'>Coberturas</Button>
      </a>
    </div>
  </div>
  )

};

export default Calculator;

import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Divider, Grid, Card, CardHeader, CardContent, Button, CardActions } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'


const useStyles = makeStyles(theme => ({
  root: {
    padding: '4em',
  },
  content: {
    padding: 0
  },
  heading: {
    textTransform: 'uppercase'
  },
  highlight: {
    color: theme.palette.navio.red
  },
  swoosh: {
    backgroundImage: 'url(/images/navio-swoosh.png)'
  },
  paragraph: {
    margin: '1em 0'
  },
  leftCol: {
    paddingRight: '3em !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }
}));

const Items = [
  'El pago de las rentas correspondientes a los meses de _____________. por un importe mensual de $___________________.',
  'El pago del servicio telefónico por un importe total de $____________.',
  'El pago del agua por un importe total de $_____________.',
  'El pago de consumo de luz por un importe total de $_____________.',
  'El pago de consumo de gas por un importe total de $_____________.',
  'El pago de la cuota de mantenimiento correspondiente al mes de ________, mismo que venció el día ____ de dicho mes, por un importe total de $_________.',
  'La entrega legal del inmueble, el cual fue: Abandonado, pero no desocupado ó Abandonado y desocupado ó Nunca ocupado y de cuya situación me enteré en fecha ______________.',
  'La desocupación y entrega legal del inmueble por haberse vencido el contrato de arrendamiento en fecha _______________.',
  'El pago de los intereses moratorios aplicados exclusivamente al pago impuntual de las rentas, hasta por la cantidad de: $_____________.'
]

const ClaimLetter = props => {
  const classes = useStyles()

  const { t } = useTranslation()

  const Item =({children}) => (
  <div style={{display: 'flex', alignItems: 'center', margin: '1em 0'}}>
      <img src={process.env.PUBLIC_URL + '/images/navio-square.png'} style={{width: '3em'}}/>
      <Typography variant='subtitle1'>{children}</Typography>
    </div>
  )

  return (
    <div className={clsx(classes.root, 'document')}>
      <Grid container spacing={3}>
        <Grid item xs={3} className={classes.leftCol}>
          <div>
            <img src={process.env.PUBLIC_URL + '/images/navio-logo.png'} style={{width: '12em'}}/>
          </div>
          <div >
              <Typography>
                info@Navío.mx
              </Typography>
              <Typography style={{fontWeight: 'bold'}}>
                www.Navío.mx
              </Typography>
            </div>
        </Grid>

        <Grid item xs={9}>
          <div style={{display: 'flex', alignItems: 'center', margin: '6em 0 1em 0'}}>
            <Typography variant='h1' style={{fontSize: "16px"}}>CARTA RECLAMACIÓN POR INCUMPLIMIENTO DEL ARRENDATARIO.</Typography>
          </div>
            <Typography>Fecha: </Typography>
            <Typography>Cliente: </Typography>
            <Typography>No. de Póliza: </Typography>
            <Typography className={classes.paragraph} style={{margin: '2em 0 1em 0'}}>
                Solicito la intervención jurídica de <span style={{textTransform: 'uppercase', fontWeight: 'bold'}}>Navío</span> conforme a los términos y condiciones de la póliza
                de referencia, toda vez que mi Inquilino ha incumplido hasta esta fecha con las obligaciones mencionadas a continuación, asumidas en el contrato de arrendamiento.
            </Typography>
            <div style={{display: 'flex', alignItems: 'center', margin: '1em 0 1em 0'}}>
            <Typography variant='h1' style={{fontSize: "16px"}}>*SELECCIONAR OPCIONES APLICABLES AL CASO:</Typography>
          </div>
              {
                Items.map(e => <Item>{e}</Item>)
              }
            <div style={{display: 'flex', alignItems: 'center', margin: '3em 0 1em 0'}}>
                <Typography variant='h1' style={{fontSize: "16px"}}>DATOS DEL ARRENDATARIO.</Typography>
            </div>
            <Typography>Nombre:_______________________________________________ </Typography>
            <Typography>Teléfono:______________________________________________ </Typography>
            <Typography>Información Adicional:_________________________________</Typography>
        </Grid>
      </Grid>
    </div>
  )
};

export default ClaimLetter;

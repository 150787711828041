import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { TenantForm } from './components';
import { useQuery } from '@apollo/react-hooks';
import { FETCH_TENANTS } from 'constants/queries';
import DefineAbilityFor from 'constants/abilities'  
import { StoreContext } from 'App'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Tenant = props => {
  const context = useContext(StoreContext)
  const ability = DefineAbilityFor(context.state.session)
  const history = useHistory()
  const { t } = useTranslation()
  const { tenantId } = useParams();
  const { className, requirement, ...rest } = props;
  const { data } = useQuery(FETCH_TENANTS, {
    variables: { query: { id: tenantId } }
  });
  const classes = useStyles();

  if (data)
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TenantForm 
              fieldsEnabled={ability.can('create', 'tenants')}
              onCompleted={ () => {
                context.state.showAlert({
                  severity: 'success',
                  message: `${t('tenant')} ${t('created')}`
                });
                setTimeout(() => history.push('/tenants'), 1000)
              }}
            />
          </Grid>
          <Grid item lg={4} md={6} xl={4} xs={12} />
        </Grid>
      </div>
    );
  return <div></div>;
};

export default Tenant;


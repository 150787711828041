import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { RequirementsToolbar, RequirementsTable } from './components';
import { useQuery } from '@apollo/react-hooks';
import { FETCH_REQUIREMENTS } from 'constants/queries';
import mockData from './data';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const RequirementList = () => {
  const classes = useStyles();
  const { data } = useQuery(FETCH_REQUIREMENTS);
  const filter = useState('');
  if (data) {
    const requirements = data.requirements.filter(requirement =>
      requirement.name.match(new RegExp(filter[0], 'i'))
    );

    return (
      <div className={classes.root}>
        <RequirementsToolbar filter={filter} />
        <div className={classes.content}>
          <RequirementsTable requirements={requirements} />
        </div>
      </div>
    );
  }
  return <div></div>;
};

export default RequirementList;

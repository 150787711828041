import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { parseCurrency } from 'lib/helpers'


const useStyles = makeStyles(() => ({
  content: {
    padding: 0
  },
  inner: {},
  row: {
    cursor: 'pointer'
  }

}));
const UserProperties = props => {
  const { properties, ...rest } = props;
  const classes = useStyles();
  const history = useHistory();

  const { t, i18n } = useTranslation();


  return (
    <Card>
      <CardHeader title={t("user_properties")} />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("policyType")}</TableCell>
                  <TableCell>{t("monthlyRent")}</TableCell>
                  <TableCell>{t("propertyUse")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {properties.map(property => (
                  <TableRow
                    hover
                    key={property.id}
                    className={classes.row}
                    onClick={() => history.push('/properties/view/' + property.id)}>
                    <TableCell>{t(property.lastPolicy.policyType)}</TableCell>
                    <TableCell>
                      {parseCurrency(property.lastContract.monthlyRent)}
                    </TableCell>
                    <TableCell>
                      {t(property.propertyUse)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};
export default UserProperties;

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { TenantsToolbar, TenantsTable } from './components';
import { useQuery } from '@apollo/react-hooks';
import { FETCH_TENANTS } from 'constants/queries';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const TenantList = () => {
  const classes = useStyles();
  const { data } = useQuery(FETCH_TENANTS, { fetchPolicy: 'network-only' });
  const filter = useState('');
  const [ filterScope, setFilterScope ] = useState('fullName')
  if (data) {
    const tenants = data.tenants.filter(tenant =>
      (tenant[filterScope]).match(new RegExp(filter[0], 'i'))
    );

    return (
      <div className={classes.root}>
        <TenantsToolbar filter={filter} filterScope={filterScope}/>
        <div className={classes.content}>
          <TenantsTable tenants={tenants} setFilterScope={setFilterScope} filterScope={filterScope}/>
        </div>
      </div>
    );
  }
  return <div></div>;
};

export default TenantList;

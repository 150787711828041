import React, { useEffect, useContext, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Button,
  Typography,
  Divider,
  Paper
} from '@material-ui/core';

import { SUBSCRIPTION_CHAT_UPDATED } from 'constants/queries';
import { StoreContext } from 'App'
import Helpers from 'lib/helpers'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '1em',
    height: '100%',
    maxHeight: 'calc(100vh - 64px - 2em - 12em)',
    overflowY: 'scroll'
    
  },
  message: imAuthor =>  ({
    background: imAuthor ? theme.palette.primary.main : theme.palette.divider,
    padding: '1em',
    marginBottom: '1em',
    borderRadius: '5%',
    color: imAuthor ? theme.palette.primary.contrastText : theme.palette.black
  }),
  container: imAuthor => ({
    display: 'flex',
    flexDirection: imAuthor ? 'row-reverse' : 'inherit'
  }),
}))

const handleNewMessages = ( roomId, updateLastSeen ) => ({
  document: SUBSCRIPTION_CHAT_UPDATED,
  variables: { roomId },
  updateQuery(prev, { subscriptionData }) {
    if (!subscriptionData.data) return prev;
    updateLastSeen({variables: {id: roomId}})
    const updatedRoom = {...prev.chatRooms[0]}
    updatedRoom.chatMessages = [...prev.chatRooms[0].chatMessages, subscriptionData.data.messageAddedToRoom]
    return {
      chatRooms: [updatedRoom]
    };
  }
});

const Message = ({imAuthor, message}) => {
  const classes = useStyles(imAuthor);
  return <div className={classes.container}>
    <Typography className={classes.message} key={message.id} variant="h6">
      {message.body}
    </Typography>
  </div>
}
const MessageList = ({ room, loading, subscribeToMore, updateLastSeen }) => {
  const chatBottom = useRef(null)
  const container = useRef(null)
  const sortedMessages = room.chatMessages.sort((a, b) => (new Date(a.createdAt)) - (new Date(b.createdAt)))

  useEffect(() => {
    return subscribeToMore(handleNewMessages(room.id, updateLastSeen))
  }, [room.id]);

  useEffect(() => {
    // chatBottom.current.scrollIntoView();
    // container.scrollTop = container.scrollHeight
  }, [room.chatMessages] )
  const context = useContext(StoreContext)
  const classes = useStyles()
  return (
    <Paper className={classes.paper} ref={container}>
        {sortedMessages.map(m => {
          const imAuthor = m.user.id == context.state.session.id;
          return ( <Message key={m.id} message={m} imAuthor={imAuthor} />)
        })}
        <div style={{float: 'left', clear: 'both'}} ref={chatBottom}/>
    </Paper>
  );
};

export default MessageList;

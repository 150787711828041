import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/styles';
import { 
  Divider,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Button,
  CardActions,
  Typography
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import {
  UserProfile,
  UserDetails,
  UserPassword,
  UserProperties,
  UserProducts,
  ClientDetail,
} from 'components';
import { TenantsTable } from '../TenantList'
import { useQuery } from '@apollo/react-hooks';
import { FETCH_INVOICES } from 'constants/queries';
import { Can } from 'lib/can'
import { subject } from '@casl/ability';

import { parseCurrency } from 'lib/helpers'
import { StoreContext } from 'App'
import DefineAbilityFor from 'constants/abilities';
import { InvoiceForm } from 'views'
import { Loader } from 'components'
import { PaymentCard, PaymentForm } from './components'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    padding: 0
  }
}));


const PastPayments = (props) => {

  return (
    <React.Fragment>
      {props.payments.map(p => (
        <PaymentCard key={p.id} invoice={props.invoice} payment={p}/>
      ))}
    </React.Fragment>
  )
}

const InvoiceView = props => {
  const { t } = useTranslation()
  const { invoiceId } = useParams();
  const { data } = useQuery(FETCH_INVOICES, {
    variables: { query: { id: invoiceId } }
  });

  const classes = useStyles();
  const context = useContext(StoreContext)
  const ability = DefineAbilityFor(context.state.session)
  const history = useHistory()

  if(data && data.invoices[0]) {
    const invoice = data.invoices[0]
    const payments = invoice.payments
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Can I='create' this='payment'>
              <Typography variant='h3'>
                  {t('new_transaction')}:
              </Typography>
              <PaymentForm invoice={data.invoices[0]}/>
            </Can>
            <Typography variant='h3'>
                {t('past_transactions')}:
              <span style={{float: 'right'}}>{t('totalPayed')}: ${parseCurrency(payments.reduce((sum, p) => sum += p.qty, 0).toFixed(2))}</span>
            </Typography>
            <PastPayments invoice={invoice} payments={invoice.payments.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt))}/>
          </Grid>

          <Can I='create' this='invoice'>
            <Grid item xs={6}>
              <InvoiceForm invoice={invoice} onCompleted={
                  () => context.state.showAlert({
                    severity: 'success',
                    message: `${t('invoice')} ${t('updated')}`
                  })
              }/>
            </Grid>
          </Can>
        </Grid>
      </div>
    )
  }
  return <Loader/>

  
};

export default InvoiceView;

import React, {useContext} from 'react';
import { useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/styles';
import { Typography, Divider, Grid, Card, CardHeader, CardContent, Button, CardActions } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import Pdf from 'react-to-pdf'

import { FETCH_USER_FULL } from 'constants/queries'
import { WelcomeLetter } from 'components'
import { ClaimLetter,
 TermsAndConditions,
 TermsDocument,
 WelcomeLetterBasic,
 WelcomeLetterEssential,
 WelcomeLetterPremium,
 WelcomeLetterWide
} from 'components/Documents';
import { PolicyList } from 'views'


import gql from 'graphql-tag';
import * as Fragments from 'constants/fragments.js';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    padding: 0
  }
}));

const ref = React.createRef();
const options = {
  orientation: 'p',
  unit: 'in',
  format: [16.54, 23.39]
}

const Proto = props => {
  return <PolicyList/>
};

export default Proto;

import * as SessionServices from './Authentication';
import { IT_Session } from './types';
const SessionHandler = {
  fetchCurrentUser: async () => {
    const storedToken = SessionServices.getAuthToken();
    if (storedToken) {
      console.log('stored token:', storedToken);
      const res = await SessionServices.fetchCurrentUser();
      if (res.data.currentUser)
        return { authToken: storedToken, ...res.data.currentUser };
      else return null;
    }
    return null;
  },
  hasToken: () => {
    const storedToken = SessionServices.getAuthToken()
    if (storedToken == 'null') return false
    return !!storedToken
  }

};

export default SessionHandler;


import React, {useState} from 'react'
import moment from 'moment'
// import numberToSpanis from 'number-to-spanish-words'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { PolicyDocx } from './PolicyDocx'

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Paper,
  Typography
} from '@material-ui/core';
import * as Fragments from 'constants/fragments.js';
import { makeStyles } from '@material-ui/styles';
import { declarations, intro, clauses } from './PolicyDocx'
import { parseAddress, parseCurrency, Invoice } from 'lib/helpers'
import gql from 'graphql-tag'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    padding: 0
  }
}));

const numberToSpanis = () => ''
const deposit_type_to_number = (amount, type) => {
    switch(type) {
        case 'one_month':
            return amount * 1;
        case 'two_month':
            return amount * 2;
        case 'three_month':
            return amount * 3;
        default:
            throw {message: 'deposit_type_to_number: invalid type'}
    }
}


const deposit_type_t = (type) => {
    switch(type) {
        case 'one_month':
            return 'Un Mes'
        case 'two_month':
            return 'Dos Meses'
        case 'three_month':
            return 'Tres Meses'
        default:
            throw {message: 'deposit_type_to_number: invalid type'}
    }
}

const DocumentEditor = ({policy}) => {
      const classes = useStyles();
    const { t } = useTranslation();

    const info = {
        arrendador: {key: 'arrendador', type: 'text', value: policy.user.fullName},
        arrendatario: {key: 'arrendatario', type: 'text', value: policy.property.tenant?.canonicName},
        direccionInmueble: {key: 'direccionInmueble', type: 'text', value: parseAddress(policy.property.address, t)},
        ubicacionNotificaciones: {key: 'ubicacionNotificaciones', type: 'text', value: ''},
        inicioFecha: {key: 'inicioFecha', type: 'text', value: moment(policy.contract.startDate).format('DD/MM/YYYY')},
        finFecha: {key: 'finFecha', type: 'text', value: moment(policy.contract.endDate).format('DD/MM/YYYY')},
        numeroCuentaDeposito: {key: 'numeroCuentaDeposito', type: 'text', value: policy.contract.clabe},
        bancoDeposito: {key: 'bancoDeposito', type: 'text', value: policy.contract.bank},
        nombreCuentaDeposito: {key: 'nombreCuentaDeposito', type: 'text', value: parseCurrency(policy.contract.monthlyRent)},
        cantidadDeposito: {key: 'cantidadDeposito', type: 'text', value: parseCurrency(deposit_type_to_number(policy.contract.monthlyRent, policy.contract.deposit))},
        cantidadDepositoText: {key: 'cantidadDepositoText', type: 'text', value: ''},
        depositoInicial: {key: 'depositoInicial', type: 'text', value: deposit_type_t(policy.contract.deposit)},
        depositoInicialText: {key: 'depositoInicialText', type: 'text', value: numberToSpanis(policy.contract.deposit)},
        currentDay: {key: 'currentDay', type: 'text', value: moment().format('DD/MM/YYYY')}
    }

    const values = Object.keys(info).reduce((acc, k) => ({...acc, [k]: info[k].value}), {})
    debugger
    const {register, watch} = useForm({defaultValues: values})

    const [clauseOptions, setOptions] = useState(clauses(values))
    const [hiddenClauses, setHiddenClauses] = useState([])

    const addHiddenClause = (idx) => setHiddenClauses([...hiddenClauses, idx])
    const removeHiddenClause = (idx) => setHiddenClauses(hiddenClauses.filter(i => i != idx))

    const data = watch()

    return (

        <div className={classes.root}>
            <PolicyDocx {...data} hiddenClauses={hiddenClauses}/>
            <Card style={{padding: '1em'}}>
                <Grid container spacing={4}>
                    {Object.keys(info).map(k => (
                        <Grid item xs={4}>
                              <TextField
                                fullWidth
                                required
                                name={info[k].key}
                                type={info[k].type}
                                label={t(info[k].key)}
                                defaultValue={ info[k].value }
                                margin="dense"
                                variant="outlined"
                                inputRef={register({ required: false })}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                        </Grid>

                    ))}
                </Grid>

            </Card>
        {clauseOptions.map((c, idx) => {
            const checked = !hiddenClauses.includes(idx)
            return (
                <Card style={{margin: '1em', padding: '1em'}}>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '1em'}}>
                    <input 
                        type="checkbox" 
                        value={checked} 
                        checked={checked}
                        onChange={() => !checked ? removeHiddenClause(idx) : addHiddenClause(idx) }/>
                    <h4 style={{marginLeft: '0.5em'}}>{c.heading}</h4>
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    {c.paragraphs.map(p => {
                        if( p.hasOwnProperty('custom')) {
                            let customs = p.custom().map((p, idx) => <div key={idx}>{p}</div>)
                            return <div style={{margin: '1em'}}>{customs}</div>
                        }
                        else 
                            return <div>{p}</div>
                    })}
                </div>
                </Card>
            )
        })}
        </div>
    )
}

const FETCH_DATA = gql`
query fetchPolicyWithContract($query: PolicyQuery) {
    policies(query: $query) {
        id
        user {
            id
            fullName
        }
        property {
            id
            tenant {
                ...tenantFields
            }
            address {
                ...addressFields
            }
        }
        contract {
            id
            monthlyRent
            startDate
            endDate
            bank
            accountOwner
            deposit
            clabe
            tax
        }

    }
}
    ${Fragments.AddressFragments.fields}
    ${Fragments.TenantFragments.fields}
`

export const PolicyDocumentEditor = () => {
    const {id} = useParams()
    const { data, loading, error } = useQuery(FETCH_DATA, {
        variables: { query: {id}}
    })
    if(loading) return <div></div>
    if(error) return <div>error</div>
    return <DocumentEditor policy={data.policies[0]}/>


}

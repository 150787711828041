import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  TableSortLabel
} from '@material-ui/core';

import { getInitials } from 'helpers';
import { DELETE_INVOICE, FETCH_INVOICES } from 'constants/queries';
import Helpers from 'lib/helpers';
import { StoreContext } from 'App';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  filterScope: {
    borderTop: `1px solid ${theme.palette.primary.main}`
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const InvoicesTable = props => {
  const { className, invoices, setFilterScope, filterScope, ...rest } = props;
  const { t, i18n } = useTranslation();

  const headCells = [
    {
      id: 'firstName',
      numeric: false,
      label: t('invoice_name'),
      numeric: false,
      disablePadding: true,
      searchable: true
    },
    {
      id: 'email',
      numeric: false,
      label: t('invoice_email'),
      numeric: false,
      disablePadding: false,
      searchable: true
    },
    {
      id: 'phone',
      numeric: false,
      label: t('invoice_phone'),
      numeric: false,
      disablePadding: false,
      searchable: false
    },
    {
      id: 'credentials',
      numeric: false,
      label: t('invoice_credentials'),
      numeric: false,
      disablePadding: false,
      searchable: true
    },
    {
      id: 'actions',
      numeric: false,
      label: t('invoice_actions'),
      numeric: false,
      disablePadding: false,
      searchable: false
    }
  ];

  const classes = useStyles();
  const history = useHistory();
  const context = useContext(StoreContext);
  const handleDelSuccess = () =>
    context.state.showAlert({
      severity: 'warning',
      message: 'Usuario Eliminado'
    });
  const [deleteInvoice] = useMutation(DELETE_INVOICE, {
    onCompleted: handleDelSuccess,
    update: (cache, { data }) => {
      const { invoices } = cache.readQuery({ query: FETCH_INVOICES });
      cache.writeQuery({
        query: FETCH_INVOICES,
        data: { invoices: invoices.filter(u => u.id != data.invoice.destroy) }
      });
    }
  });

  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(headCells[0].id);

  const handleSelectAll = event => {
    let selectedInvoices;

    const { invoices } = props;

    if (event.target.checked) {
      selectedInvoices = invoices.map(invoice => invoice.id);
    } else {
      selectedInvoices = [];
    }

    setSelectedInvoices(selectedInvoices);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedInvoices.indexOf(id);
    let newSelectedInvoices = [];

    if (selectedIndex === -1) {
      newSelectedInvoices = newSelectedInvoices.concat(selectedInvoices, id);
    } else if (selectedIndex === 0) {
      newSelectedInvoices = newSelectedInvoices.concat(selectedInvoices.slice(1));
    } else if (selectedIndex === selectedInvoices.length - 1) {
      newSelectedInvoices = newSelectedInvoices.concat(selectedInvoices.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedInvoices = newSelectedInvoices.concat(
        selectedInvoices.slice(0, selectedIndex),
        selectedInvoices.slice(selectedIndex + 1)
      );
    }

    setSelectedInvoices(newSelectedInvoices);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedInvoices.length === invoices.length}
                      color="primary"
                      indeterminate={
                        selectedInvoices.length > 0 &&
                        selectedInvoices.length < invoices.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  {headCells.map(headCell => (
                    <TableCell
                      className={filterScope == headCell.id ? classes.filterScope : null}
                      onClick={() => filterScope && (headCell.searchable && setFilterScope(headCell.id))}
                      key={headCell.id}
                      align={headCell.numeric ? 'right' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy === headCell.id ? order : false}>
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}>
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(invoices, getComparator(order, orderBy))
                  .slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage)
                  .map(invoice => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={invoice.id}
                      selected={selectedInvoices.indexOf(invoice.id) !== -1}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedInvoices.indexOf(invoice.id) !== -1}
                          color="primary"
                          onChange={event => handleSelectOne(event, invoice.id)}
                          value="true"
                        />
                      </TableCell>
                      <TableCell>
                        <div className={classes.nameContainer}>
                          <Avatar
                            className={classes.avatar}
                            src={invoice.avatar || '/images/avatars/avatar_4.png'}>
                            {getInitials(invoice.name)}
                          </Avatar>
                          <Typography variant="body1">
                            {invoice.firstName + ' ' + invoice.lastName}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell>{invoice.email}</TableCell>
                      <TableCell>{invoice.phone}</TableCell>
                      <TableCell>
                        {Helpers.capitalize(t(invoice.credentials))}
                      </TableCell>
                      <TableCell>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() =>
                            history.push('/invoices/view/' + invoice.id)
                          }>
                          {t('action_see')}
                        </Button>
                        <span> </span>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() =>
                            deleteInvoice({ variables: { id: invoice.id } })
                          }>
                          {t('action_delete')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={invoices.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage={t('rows_per_page')}
          labelDisplayedRows={({ from, to, count }) =>
            t('from_to_count', { from, to, count })
          }
        />
      </CardActions>
    </Card>
  );
};

InvoicesTable.propTypes = {
  className: PropTypes.string,
  invoices: PropTypes.array.isRequired
};

export default InvoicesTable;

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment'

import { ProspectsToolbar, ProspectsTable } from './components';
import { useQuery } from '@apollo/react-hooks';
import { FETCH_PROSPECTS } from 'constants/queries';
import { sortByDateFn } from 'lib/helpers'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const headCells = [
  {
    id: 'name',
    numeric: false,
    label: 'Nombre',
    numeric: false,
    searchable: true,
    disablePadding: true,
    function: p => p.name
  },{
    id: 'email',
    numeric: false,
    label: 'Email',
    numeric: false,
    searchable: true,
    disablePadding: true,
    function: p => p.email
  },{
    id: 'phone',
    numeric: false,
    label: 'Telefono',
    numeric: false,
    searchable: true,
    disablePadding: true,
    function: p => p.phone
  },{
    id: 'rent',
    numeric: false,
    label: 'Renta Estimada',
    numeric: false,
    searchable: true,
    disablePadding: true,
    function: p => p.rent
  }, {
    id: 'created_at',
    numeric: false,
    label: 'Creado',
    numeric: false,
    searchable: true,
    disablePadding: true,
    function: p => moment(p.createdAt).format('DD-MM-YYYY')
  },
];

const ProspectList = () => {
  const classes = useStyles();
  const { data } = useQuery(FETCH_PROSPECTS, { fetchPolicy: 'network-only' });
  const filter = useState('');
  const [dirtyFilter, setDirtyFilter ] = useState(false)
  const [ filterScope, setFilterScope ] = useState(headCells[headCells.length - 1])
  if (data) {
    let prospects = data.prospects.filter(prospect =>
      filterScope.function 
        ? filterScope.function(prospect).match(new RegExp(filter[0], 'i'))
        : prospect[filterScope].match(new RegExp(filter[0], 'i'))
    );

    prospects = prospects.sort(sortByDateFn)


    return (
      <div className={classes.root}>
        <ProspectsToolbar filter={filter} filterScope={filterScope}/>
        <div className={classes.content}>
          <ProspectsTable
            headCells={headCells}
            prospects={prospects}
            setFilterScope={setFilterScope}
            filterScope={filterScope}
            dirtyFilter={dirtyFilter}
            setDirtyFilter={setDirtyFilter}
          />
        </div>
      </div>
    );
  }
  return <div></div>;
};

export default ProspectList;


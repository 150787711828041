const MexicoStates = [{ label: 'Aguascalientes', value: 'aguascalientes'},
{ label: 'Baja California', value: 'baja_california'},
{ label: 'Baja California Sur', value: 'baja_california_sur'},
{ label: 'Campeche', value: 'campeche'},
{ label: 'Chiapas', value: 'chiapas'},
{ label: 'Chihuahua', value: 'chihuahua'},
{ label: 'Ciudad de México', value: 'cuidad_de_mexico'},
{ label: 'Coahuila', value: 'coahuila'},
{ label: 'Colima', value: 'colima'},
{ label: 'Durango', value: 'durango'},
{ label: 'Guanajuato', value: 'guanajuato'},
{ label: 'Guerrero', value: 'guerrero'},
{ label: 'Hidalgo', value: 'hidalgo'},
{ label: 'Jalisco', value: 'jalisco'},
{ label: 'México', value: 'méxico'},
{ label: 'Michoacán', value: 'michoacán'},
{ label: 'Morelos', value: 'morelos'},
{ label: 'Nayarit', value: 'nayarit'},
{ label: 'Nuevo León', value: 'nuevo_leon'},
{ label: 'Oaxaca', value: 'oaxaca'},
{ label: 'Puebla', value: 'puebla'},
{ label: 'Querétaro', value: 'querétaro'},
{ label: 'Quintana Roo', value: 'quintana_roo'},
{ label: 'San Luis Potosí', value: 'san_luis_potosi'},
{ label: 'Sinaloa', value: 'sinaloa'},
{ label: 'Sonora', value: 'sonora'},
{ label: 'Tabasco', value: 'tabasco'},
{ label: 'Tamaulipas', value: 'tamaulipas'},
{ label: 'Tlaxcala', value: 'tlaxcala'},
{ label: 'Veracruz', value: 'veracruz'},
{ label: 'Yucatán', value: 'yucatán'},
{ label: 'Zacatecas', value: 'zacatecas'}]

export default MexicoStates;

import React, { useState } from 'react'
import { DropzoneArea, DropzoneDialog } from 'material-ui-dropzone';
import {Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next'

import { FileCard } from 'components';

const useStyles = makeStyles(theme => ({
  dropZoneButton: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      bockgroundColor: theme.palette.success.contrastText
    }
  },
}))



const FileBox = (props) => {
  const { fileLink, onSave, label, filesLimit = 1, fileEditable = true, fileDeletable = true } = props
  const { t } = useTranslation()
  const [isOpen, setOpen] = useState(false)
  const classes = useStyles()

  return (
    <React.Fragment>
      <DropzoneDialog
        filesLimit={filesLimit}
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={5000000}
        open={isOpen}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          onSave(filesLimit > 1 ? files : files[0])
          setOpen(false);
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
      />
      <div style={{marginBottom: '2em'}}></div>
      <FileCard
        title={label}
        fileLink={fileLink}
        fileEditable={fileEditable}
        fileDeletable={fileDeletable}
        onClickEdit={() => setOpen(true)}
      />
    </React.Fragment>
  )
}

export default FileBox

import React from 'react'
import { Document,
Packer,
Paragraph,
HeadingLevel,
convertInchesToTwip,
UnderlineType,
LevelFormat,
Table,
TableRow,
TableCell,
    WidthType,
AlignmentType } from 'docx'
import { saveAs } from 'file-saver'
import { Button } from '@material-ui/core'


class DocumentCreator {
    create({clauses, declarations, intro, last}) {
    const payload = {
        numbering: {
            config: [
                {
                    reference: 'letters',
                    levels: [{
                       level: 0,
                        format: LevelFormat.LOWER_LETTER,
                        text: '%1.',
                        alignment: AlignmentType.START,
                        style: {
                            paragraph: {
                                indent: { left: convertInchesToTwip(1), hanging: convertInchesToTwip(0.18) }, spacing: {
                                    after: convertInchesToTwip(2)
                                }
                            },
                        }
                    }]
                },{
                    reference: 'clause',
                    levels: [
                        {
                            level: 0,
                            format: LevelFormat.DECIMAL,
                            text: '%1.',
                            alignment: AlignmentType.START,
                            style: {
                                paragraph: {
                                    indent: {left: 10 }
                                }
                            }
                        }
                    ]
                },{
                    reference: 'declaration',
                    levels: [
                        {
                            level: 0,
                            format: LevelFormat.UPPER_ROMAN,
                            text: '%1.',
                            alignment: AlignmentType.START,
                        }
                    ]
                },{
                    reference: 'declaration-text-0',
                    levels: [
                        {
                            level: 1,
                            alignment: AlignmentType.LEFT,
                            format: LevelFormat.LOWER_LETTER,
                            text: '%1)',
                            style: {
                                paragraph: {
                                    indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.18) },
                                },
                            }
                        }
                    ]
                },{
                    reference: 'declaration-text-1',
                    levels: [
                        {
                            level: 1,
                            format: LevelFormat.LOWER_LETTER,
                            text: '%1)',
                            style: {
                                paragraph: {
                                    indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.18) },
                                },
                            }
                        }
                    ]
                },{
                    reference: 'declaration-text-2',
                    levels: [
                        {
                            level: 1,
                            format: LevelFormat.LOWER_LETTER,
                            text: '%1)',
                            style: {
                                paragraph: {
                                    indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.18) },
                                },
                            }
                        }
                    ]
                }
            ]

        },
            styles: {
                default: {
                    heading1: {
                        run: {
                            underline: UnderlineType.SINGLE,
                            font: "Times New Roman",
                            bold: true,
                            allCaps: true,
                        }
                    },
                    heading2: {
                        run: {
                            underline: UnderlineType.SINGLE,
                            font: "Times New Roman",
                            bold: true,
                        }
                    },
                    listParagraph: {
                        run: {
                            color: 'FF0000'
                        }
                    },
                    run: {
                        // indent: {
                        //     left: convertInchesToTwip(0.5),
                        // }
                    }
                },
                paragraphStyles: [
                    {
                        id: 'clause',
                        name: 'Clause',
                        bosedOn: 'Normal',
                        paragraph: {
                            indent: {
                                left: convertInchesToTwip(0.5),
                            },
                            spacing: {
                                after: 500,
                            }
                        }
                    },{
                        id: 'declaration',
                        name: 'Declaration',
                        basedOn: 'Normal',
                        paragraph: {
                            indent: {
                                left: 0
                            },
                        },
                        ListParagraph: {
                            run: {
                                color: 'FF00FF',
                            },
                            indent: {
                                left: 0
                            },
                        }
                    },{
                        id: 'signature',
                        name: 'Signature',
                        paragraph: {
                            alignment: AlignmentType.CENTER,
                            spacing: {
                                after: convertInchesToTwip(1)
                            }
                        }

                    },{
                        id: 'custom',
                        name: 'Custom',
                        basedOn: 'declaration',
                        paragraph: {
                            spacing: {after: convertInchesToTwip(0.1)}
                        }
                    },{
                        id: 'undersig',
                        name: 'Signature',
                        run: {
                            underline: UnderlineType.SINGLE,
                        },
                        paragraph: {
                            alignment: AlignmentType.CENTER,
                            spacing: {
                                after: convertInchesToTwip(0.05)
                            }
                        }

                    },{
                        id: 'final',
                        name: 'Final',
                        paragraph: {
                            spacing: {
                                after: convertInchesToTwip(0.1)
                            }
                        },
                    }

                ]

            },
            sections: [
                {
                    children: [

                        new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            text: 'Contrato de Arrendamiento',
                            alignment: AlignmentType.CENTER,
                        }),

                        new Paragraph({
                            text: intro,
                        }),

                        new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            text: 'Declaraciones',
                            alignment: AlignmentType.CENTER,
                        }),

                        ...declarations.map((o, idx) => (
                            [
                            new Paragraph({
                                text: o.heading,
                                numbering: {
                                    reference: 'declaration',
                                    level: 0,
                                },
                                heading: HeadingLevel.HEADING_2,
                                alignment: AlignmentType.LEFT,
                            }),
                            ...o.declarations.map(d => (
                                new Paragraph({
                                    text: d,
                                    style: 'declaration',
                                    numbering: {
                                        reference: 'declaration-text-' + idx,
                                        level: 0,
                                    }
                                })
                            ))
                            ]
                        )).flat(),

                        this.createHeading({text: 'Clausulas'}),
                        new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            alignment: AlignmentType.CENTER,
                        }),
                        ...clauses.map(c => this.createClause(c)).flat(),
                        new Paragraph({
                            style: 'final',
                            styles: {
                                paragraph: {
                                    spacing: {after: 1000}
                                }
                            },
                            text: last,
                        }),

                        new Table({
                            columnWidths: [5000, 5000],
                            rows: [
                                new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 100,
                                                type: WidthType.AUTO,
                                            },
                                            children: [
                                                new Paragraph({
                                                    text: 'Arrendatario',
                                                    style: 'signature'
                                                }),
                                                new Paragraph({
                                                    text: '            ',
                                                })
                                            ]
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 50,
                                                type: WidthType.PERCENTAGE,
                                            },
                                            children: [
                                                new Paragraph({
                                                    text: 'Arrendador',
                                                    style: 'signature',
                                                }),
                                                new Paragraph({
                                                    text: '               ',
                                                })
                                            ]
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }
            ]
        }
        const document = new Document(payload)
        return document;
    }


    createHeading({text}) {
        return new Paragraph({
            text,
            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.CENTER,
        })
    }

    createClause({heading, paragraphs}) {
        return [
            new Paragraph({
                text: heading,
                numbering: {
                    reference: 'clause',
                    level: 0,
                },
                heading: HeadingLevel.HEADING_2,
                alignment: AlignmentType.LEFT,
            }),
            ...paragraphs.map(p => {
                if(p.hasOwnProperty('custom'))
                    return p.custom().map(o => (
                        new Paragraph({
                            text: o,
                            style: 'custom',
                            paragraph: {
                                spacing: {
                                    after: 1000
                                }
                            },
                            numbering: {
                                reference: 'letters',
                                level: 0
                            }
                        })
                    ))
                return new Paragraph({
                    text: p,
                    style: 'clause',
                })
            }).flat()
        ]
    }
}

export const intro = ({arrendador = '', arrendatario = ''}) => `Contrato de Arrendamiento que celebran por una parte ${arrendador}, a quien para efectos de este Contrato se le denominará como el “Arrendador”, y por la otra parte ${arrendatario}, a quien para efectos de este Contrato se le denominará como el “Arrendatario” (en lo sucesivo, el “Contrato”). Al Arrendador en conjunto con el Arrendatario se les denominará como las “Partes” y se sujetan conforme a las siguientes Declaraciones y Cláusulas:`

export const declarations = ({direccionInmueble = '', ubicacionNotificaciones = ''}) => [
    {heading: 'Declara el Arrendador:',
        declarations: [
        'Ser de nacionalidad mexicana, mayor de edad, con capacidad y facultades legales necesarias para contratar y obligarse en los términos del presente Contrato;',
        'Que está inscrito en el Registro Nacional de Contribuyentes bajo la clave  ',
        `Que es legítimo propietario del inmueble ubicado en ${direccionInmueble}. (en lo sucesivo, el “Inmueble”) y que es su deseo darlo en arrendamiento en favor del Arrendatario; y`,
        `Que para efectos de este Contrato señala como su domicilio para recibir y oír toda clase de notificaciones el ubicado en ${ubicacionNotificaciones}.`,
        ]
    },
    {heading: 'Declara el Arrendatario:',
        declarations: [
        'Ser de nacionalidad mexicana, mayor de edad, con capacidad y facultades legales necesarias para contratar y obligarse en los términos del presente Contrato;',
        'Que está inscrito en el Registro Nacional de Contribuyentes bajo la clave  _____.',
        'Que tiene interés en recibir en arrendamiento el Inmueble que se describe en el inciso c), de la declaración número I;',
        'Que conoce perfectamente la ubicación, medidas, colindancias y estado físico del Inmueble, el cual reúne todas las condiciones de higiene y salubridad, por lo que es su deseo y está dispuesto a celebrar el presente Contrato Arrendamiento bajo los términos y condiciones que se establecen en el presente Contrato; y',
        'Que para efectos de este Contrato señala el Inmueble como su domicilio para recibir y oír toda clase de notificaciones.',
        ]
    },
    {heading: 'Declaran las Partes:',
        declarations: [
        'Que en el presente Contrato no existe dolo, error, mala fe o cualquier otro vicio de la voluntad; y',
        'Que se reconocen la personalidad con la que comparecen a la celebración de este Contrato y expresamente convienen en someterse a las obligaciones contenidas en las siguientes:',
        ]
    }
]

export const clauses = ({
 inicioFecha = '',
 finFecha = '',
 numeroCuentaDeposito = '',
 bancoDeposito = '',
 nombreCuentaDeposito = '',
 cantidadDeposito = '',
 cantidadDepositoText = '',
 depositoInicial = '',
 depositoInicialText = '',
}) => [
{
    heading: 'OBJETO DEL CONTRATO. ',
    paragraphs: [
    'Por medio del presente Contrato, el Arrendador otorga en favor del Arrendatario el uso y goce temporal del Inmueble, con todo cuanto de hecho y por derecho le corresponda y se encuentre dentro de su perímetro, obligándose el Arrendatario a cubrir por este concepto una renta mensual que se estipula más adelante.',
    ]
},
{
    heading: 'POSESIÓN.',
    paragraphs: [
    'Sujeto a los plazos, términos y condiciones aquí acordadas en el presente Contrato, el Arrendador otorga en arrendamiento el Inmueble al Arrendatario, y éste lo toma en dicha calidad.',
    ]
},
{
    heading: 'VIGENCIA DEL CONTRATO DE ARRENDAMIENTO. ',
    paragraphs: [
    `El presente Contrato tendrá una vigencia de 12 (doce) meses calendario, término que correrá a partir del día en que se entregue la posesión del Inmueble, que será precisamente el día ${inicioFecha} del año 2021 (en lo sucesivo, la Fecha de Entrega), y concluirá por consiguiente el día ${finFecha} del año 2022 sin necesidad de juicio o diligencia algunaCon 30 (treinta) días naturales de antelación a la fecha de terminación del presente Contrato o, en su caso, a sus prórrogas, las Partes podrán convenir por escrito su prórroga por la vigencia que acuerden entre ellas.`,
    ]
},
{
    heading: 'RENTA MENSUAL.',
    paragraphs: [
    `El Arrendatario pagará al Arrendador, ya sea mediante depósito bancario a la cuenta Número ${numeroCuentaDeposito} del banco ${bancoDeposito} a nombre de ${nombreCuentaDeposito}, o entrega a persona autorizada, en concepto de renta mensual por la ocupación del Inmueble, la cantidad de $${cantidadDeposito} (${cantidadDepositoText} PESOS 00/100 M.N.), más I.V.A(en lo sucesivo la “Renta Mensual”).`,
    'El Arrendatario deberá pagar la Renta Mensual, por meses adelantados, el día 1 de cada mes o, si éste fuera inhábil, el siguiente día hábil, teniendo como máximo cinco días posteriores a dicha fecha de pagoUna vez realizado el pago, el Arrendador entregará al Arrendatario la factura electrónica correspondiente, en caso de requerir factura.',
    'La falta de pago oportuno de la Renta Mensual traerá como consecuencia una pena convencional por moratoria a cargo del Arrendatario, y a favor del Arrendador, equivalente al 50% de la Renta Mensual por cada mes de retraso.',
    'El Arrendatario no podrá retener el pago de la Renta Mensual en caso alguno, ni bajo ningún título legal, contractual, extracontractual, extrajudicial, o cualquier otro, quedando obligado a pagarla, así como cualquier otra cantidad que le adeude al Arrendador, hasta que el Arrendatario haya entregado al Arrendador el Inmueble conforme a lo previsto en el presente Contrato.',
    'Cuando el Arrendatario cubra el importe de la Renta Mensual o cualquier otra obligación pecuniaria con un cheque y éste sea devuelto por el Banco respectivo, el Arrendatario indemnizará al arrendador por 20% (veinte por ciento) sobre documento devuelto en los términos del artículo 193 de la Ley General de Títulos y Operaciones de Crédito.',
    ]
},
{
    heading: 'DEPÓSITO.',
    paragraphs: [
    `El Arrendatario entrega a la firma del presente Contrato al Arrendador la cantidad de $${depositoInicial}(${depositoInicialText} PESOS 00/100 M.N.), en concepto de depósito (en lo sucesivo, el Depósito) y con el objeto de garantizar el cumplimiento de adeudos y el buen uso del Inmueble, entendiéndose que cualquier deterioro que se haga sobre el Inmueble será motivo de reparación a cargo del Arrendatario, en cuyo caso dicha reparación se realizará con cargo al Depósito que se menciona en la presente Cláusula.`,
    'Asimismo, ambas Partes están de acuerdo en que, si no existe deterioro o adeudo alguno, el Depósito de referencia se devolverá en forma íntegra al Arrendatario, salvo en el caso expreso de terminación anticipada por parte del Arrendatario descrito en la Cláusula Décima Quinta del presente ContratoEn caso de proceder la devolución del Depósito, ésta se hará dentro de los 30 (treinta) días siguientes a la desocupación y entrega del Inmueble al Arrendador, cuando se compruebe de manera fehaciente que no existe algún adeudo en los servicios. ',
    ]
},
{
    heading: 'ESTADO DEL INMUEBLE.',
    paragraphs: [
        'El Arrendatario constató a su entera satisfacción el estado de conservación del Inmueble, el cual se encuentra aseado y en buen estado de funcionamiento, obligándose el Arrendatario, a mantenerlo y devolverlo en el mismo estado al concluir el presente arrendamiento; asimismo, constató que el Inmueble cuenta con los servicios de agua, drenaje y electricidad, y que no existe adeudo pendiente; las Partes acuerdan que cualquier servicio público adicional que requiera el Inmueble será contratado por cuenta y costo del Arrendatario.',
    ]
},
{
    heading: 'ENTREGA DEL INMUEBLE.',
    paragraphs: [
    'El Arrendador, en este acto otorga al Arrendatario la ocupación efectiva del Inmueble, a efecto que el Arrendatario ejerza el uso y goce temporal del Inmueble; sujeto al cumplimiento de todas y cada una de las disposiciones y restricciones que sean aplicables y previstas en el presente Contrato',
    'El Arrendatario tendrá el derecho de acceder al Inmueble a partir de la Fecha de Entrega; lo anterior en el entendido de que el Arrendatario no podrá realizar cualesquier tipos de construcciones, cambios o ajustes al Inmueble, sin contar con el consentimiento previo y por escrito del Arrendador.',
    ]
},
{
    heading: 'SERVICIOS.',
    paragraphs: [
    'El Arrendatario se obliga a cubrir oportunamente el importe de los servicios de energía eléctrica, servicio de agua potable, así como a entregar los recibos originales liquidados por tales conceptos y por el servicio telefónico si es que lo hubiere, al ArrendadorEl Inmueble se entrega con los servicios antes mencionados, por lo que, si el Arrendatario decide contratar e instalar cualquier otro servicio será por su cuenta y responsabilidad, y sin afectación alguna para el Arrendador. Al término del Contrato, el Arrendatario deberá cancelar dicho servicio y cubrir todos los pagos que se hayan originado. Todos los asuntos relacionados con cualquier servicio del Inmueble deberán ser comunicados por escrito al Arrendador, o a quien sus derechos representen.',
    ]
},
{
    heading: 'MEJORAS AL INMUEBLE.',
    paragraphs: [
    'Todas las mejoras que desee llevar a cabo el Arrendatario en el Inmueble deberán ser notificadas al Arrendador y obtener autorización por escrito previo a su realizaciónEl Arrendatario asume toda la responsabilidad de la realización de cualquier obra de mejora, de permisos y licencias, así como del pago de primas, cuotas y/o cualquier obligación civil y administrativa de carácter legal. Dichas mejoras quedarán en beneficio del Inmueble, sin que el Arrendador tenga la obligación de pagar cantidad alguna por haberlas autorizado. ',
    ]
},
{
    heading: 'RENUNCIA.',
    paragraphs: [
    'El Arrendatario renuncia expresamente, mediante esta Cláusula, al derecho de tanto y de preferencia a que se refiere el artículo 2341 del Código Civil del Estado de Nuevo León, en caso de que el Arrendador deseare vender el Inmueble.',
    ]
},
{
    heading: 'OBLIGACIONES DEL ARRENDATARIO',
    paragraphs: [
        'Independientemente de cualquiera otra obligación de carácter general a cargo del Arrendatario conforme al presente Contrato, tendrá además las siguientes obligaciones específicas:',
        {custom: () => [
            'Respetar y cumplir las disposiciones y/o recomendaciones de la asociación de colonos o junta de vecinos;',
            'No podrá subarrendar a terceras personas todo o en parte, ni ceder los derechos y obligaciones que emanan del presente Contrato;',
            'Pagar puntualmente el importe de la Renta Mensual en la fecha estipulada;',
            'Cubrir el importe de las reparaciones que requiera el Inmueble por razón de su uso, o por errores u omisiones suyas o de terceros por obras y responsabilidades según la Cláusula Séptima y Novena; y',
            'No podrá almacenar sustancias peligrosas, inflamables, corrosivas, deletéreas o ilegales dentro del Inmueble, por lo cual, en caso de siniestro, el Arrendatario deberá cubrir al Arrendador y a los demás vecinos que resulten afectados los daños y perjuicios que les ocasione.',
        ]},
        'En el caso de que por cualquier causa imputable al Arrendatario la autoridad, cualquiera que ésta fuese, llegase a inmovilizar, limitar, clausurar, expropiar o extinguir el dominio de alguna manera del Inmueble, el Arrendatario y el Fiador se obligan de la manera más amplia y legal a indemnizar al Arrendador recuperando el valor monetario a precio vigente comercial del Inmueble. Si  por  causas  imputables  al  Arrendatario,  su  actividad,  visitas,  familiares  o  invitados,  hubiera  alguna situación  legal  que  diera  lugar  a  afectar  de  alguna  manera  al Arrendador  (sus  derechos  sobre  el Inmueble, las rentas, su tranquilidad personal o su situación jurídica), el Arrendatario se obliga a pagar los gastos, viáticos, costas, honorarios, consultas  legales, etc. a favor del Arrendador de  manera  completa  y suficiente para enfrentar cualquier situación que sea para salvaguardar los intereses del Arrendador. ',
        'El Arrendatario manifiesta que en caso de incumplimiento parcial o total de las Cláusulas y términos del presente Contrato dará motivo a procedimientos y trámites judiciales y podrá ser emplazado en el mismo domicilio del Inmueble y en caso de no estar ahí podrá ser emplazado de manera amplia y legal en el domicilio del Fiador establecido claramente en la declaración III, inciso “d” de este Contrato, e incluso en cualquier otro domicilio que fuese localizado.',
    ]

},{
	heading: 'RESCISIÓN DEL CONTRATO.',
    paragraphs: [
        'El Arrendador tendrá el derecho de rescindir el presente Contrato, sin necesidad de declaración judicial o extrajudicial alguna, en caso de que El Arrendatario incumpla con cualquiera de las obligaciones a su cargo estipuladas y derivadas del presente Contrato, además de lo estipulado en el artículo 2383 del Código Civil del Estado de Nuevo León. Cualquier renuncia tácita o expresa del Arrendador a ejercer su derecho de rescisión conforme al presente Contrato, en virtud de incumplimiento del Arrendatario o cualquier otro derecho que tuviere, no se considerará como renuncia en un caso similar o posterior incumplimiento de los términos y condiciones del presente Contrato. El incumplimiento por parte del Arrendatario para cubrir el pago puntual de dos Rentas Mensuales, la realización de modificaciones al Inmueble sin la autorización expresa por parte del Arrendador, o el incumplimiento de cualesquiera otra de las obligaciones a cargo del Arrendatario, dará lugar a la rescisión del presente Contrato por parte del Arrendador, quien lo hará saber al Arrendatario por escrito dentro de los 10 (diez) días inmediatos siguientes a cualquiera de los supuestos. Ante tal circunstancia el Arrendatario deberá desocupar el Inmueble en el término de 5 (cinco) días posteriores de recibida dicha notificación, sin necesidad de declaración judicial alguna.',

    ]
},
{
	heading: 'TERMINACIÓN ANTICIPADA.',
    paragraphs: [
        'En caso de que el Arrendatario deseare desocupar el Inmueble arrendado antes del término pactado en este Contrato, podrá hacerlo siempre y cuando lo notifique con al menos 30 (treinta) días de anticipación al Arrendador, siempre que esté al corriente en el pago de la Renta Mensual y en el cumplimiento de sus demás obligaciones conforme al presente Contrato. En el caso de que se cumpla este supuesto por parte del Arrendatario, éste perderá el 100% del depósito a que se refiere la Cláusula Quinta, como pena convencional.',

    ]
},
{
	heading: 'PACTO COMISORIO',
    paragraphs: [
        'Las Partes convienen de común acuerdo en dejar establecido el siguiente pacto comisorio: ',
        'En el caso de incumplimiento por parte del Arrendatario de cualquiera de las obligaciones establecidas en el presente Contrato, dará lugar al presente pacto comisorio, el cual tendrá como consecuencia la facultad de la Arrendadora para poder ejecutar el procedimiento de rescisión y por consecuencia la recuperación inmediata del Inmueble sin la intervención de un juez, siguiendo los requerimientos legales establecidos en el artículo 2384 BIS I. del Código Civil de Nuevo León. Para dichos efectos, el Arrendatario nombra como representante irrevocable al Licenciado Alejandro Patrón Rivera, a quien entregará la posesión física del inmueble sin necesidad de declaración judicial. Por medio del presente Contrato y en virtud del artículo 2319 del Código Civil de Nuevo León, el Arrendatario se obliga a cumplir lo convenido en este pacto comisorio expreso.',

    ]
},
{
	heading: 'PENA CONVENCIONAL. ',
    paragraphs: [
        'En el evento de que el Arrendatario no desocupe el Inmueble por cualquier causa en la fecha señalada en el presente Contrato, las Partes convienen en que la Renta Mensual que se cubrió con motivo de la última renta vigente, se incremente sin necesidad de convenio, acuerdo u otro instrumento, en cincuenta por ciento (50%), sin que dicho incremento implique una renovación del Contrato ni prórroga del mismo, siendo voluntad expresa de las Partes únicamente establecer dicho incremento como pena convencional a cargo del Arrendatario y a favor del Arrendador. El incremento señalado se producirá en igual porción (incluyendo el primer o sucesivo incremento del cincuenta por ciento) en cada mes que transcurra sin que el Arrendatario desocupe el Inmueble, subsistiendo además todas las obligaciones señaladas para el Arrendatario hasta el momento en que el Inmueble sea devuelto al Arrendador conforme a lo estipulado en este Contrato. No obstante, en caso de que el Arrendador otorgue su consentimiento por escrito para la renovación del presente Contrato, la Renta Mensual se incrementará según acuerdo entre las Partes.',
    ]
},
{
	heading: 'ACCIDENTES.',
    paragraphs: [
        'Los daños ocasionados al Inmueble, así como a los colindantes, por siniestros originados por culpa o negligencia del Arrendatario, de su Fiador y/o de toda persona que viva en o visite por cualquier motivo el Inmueble, serán de la exclusiva responsabilidad del Arrendatario,  por lo que en caso de detectar algún equipo o instalación en mal estado en el primer mes de la ocupación del mismo, se deberá dar aviso por escrito al Arrendador, con acuse de recibo, para proceder a la reparación, por cuenta del Arrendador, siempre y cuando no sea imputable la falla al Arrendatario. Cuando el Inmueble sea clausurado o suspendido en su uso por disposición de autoridad competente y/o por causas imputables al Arrendatario, el Arrendatario se obliga a continuar pagando las Rentas Mensuales pactadas en el plazo convenido y se hace responsable de las multas y sanciones que deriven. El incumplimiento de esta obligación originará el pago de una pena convencional a cargo del Arrendatario por el equivalente a dos meses de Renta Mensual pactada en favor del Arrendador. En el caso de que el Inmueble sea clausurado o sea asegurado por cualquier autoridad, por causas imputables al Arrendatario, será causal de rescisión del presente Contrato, pudiendo el Arrendador exigir dicha rescisión en cualquier momento. ',
    ]
},
{
	heading: 'EXTINCIÓN DE DOMINIO.',
    paragraphs: [
        'El Arrendador declara que no ha participado en actos ilícitos y que el Inmueble no es ni ha sido producto, instrumento u objeto de lo referido en la fracción II (segunda) del artículo 22 constitucional; que en el Inmueble no ha ocultado ni oculta bienes o involucra bienes producto de algún delito; que no tiene conocimiento que el Inmueble haya sido utilizado para comisión de delitos por algún tercero; que el Inmueble lo adquirió con dinero producto de actos lícitos. El Arrendatario declara que el Inmueble lo arrendará y cubrirá la Renta Mensual descrita en la Cláusula Cuarta del presente Contrato con dinero producto de actos lícitos; que no pertenece a ninguna agrupación ilegal o a ningún grupo que se dedique a actividades ilícitas y el uso que hará del Inmueble es para un fin lícito. Tanto el Arrendador, como el Arrendatario, declara que, como efecto de lo señalado en el presente Contrato, el Arrendador no tendrá acceso alguno al Inmueble, por lo que no podrá enterarse de los actos o actividades que se lleven a cabo en el Inmueble por el Arrendatario o por un tercero.  Por consiguiente, es el Arrendatario es el único responsable de dichos actos o actividades ante todo tipo de autoridades y se obliga a sacar en paz y a salvo al Arrendador y al Inmueble, obligándose el Arrendatario a restituir al Arrendador de todas las consecuencias que el incumplimiento de esta Cláusula pueda ocasionar.',
    ]
},
{
	heading: 'ACUERDO TOTAL.',
    paragraphs: [
        'Este Contrato y todos sus Anexos, si los hubiese, constituyen la totalidad de los acuerdos celebrados entre las Partes respecto al objeto de este, y sustituye y deja sin efectos cualquier acuerdo previo entre las Partes en relación con el mismo. ',
    ]
},
{
	heading: 'ENCABEZADOS.',
    paragraphs: [
        'El encabezado con el que cuenta cada una de las Cláusulas de este Contrato ha sido incluido únicamente con el propósito de facilitar la consulta de este, por lo que no afectará para la interpretación de este acto jurídico.',
    ]
},
{
heading: 'LEYES APLICABLES Y TRIBUNALES COMPETENTES.',
  paragraphs: [
    'Para todo lo relacionado con la interpretación y ejecución del presente Contrato son aplicables las Leyes correspondientes a su objeto y, en caso de controversia, serán competentes los tribunales de Monterrey, Nuevo León, México, renunciando las Partes expresamente a cualquier otra jurisdicción que pudiera corresponderles por virtud de sus domicilios presentes o futuros o por cualquier otro motivo.  ',
  ]
},
]

export const PolicyDocx = (props) => {
    const generate = () => {
        const documentCreator = new DocumentCreator();
        debugger
        const params = {clauses: clauses({
            inicioFecha: props.inicioFecha,
             finFecha: props.finFecha,
             numeroCuentaDeposito: props.numeroCuentaDeposito,
             bancoDeposito: props.bancoDeposito,
             nombreCuentaDeposito: props.nombreCuentaDeposito,
             cantidadDeposito: props.cantidadDeposito,
             cantidadDepositoText: props.cantidadDepositoText,
             depositoInicial: props.depositoInicial,
             depositoInicialText: props.depositoInicialText,
        }), intro: intro({
            arrendador: props.arrendador,
            arrendatario: props.arrendatario,
        }), declarations: declarations({
            direccionInmueble: props.direccionInmueble,
            ubicacionNotificaciones: props.ubicacionNotificaciones,
        }), last:  `Una vez leído el presente Contrato por las Partes y enteradas del contenido y alcance legal de todas y cada una de las Cláusulas que lo componen, declaran que no existen vicios del consentimiento que pudieren invalidar o nulificar dicho Contrato y lo firman de entera conformidad el día ${props.currentDay} en la ciudad de Monterrey, Nuevo León, México.`}

        params.clauses = params.clauses.filter((_, idx) => !props.hiddenClauses.includes(idx))
        const doc = documentCreator.create(params)
        Packer.toBlob(doc).then(blob => {
            saveAs(blob, 'example.docx')
            console.log('document created')
        })
    }

    return (
          <Button fullWidth variant="contained" color="primary" onClick={() => generate()}>
                Contrato
          </Button>
    )
}

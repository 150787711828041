import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SearchInput } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const UsersToolbar = props => {
  const history = useHistory();
  const classes = useStyles();

  const { className, filter, filterScope, ...rest } = props;
  const { t, i18n } = useTranslation();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {/* <div className={classes.row}> */}
      {/*   <span className={classes.spacer} /> */}
      {/*   <Button */}
      {/*     color="primary" */}
      {/*     variant="contained" */}
      {/*     onClick={() => history.push('/tenants/create')}> */}
      {/*     {t('add_x', {x: t('tenant')})} */}
      {/*   </Button> */}
      {/* </div> */}
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder={t('search_x_by', {x: t('tenant')}) + ' ' + t(filterScope)}
          value={filter[0]}
          onChange={e => filter[1](e.target.value)}
        />
      </div>
    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default UsersToolbar;

import React from 'react'
import {
  Card,
  CardContent,
  Typography,
  Grid
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/styles'

import { parseCurrency } from 'lib/helpers'
const useStyles = makeStyles(theme => ({
  cardContent: {
    display: 'flex',
    alignItems: 'center'
  },
  classSummary: {
    flex: '1',
    flexGrow: '5',
  },
  textSecondary: {
    color: theme.palette.text.secondary,
    textTransform: 'capitalize'
  }

}))

const PaymentCard = (props) => {
  const {invoice, payment} = props;
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <form>
      <Card style={{margin: '1rem 0', borderRadius: '1rem'}}>
        <CardContent className={classes.cardContent}>
          <div className={classes.classSummary}>
            <Typography variant='h5' style={{textTransform: 'capitalize'}}>{t(payment.paymentMethod)}</Typography>
            <Typography variant='h6' className={classes.textSecondary}>{payment.reference}</Typography>
            <Typography variant='h6' className={classes.textSecondary}>{payment.cardNum}</Typography>
          </div>
          <div style={{flex: '1', textAlign: 'right'}}>
            <Typography variant='h4'>
                <span style={{color: '#9ca3a8'}}>$</span>{parseCurrency(payment.qty)}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </form>
  )
}
export default PaymentCard

import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, Chip, Button } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import DefineAbilityFor from 'constants/abilities'
import { subject } from '@casl/ability'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import moment from 'moment'

import { Can } from 'lib/can'
import { PropertyForm } from './components';
import { PolicyList } from 'views'
import { useQuery, useMutation } from '@apollo/react-hooks';
import { FETCH_PROPERTIES, UPDATE_POLICY } from 'constants/queries';
import { StoreContext } from 'App'
import { parseCurrency } from 'lib/helpers'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const PropertyView = props => {
  const { propertyId } = useParams();
  const { className, requirement, ...rest } = props;
  const { t } = useTranslation()
  const history = useHistory();
  const context = useContext(StoreContext)
  const ability = DefineAbilityFor(context.state.session)
  const { data } = useQuery(FETCH_PROPERTIES, {
    variables: { query: { id: propertyId } }
  });

  const [mutatePolicy, policyMutationStatus] = useMutation(UPDATE_POLICY)
  const classes = useStyles();

const policyHeadCells = [
  {
      id: 'id',
      numeric: false,
      label: t('id'),
      numeric: true,
      searchable: true,
      disablePadding: true,
      fn: p => parseInt(p.id),
      display: p => '#' + p.id
  }, {
      id: 'policyType',
      numeric: false,
      label: t('policyType'),
      numeric: false,
      searchable: true,
      disablePadding: true,
      fn: p => p.policyType,
      display: p => t(p.policyType),
    },{
      id: 'policyStatus',
      numeric: false,
      label: t('policyStatus'),
      searchable: true,
      disablePadding: true,
      fn: p => p.policyStatus,
      display: p => <Chip label={t(p.policyStatus)} className={clsx(p.policyStatus == 'pending' && classes.lateChip, p.policyStatus == 'expired' && classes.concludedChip, p.policyStatus == 'valid' && classes.validChip)}/>
    },{
      id: 'endDate',
      numeric: false,
      label: t('policyEndDate'),
      searchable: true,
      disablePadding: true,
      fn: p =>  moment(p.endDate).format('MMM DD, YYYY'),
      display: p => moment(p.endDate).format('MMM DD, YYYY'),
      sortFn: i => new Date(i.endDate)
    },{
      id: 'policyCost',
      numeric: true,
      label: t('policyCost'),
      searchable: true,
      disablePadding: true,
      fn: p => p.lastInvoice ? p.lastInvoice?.total : 0,
      display: p => p.lastInvoice ? '$' + parseCurrency( p.lastInvoice.total.toString() ) : 0

    }, {
      id: 'lastPaymentDate',
      numeric: false,
      label: t('lastPaymentDate'),
      numeric: false,
      searchable: true,
      disablePadding: true,
      fn: p => p.lastPayment ? moment(p.lastPayment.createdAt).format('MMM DD, YYYY') : 'N/A',
      display: p => p.lastPayment ? moment(p.lastPayment.createdAt).format('MMM DD, YYYY') : 'N/A',
      sortFn: p => new Date(p.lastPayment ? p.lastPayment.createdAt : 0)
    }, {
      id: 'actions',
      numeric: false,
      label: t('actions'),
      numeric: false,
      searchable: false,
      disablePadding: true,
      display: (p) => p.policyStatus != 'canceled' && (
          <Can I='update' this='policy' field='isCanceled'>
            <Button 
            disabled={policyMutationStatus.loading}
            onClick={() => {
            mutatePolicy({
              onCompleted: () => {
                context.state.showAlert({
                  severity: 'success',
                  message: `${t('policy')} ${t('updated')}`
                  })
              },
              variables: {
                id: p.id,
                input: {
                  isCanceled: true
                }
              }
            })}}
            >{t('cancel')}</Button>
          </Can>
      )
    }
  ]

  if (data) {
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <PropertyForm 
              property={data.properties[0]} 
              fieldsEnabled={ability.can('update', subject('property', data.properties[0]))}
              onCompleted={ () => {
                context.state.showAlert({
                  severity: 'success',
                  message: `${t('property')} ${t('updated')}`
                });
                setTimeout(() => history.push('/properties'), 1000)
              }}
              onError={ (e) => {
                context.state.showAlert({
                  severity: 'error',
                  message: e.message
                });
              }}
            />
          </Grid>
          <Grid item lg={4} md={6} xl={4} xs={12} />
          <Grid item xs={12}>
            <Paper style={{minHeight: '10em'}}>
              <PolicyList headCells={policyHeadCells} query={{propertyId: propertyId}}/>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
  return <div></div>;
};

export default PropertyView;


import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles'
import SendIcon from '@material-ui/icons/Send';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Button,
  Typography,
  Divider,
  TextField,
  IconButton,
  Box
} from '@material-ui/core';
import { StoreContext } from 'App'
import { FETCH_CHAT_ROOM, POST_CHAT_MESSAGE } from 'constants/queries'

import MessageList from './messageList.js'

const useStyles = makeStyles(theme => ({
  root:  {
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flexGrow: 1
  },
  form: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  messageList: {
    flexGrow: '1',
  },
  textArea: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '3em',
    flexGrow: '0',
    marginTop: '8px'
  }
}))

const Chat = ({room, updateLastSeen}) => {
  const { t } = useTranslation()
  const [msgBody, setMsgBody] = useState('')
  const context = useContext(StoreContext)
  const userId = context.state.session.id;
  const classes = useStyles();
  
  const queryParams = useQuery(FETCH_CHAT_ROOM, {
    fetchPolicy: 'network-only',
    variables: {
      query: {
        id: room.id
      }
    }
  })

  const [sendMessage, deliveryState] = useMutation(POST_CHAT_MESSAGE, {
    onCompleted: () => {setMsgBody(''); updateLastSeen({variables: {id: room.id}})}
  })

  const onSubmit = (e) => {
    e.preventDefault();
    sendMessage({
      variables: {
        input: {
          userId,
          chatRoomId: room.id,
          body: msgBody
        }
      }
    })
  }

  if(queryParams.data)  {
    return (
      <Card className={classes.root}>
        <CardHeader title={room.creator.fullName}/>
        <Divider />
        <CardContent className={classes.content}>
          <form action="" onSubmit={onSubmit} className={classes.form}>
            <Box className={classes.messageList}>
              <MessageList room={queryParams.data.chatRooms[0]} updateLastSeen={updateLastSeen} {...queryParams}/>
            </Box>
            <Grid container spacing={3} className={classes.textArea}>
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  variant='outlined'
                  name='message'
                  label={t('message')}
                  value={msgBody}
                  onChange={e => setMsgBody(e.target.value)}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton type='submit'>
                  <SendIcon/>
                </IconButton>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    )
  }
  return <div/>

}
export default Chat

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { InvoiceToolbar, InvoiceTable } from './components';
import { useQuery } from '@apollo/react-hooks';
import { FETCH_INVOICES } from 'constants/queries';
import mockData from './data';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const InvoiceList = () => {
  const classes = useStyles();
  const { data } = useQuery(FETCH_INVOICES, { fetchPolicy: 'network-only' });
  const filter = useState('');
  const [ filterScope, setFilterScope ] = useState('fullName')
  if (data) {
    const invoices = data.invoices.filter(invoice =>
      invoice[filterScope].match(new RegExp(filter[0], 'i'))
    );

    return (
      <div className={classes.root}>
        <InvoiceToolbar filter={filter} filterScope={filterScope} />
        <div className={classes.content}>
          <InvoiceTable invoices={invoices} setFilterScope={setFilterScope} filterScope={filterScope}/>
        </div>
      </div>
    );
  }
  return <div></div>;
};

export default InvoiceList;

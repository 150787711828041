import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { StoreContext } from 'App';
import { LOG_IN } from 'constants/queries';
import { Facebook as FacebookIcon, Google as GoogleIcon } from 'icons';
import { LOG_IN_SUCCESS, FETCHING_SESSION } from 'lib/actions';
import Helpers from 'lib/helpers'

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/backgrounds/navio.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignIn = props => {
  const { history } = props;
  const { t } = useTranslation();
  const { register, errors, handleSubmit } = useForm();

  const context = React.useContext(StoreContext);
  const classes = useStyles();


  const onSubmit = (data) => {
      // context.dispatch({action: FETCHING_SESSION})
      // debugger
      logIn({ variables: data }); 
  }

  const handleSuccess = data => {
    if (context === undefined)
      throw new Error('Must be used within context provider');
    if (data.signIn && context) {
      context.dispatch({ type: LOG_IN_SUCCESS, payload: data.signIn });
      history.push('/');
    } else
      context.state.showAlert({
        severity: 'error',
        message: 'Credenciales Invalidas'
      });
  };

  const [logIn, mutationState] = useMutation(LOG_IN, {
    onCompleted: handleSuccess
  });

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                  { t('welcome') }
              </Typography>
              <div className={classes.person}>
                <Typography className={classes.name} variant="body1">
                  Navio
                </Typography>
                <Typography className={classes.bio} variant="body2">
                  {/* Manager at inVision */}
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                <Typography className={classes.title} variant="h2">
                  { t('sign_in') }
                </Typography>
                <Typography color="textSecondary" gutterBottom></Typography>
                {/* <Grid className={classes.socialButtons} container spacing={2}> */}
                {/*   <Grid item> */}
                {/*     <Button */}
                {/*       disabled */}
                {/*       size="large" */}
                {/*       variant="contained"> */}
                {/*       <GoogleIcon className={classes.socialIcon} /> */}
                {/*         { t('login_with', {service: 'Google'})} */}
                {/*     </Button> */}
                {/*   </Grid> */}
                {/* </Grid> */}
                {/* <Typography */}
                {/*   align="center" */}
                {/*   className={classes.sugestion} */}
                {/*   color="textSecondary" */}
                {/*   variant="body1"> */}
                {/*   { t('or_with_email') } */}
                {/* </Typography> */}
                <TextField
                  fullWidth
                  className={classes.textField}
                  label="Correo Electronico"
                  name="email"
                  type="text"
                  variant="outlined"
                  inputRef={register({required: true, pattern: Helpers.emailRegex})}
                  error={errors.email}
                  helperText={errors.email && t(errors.email.type)}
                />
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Contraseña"
                  name="password"
                  type="password"
                  variant="outlined"
                  inputRef={register({required: true})}
                  error={errors.password}
                  helperText={errors.password && t(errors.password.type)}
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained">
                  { t('enter') }
                </Button>
                {/* <Typography color="textSecondary" variant="body1"> */}
                {/*     { t('no_account?') } */}
                {/*   <Link */}
                {/*     component={RouterLink} */}
                {/*     to="/sign-up" */}
                {/*     variant="h6"></Link> */}
                {/* </Typography> */}
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  LinearProgress
} from '@material-ui/core';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { FETCH_REQUIREMENTS } from 'constants/queries';
import { Loader } from 'components'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  progress: {
    marginTop: theme.spacing(3)
  }
}));

const TasksProgress = props => {
  const { className, ...rest } = props;
  const { data } = useQuery(FETCH_REQUIREMENTS);
  const { t } = useTranslation();

  const classes = useStyles();
  if (data) {
      const activeRequirements = data.requirements.filter(e => e.status == 'active')
      const requirementsWithLots = activeRequirements.filter(e => e.lots.length == 0)
      const percentage =  parseInt(requirementsWithLots.length / activeRequirements.length * 100);

      return (
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardContent>
            <Grid container justify="space-between">
              <Grid item>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                  variant="body2">
                  { t('requirements_without_lots') }
                </Typography>
                <Typography variant="h3">
                    { requirementsWithLots.length }
                </Typography>
                <Typography className={classes.caption} variant="caption">
                    { `${percentage}%` }
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar}>
                  <InsertChartIcon className={classes.icon} />
                </Avatar>
              </Grid>
            </Grid>
            <LinearProgress
              className={classes.progress}
              value={percentage}
              variant="determinate"
            />
          </CardContent>
        </Card>
      );
  }
  return (
    <Card>
      <CardContent>
        <Loader></Loader>
      </CardContent>
    </Card>
  )
  };

TasksProgress.propTypes = {
  className: PropTypes.string
};

export default TasksProgress;

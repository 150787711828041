import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SearchInput } from 'components';
import { Can } from 'lib/can';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const RequirementsToolbar = props => {
  const { className, filter, ...rest } = props;

  const history = useHistory();
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Can I="create" this="requirement">
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.push('/requirements/create')}>
            {t("add_requirement")}
          </Button>
        </Can>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder={t("search_requirement")}
          value={filter[0]}
          onChange={e => filter[1](e.target.value)}
        />
      </div>
    </div>
  );
};

RequirementsToolbar.propTypes = {
  className: PropTypes.string
};

export default RequirementsToolbar;

import React from 'react'
import {ErrorObject} from 'components'
/*
* ErrorBoundary
* component to prevent errors from boubling up the hieracrhy
* expocts an ErrorObjects
* exposes API
*
* props:
*   onMessageDislay
*   children
*
* ErrorObject
*   message
*   level
*   incidentTime
*/
const styles = {
  background: '#ff8e8e',
  border: '1px solid #C00A',
  boxShadow: '0 3px 6px #fa8787aa',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 'auto',
  padding: '1em',
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false}
  }


  static getDerivedStateFromError(error) {
    if(error instanceof ErrorObject) {
      return {hasError: true, errorObject: error}
    }
    else
      return {hasError: true, errorObject: new ErrorObject({message: error.message})}
  }

  componentDidCatch(error, errorInfo) {
    try {
      if((error instanceof ErrorObject) && error.level == 1) this.props.onMessageDisplay(error.message)
    } catch(err) {
      console.error('UNKNOWN ERROR: ErrorBoundary did not receive ErrorObject')
    }
  }

  render() {
  

      if(this.state.errorObject) return (
        <div style={{...styles, ...this.props.styles}}>
          <div>{this.state.errorObject.message}</div> 
        </div>
        )
      if(this.state.hasError) return (
        <div style={{...styles, ...this.props.styles}}>
          <div>Unknown Error: 0</div> 
        </div>
        )
      return this.props.children;

  }
}

export default ErrorBoundary

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import { getAuthToken } from './lib/Authentication';
import { createUploadLink } from 'apollo-upload-client';
import apolloLogger from 'apollo-link-logger';
import cacheLogger from 'apollo-cache-logger';
import * as ActionCable from '@rails/actioncable'
// import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import { ActionCableLink } from 'graphql-ruby-client'

const devUri = 'http://localhost:3000'
const prodUri = 'https://api.navio.mx'

const uri = prodUri

const cable = ActionCable.createConsumer(uri + '/cable');

const hasSubscriptionOperation = ({query: {definitions}}) => 
  definitions.some(
    ({kind, operation}) => kind === 'OperationDefinition' && operation == 'subscription'
  )

const httpLink = ApolloLink.from([
  apolloLogger,
  setContext((_, { headers }) => {
    const token = getAuthToken();
    console.log(token);
    return {
      headers: {
        ...headers,
        auth_token: token || ''
      }
    };
  }),
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError) console.log(`[Network error]: ${networkError}`);
  }),
  new createUploadLink({
    uri: uri + '/graphql',
    credentials: 'same-origin'
  })
])

const link = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({cable}),
  httpLink
)

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
  // cache: new cacheLogger(new InMemoryCache(), {logger: msg => console.log('cache: \n', msg)})
});
export default client;

import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { StatusBullet } from 'components';
import { FETCH_REQUIREMENTS } from 'constants/queries';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    // minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const statusColors = {
  delivered: 'success',
  pending: 'info',
  refunded: 'danger'
};

const LatestRequirements = props => {
  const { className, ...rest } = props;
  const { data, loading, error } = useQuery(FETCH_REQUIREMENTS);
  const { t } = useTranslation()

  const classes = useStyles();
  const history = useHistory();

  if (data) {
    const requirements = data.requirements
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .slice(0, 5);

    return (
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title={ t('recent_requirements') }/>
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{ t('name') }</TableCell>
                    <TableCell>{ t('product') }</TableCell>
                    <TableCell sortDirection="desc">
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel active direction="desc">
                            { t('date') }
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requirements.map(requirement => (
                    <TableRow
                      hover
                      key={requirement.id}
                      onClick={() => history.push('/requirements/view/' + requirement.id)}>
                      <TableCell>{requirement.name}</TableCell>
                      <TableCell>
                        {requirement.product.name}
                      </TableCell>
                      <TableCell>
                        {moment(requirement.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={() => history.push('/requirements')}>
            { t('action_view_all')} <ArrowRightIcon />
          </Button>
        </CardActions>
      </Card>
    );
  }

  return <div></div>;
};

LatestRequirements.propTypes = {
  className: PropTypes.string
};

export default LatestRequirements;

import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import gql from 'graphql-tag'
import moment from 'moment'
import ErrorIcon from '@material-ui/icons/Error';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Button,
  Typography,
  Divider,
  TextField,
  Avatar,
  Paper
} from '@material-ui/core';

import { Can } from 'lib/can'
import { Chat } from './components'
import { Loader } from 'components'
import { StoreContext } from 'App'
import { SUBSCRIPTION_CHAT_ROOM_UPDATES, UPDATE_ROOM_LAST_SEEN, GET_ACTIVE_CHAT_ROOMS } from 'constants/queries'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  grid: {
    height: 'calc(100vh - 64px - 2em)'
  },
  chatRoomOption: {
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',
    '&:hover': {
      boxShadow: '0 3px 6px #0005'
    }
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3em'
  }
}))

const ChatRoom = ({room, onClick}) => {
  const classes = useStyles()
  const context = useContext(StoreContext)

  let lastMessage = room.lastMessage ? room.lastMessage.body : ''
  if(lastMessage.length > 20) lastMessage = lastMessage.substr(0, 17) + '...';
  const lastSeenOutdated = room.lastMessage.createdAt > room.lastSeenForCurrentUser;
  const lastMessageIsFromCurrentUser = room.lastMessage.userId == context.state.session.id
  const hasUnseenMessages = lastSeenOutdated && !lastMessageIsFromCurrentUser
  
  return (
  <Card className={classes.chatRoomOption} onClick={onClick}>
    <CardHeader 
      title={room.creator.fullName} 
      subheader={moment(room.lastMessage.createdAt).format('DD/MM/YYYY') + ' - ' + lastMessage}
      avatar={hasUnseenMessages && <Avatar >
          <ErrorIcon/>
        </Avatar>
      }
    />
  </Card>
  )

}

const ClientAttentionView = () => {
  const { t } = useTranslation();
  const { data, loading, error, subscribeToMore } = useQuery(GET_ACTIVE_CHAT_ROOMS)
  const [updateLastSeen, _] = useMutation(UPDATE_ROOM_LAST_SEEN, {
    update(cache, {data: { chatRoom }}) {
      chatRoom.id = currentRoom.id
      const {chatRooms} = cache.readQuery({ query: GET_ACTIVE_CHAT_ROOMS})
      const targetRoom = chatRooms.filter(room => room.id == chatRoom.id)[0]
      const updatedRoom = {...targetRoom, lastSeenForCurrentUser: chatRoom.updateLastSeenForCurrentUser}
      cache.writeQuery({
        query: GET_ACTIVE_CHAT_ROOMS,
        data: { chatRooms: [...chatRooms.filter(room => room.id != updatedRoom.id), updatedRoom]}
      })
    }
  })

  const classes = useStyles()
  const [currentRoom, _setCurrentRoom] = useState(null)
  const setCurrentRoom = (room) => {
    _setCurrentRoom(room)
    updateLastSeen({variables: {id: room.id}})
  }

  const handleGlobalUpdates = {
    document: SUBSCRIPTION_CHAT_ROOM_UPDATES,
    updateQuery(prev, { subscriptionData }) {
      if(!subscriptionData) return prev;
      const updatedRoom = subscriptionData.data.chatRoomUpdated;
      return {...prev, chatRooms: [...prev.chatRooms.filter(room => room.id != updatedRoom.id), updatedRoom]}
    }
  }

  useEffect(() => {
    if(data) {
      setCurrentRoom(data.chatRooms[0])
    }
  }, [!data])

  useEffect(() => {
    return subscribeToMore(handleGlobalUpdates)
  }, [])

  if(data && data.chatRooms) {

    const activeRooms = data.chatRooms
                            .filter(cr => cr.messageCount > 0)
                            .sort((a,b) => (new Date(a.lastMessage.createdAt)) - new Date(b.lastMessage.createdAt))

    if(activeRooms.length > 0 && currentRoom) {
      return (
        <div className={classes.root}>
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={4}>
              <Grid container spacing={1}>
                {activeRooms.map(cr => (
                  <Grid item xs={12} key={cr.id}>
                    <ChatRoom room={cr} onClick={() => {setCurrentRoom(cr)}}/>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Chat room={currentRoom} updateLastSeen={updateLastSeen}/>
            </Grid>
          </Grid>
        </div>
      )
    } else {
      return (
        <div className={classes.root}>
          <Paper className={classes.paper}>
              <Typography variant='h3'>{t('no_active_chats')}</Typography>
          </Paper>
        </div>
      )
    }
  }
  else return <Loader />
}

export default ClientAttentionView

import gql from 'graphql-tag';

export const PolicyFragments = {}


PolicyFragments.fields = gql`
  fragment policyFields on Policy {
    id
    policyType
    policyStatus
    startDate
    endDate
  }
`

export const ContractFragments = {
  fields: gql`
    fragment contractFields on Contract {
      id
      paymentDate
      paymentFrequency
      tax
      deposit
      paymentMethod
      accountOwner
      clabe
      bank
      cardNum
      monthlyRent
      startDate
      endDate
    }
`}

export const FileLinkFragments = {
  fields: gql`
    fragment fileLinkFields on FileLink {
      link
      name
      attachmentId
    }
  `
};

export const ProductFragments = {
  fields: gql`
    fragment productFields on Product {
      id
      name
    }
  `
};

export const ProductCategoryFragments = {
  fields: gql`
    fragment productCategoryFields on ProductCategory {
      id
      name
      products {
        ...productFields
      }
    }
    ${ProductFragments.fields}
  `
};

export const ClientDetailFragments =  {
  fields: gql`
    fragment clientDetailFields on ClientDetail {
      id
      rfc
      socialReason
      personType
      writingNumber
      notaryName
      publicNotary
      notaryCity
      registryData
      legalRepresentative
      writingDate
      registrationDate
      others {
        ...fileLinkFields
      }
      ine {
        ...fileLinkFields
      }
    }
    ${FileLinkFragments.fields}
  `
}

export const UserFragments = {
  fields: gql`
    fragment userFields on User {
      id
      fullName
      firstName
      lastName
      avatar
      phone
      email
      credentials
      createdAt
    }
  `
};


export const LotFragments = {
  fields: gql`
    fragment lotFields on Lot {
      id
      provider {
        id
        firstName
        lastName
      }
      requirement {
        id
        name
        units
      }
      pricePerUnit
      deliveryDate
      expirationDate
      contactEmail
      contactPhone
      createdAt
      comments
      priceDetail
      inAdvance
      againstDocs
      onDelivery
      accepted
      certifications {
        ...fileLinkFields
      }
    }
    ${FileLinkFragments.fields}
  `
};

export const RequirementFragments = {
  fields: gql`
    fragment requirementFields on Requirement {
      id
      name
      units
      comments
      createdAt
      unitType
      priceDetail
      deadline
      deliveryDate
      status
      product {
        ...productFields
      }
      productPresentation {
        id
        description
      }
    }
    ${ProductFragments.fields}
  `
};

export const ProviderDetailFragments = {
  fields: gql`
    fragment providerDetailFields on ProviderDetail {
      id
      companyName
      contactName
      contactPhone
      contactEmail
      certificationOfQuality {
        ...fileLinkFields
      }
      halal {
        ...fileLinkFields
      }
      kosher {
        ...fileLinkFields
      }
      iso {
        ...fileLinkFields
      }
      haccp {
        ...fileLinkFields
      }
      micribiologicalAnalisis {
        ...fileLinkFields
      }
      organicCertification {
        ...fileLinkFields
      }
      brc {
        ...fileLinkFields
      }
      ifs {
        ...fileLinkFields
      }
      fssc {
        ...fileLinkFields
      }
      productionFlowDiagram {
        ...fileLinkFields
      }
      fisicalProductAnalisis {
        ...fileLinkFields
      }
      certifications {
        ...fileLinkFields
      }
    }
    ${FileLinkFragments.fields}
  `
};

export const MessageFragments = {
  fields: gql`
    fragment messageFields on Message {
      id
      body
      createdAt
      user {
        id
        firstName
        lastName
        email
      }
    }
  `
}

export const ProductPresentationFragments = {
  fields: gql`
    fragment productPresentationFields on ProductPresentation {
      id
      description
    }
  `
}


export const ChatMessageFragments = {
  fields: gql`
    fragment chatMessageFields on ChatMessage {
      id
      body
      createdAt
      user {
        id
        firstName
        lastName
        email
      }
    }
  `
}

export const AddressFragments = {
  fields: gql`
    fragment addressFields on Address {
        id
        addressableId
        addressableType
        state
        city
        colony
        street
        extNum
        intNum
        zipCode
        createdAt
    }
  `
}

export const AvalFragments = {
  fields: gql`
    fragment avalFields on Aval {
      id
      personType
      fullName
      firstName
      lastName
      phone
      mail
      rfc
      socialReason
      legalRepresentativeFirstName
      legalRepresentativeLastName
      legalRepresentativeEmail
      legalRepresentativePhone
    }
  `
}

export const TenantFragments = {
  fields: gql`
    fragment tenantFields on Tenant {
      id
      fullName
      userName
      legalRepresentativeFirstName
      legalRepresentativeLastName
      legalRepresentativeEmail
      legalRepresentativePhone
      legalRepresentativeRfc
      firstName
      lastName
      canonicName
      personType
      socialReason
      phone
      mail
      rfc
      writingNumber
      publicNotary
      notaryCity
      notaryName
      registryData
      legalRepresentative
      writingDate
      registrationDate
      hasAval
      renterId {
        ...fileLinkFields
      }
      renterResearch {
        ...fileLinkFields
      }
      avalIdFile {
        ...fileLinkFields
      }
      avalResearch {
        ...fileLinkFields
      }
      avalProperty {
        ...fileLinkFields
      }
      request {
        ...fileLinkFields
      }
      privacy {
        ...fileLinkFields
      }
      constitutiveWriting {
        ...fileLinkFields
      }
      fiscalNumber {
        ...fileLinkFields
      }
      power {
        ...fileLinkFields
      }
      others {
        ...fileLinkFields
      }
    }
    ${FileLinkFragments.fields}
  `
}


export const PropertyFragments = {
  fields: gql`
    fragment propertyFields on Property {
      id
      createdAt
      propertyUse
      createdBy {
        id
        fullName
      }
      user {
        id
        fullName
      }
      tenant {
        ...tenantFields
        aval {
          ...avalFields
        }
      }
      address {
        ...addressFields
      }
      lastPolicy {
        ...policyFields
        lastInvoice {
          id
        }
      }
      lastContract {
        ...contractFields
      }
    }
    ${FileLinkFragments.fields}
    ${AddressFragments.fields}
    ${TenantFragments.fields}
    ${PolicyFragments.fields}
    ${ContractFragments.fields}
    ${AvalFragments.fields}
  `
}

export const InvoiceFragments = {
  fields: gql`
    fragment invoiceFields on Invoice {
      id
      user {
        id
        fullName
      }
      property{
        ...propertyFields
      }
      tenant {
        ...tenantFields
      }
      assignedDate
      riskInterest
      subtotal
      tax
      total
      discount
      isLate
      isPayed
      createdAt
    }
    ${PropertyFragments.fields}
    ${TenantFragments.fields}
  `
};


export const PaymentFragments = {
  fields: gql`
    fragment paymentFields on Payment {
      id
      invoiceId
      paymentMethod
      cardNum
      reference
      qty
      createdAt
    }
  `
}


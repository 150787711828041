import React from 'react'

const Loader = () => {
  return (
  <div className="load-spinner">
    <div className="square square-1"></div>
    <div className="square square-2"></div>
    <div className="square square-3"></div>
    <div className="square square-4"></div>
  </div>
  )
}

export default Loader;

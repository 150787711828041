import React, {useContext, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, Grid, Card, CardHeader, CardContent, Button, CardActions } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks'
import Pdf from 'react-to-pdf'
import { useTranslation } from 'react-i18next'

import { FETCH_USER_FULL } from 'constants/queries'
import './printStyles.scss'

import { 
  TermsAndConditions,
  WelcomeLetterBasic,
  WelcomeLetterEssential,
  WelcomeLetterPremium,
  WelcomeLetterWide,
  TermsDocument,
  ClaimLetter,
} from 'components'
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    padding: 0
  }
}));

const DocumentMap = {
  'essential': WelcomeLetterEssential,
  'basic': WelcomeLetterBasic,
  'ample': WelcomeLetterWide,
  'premium': WelcomeLetterPremium,
  'tac': TermsAndConditions,
  'td': TermsDocument,
  'claim': ClaimLetter,
}

const DocumentNames = {
  'essential': 'Carta de Bienvenida Navio',
  'basic': 'Carta de Bienvenida Navio',
  'ample': 'Carta de Bienvenida Navio',
  'premium': 'Carta de Bienvenida Navio',
  'tac': 'Carátula Términos y Condiciones Navio',
  'td': 'Términos y Condiciones Navio',
  'claim': 'Carta Reclamación Navio'
}

const ref = React.createRef();
const options = {
  orientation: 'p',
  unit: 'in',
  format: [16.54, 23.39]
}

const DocumentView = props => {
  const history = useHistory()
  const { t } = useTranslation()
  const { docId, userId, propertyId } = useParams()
  const [useOld, setUseOld] = useState(false)
  const { data, loading } = useQuery(FETCH_USER_FULL, {
    variables: { query: { id: userId }, propertyQuery: {id: propertyId} }
  })
  const classes = useStyles()
    useEffect(() => {
      window.document.title = DocumentNames[docId]
    }, [])
  if(data) {
    const client = data.users[0]
    const property = client.properties[0]
    if(useOld)
      return (
        <div className={classes.root}>
          <Button variant='contained' color='primary' onClick={() => setUseOld(false)}>Version</Button>
          <Pdf targetRef={ref} filename={DocumentNames[docId]}>
              {({ toPdf }) => (
                <Button variant='contained' color='primary' onClick={() => {
                  document.querySelector('.document').style.maxWidth = '800px'
                  toPdf()
                }} options={options}>{t('download')}</Button>
              )}
          </Pdf>
          <div style={{margin: '1em 0', boxShadow: '0 3px 6px #000A', width: '800px'}}>
            <div ref={ref}>
              {React.createElement(DocumentMap[docId], {client, property})}
            </div>
          </div>
        </div>
      )

    return (
      <div>
        <Button variant='contained' color='primary' onClick={() => setUseOld(true)}>Version</Button>
        {React.createElement(DocumentMap[docId], {client, property})}
      </div>
    )



  }
  else
    return <div>Loading</div>
  
};

export default DocumentView;

import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Divider, Grid, Card, CardHeader, CardContent, Button, CardActions } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import typography from 'theme/typography';


const useStyles = makeStyles(theme => ({
  root: {
    padding: '4em',
  },
  content: {
    padding: 0
  },
  heading: {
    textTransform: 'uppercase'
  },
  highlight: {
    color: theme.palette.navio.red
  },
  swoosh: {
    backgroundImage: 'url(/images/navio-swoosh.png)'
  },
  paragraph: {
    margin: '1em 0'
  },
  leftCol: {
    paddingRight: '3em !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }
}));

const Items = [
  'Asistencia legal durante la vigencia de la cobertura',
  'Investigación judicial del inquilino',
  'Creación de Contrato de Arrendamiento',
  'Intervención extrajudical por incumplimiento del Contrato de Arrendamiento'
]

const TermsDocument = props => {
  const classes = useStyles()

  const { t } = useTranslation()

  const Item =({children}) => (
  <div style={{display: 'flex', alignItems: 'center', margin: '1em 0'}}>
      <img src={process.env.PUBLIC_URL + '/images/navio-swoosh.png'} style={{width: '4em'}}/>
      <Typography variant='subtitle1'>{children}</Typography>
    </div>
  )

  return (
    <div className={clsx(classes.root, 'document')}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <img src={process.env.PUBLIC_URL + '/images/navioLogo.png'} style={{width: '2em'}}/>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Typography style={{margin: '1em 0 2em 0', textAlign: 'center', fontWeight: 'bold'}} >TÉRMINOS Y CONDICIONES DE LA PRESTACIÓN DE SERVICIOS JURÍDICOS PROFESIONALES EN MATERIA DE ARRENDAMIENTO INMOBILIARIO.</Typography>
          </div>
          <div>
            <Typography style={{textAlign: 'center', fontWeight: 'bold'}} >CONDICIONES GENERALES.</Typography>
          </div>
            <Typography>
                <ol>
                    <li style={{margin: '1em 0 2em 0'}}>
                        Para los efectos de los presentes TÉRMINOS se entenderá como:
                        <div style={{margin: '0 0 0 3em'}}>
                            <ol type="A">
                                <li>ANTICIPO: Primer pago (puede variar entre el 25% y el 50% del valor de la COBERTURA contratada) que da inicio al proceso de INVESTIGACIÓN.</li>
                                <li>AGENTE NAVÍO: Cualquier persona física que, en representación de NAVÍO, se encargue de conseguir prospectos interesados en contratar cualquiera de los servicios aquí establecidos, así como de brindar servicio y atención al CLIENTE.</li>
                                <li>ALIANZAS NAVÍO: Personas físicas o morales con las cuales NAVÍO ha contraído una relación comercial con el objeto de ofrecer un servicio más completo al CLIENTE, mismos que de forma enunciativa, más no limitativa, incluyen inmobiliarias, agencias de seguros, despachos legales, abogados independientes, relaciones estratégicas y /o cualquier otra persona física o moral que NAVÍO considere necesaria para el cumplimiento de sus obligaciones.</li>
                                <li>ARRENDATARIO: Persona física o moral con la obligación de pagar mensualmente el precio de la RENTA al CLIENTE convenido por el uso y goce temporal del INMUEBLE, así como el pago de los SERVICIOS y/o cualquiera otra condición u obligación contraída en el CONTRATO DE ARRENDAMIENTO con el CLIENTE.</li>
                                <li>ARRENDATARIO PROSPECTO: Cualquier persona física o moral interesada en convertirse en ARRENDATARIO del INMUEBLE.</li>
                                <li>CARÁTULA: Es la carátula de los presentes términos que contiene la COBERTURA contraída entre el CLIENTE y NAVÍO, así como otros datos generales.</li>
                                <li>CARTA DE RECLAMACIÓN: Documento proporcionado por NAVÍO para que el CLIENTE solicite la intervención de NAVÍO en caso de CONTINGENCIA.</li>
                                <li>CLIENTE: Persona física o moral a la que se le ofrece el presente servicio jurídico el cual es propietario del INMUEBLE y que estará identificado con su nombre, entre otros datos, en la CARÁTULA de los presentes TÉRMINOS.</li>
                                <li>COBERTURA: Una de las cuatro modalidades que ampara el servicio que NAVÍO ofrece al CLIENTE pudiendo ser Esencial, Básica, Amplia o Premium, misma que es convenida y expuesta en la CARÁTULA.</li>
                                <li>
                                    CONTINGENCIA: El incumplimiento de las obligaciones del ARRENDATARIO que de manera enunciativa, más no limitativa, incluye cualquiera de los siguientes supuestos:
                                    <div style={{margin: '0 0 0 3em'}}>
                                        <ol type="a">
                                            <li>La omisión en el pago total de por lo menos dos meses de RENTA (suceso amparado por la cobertura Esencial, Básica, Amplia y/o Premium);</li>
                                            <li>La falta de desocupación del INMUEBLE por parte del ARRENDATARIO al vencimiento del CONTRATO DE ARRENDAMIENTO (suceso amparado por la cobertura Amplia y Premium); y/o</li>
                                            <li>La falta de pago de los SERVICIOS, RENTA(S) e INTERESES establecidos en el contrato (suceso amparado por la cobertura Premium).</li>
                                        </ol>
                                    </div>
                                </li>
                                <li>CONTRATO DE ARRENDAMIENTO: El contrato de arrendamiento inmobiliario suscrito entre el CLIENTE y el ARRENDATARIO.</li>
                                <li>FIADOR: Persona física o moral que se obliga solidariamente con el ARRENDATARIO frente al CLIENTE, entre otras cosas, al pago de SERVICIOS, RENTA(S) e INTERESES.</li>
                                <li>HERRAMIENTA ADMINISTRATIVA: Herramienta en línea dentro de la página web de Navío (www.navio.mx) por medio de la cual el CLIENTE podrá mantener una administración respecto a sus arrendamientos (Aplicable para las coberturas Básica, Amplia y Premium).</li>
                                <li>INMUEBLE: Bien inmueble propiedad del CLIENTE, tomado en arrendamiento por el ARRENDATARIO para su uso y goce temporal de acuerdo a lo estipulado en el CONTRATO DE ARRENDAMIENTO.</li>
                                <li>INTERESES: Exclusivamente los intereses moratorios que se apliquen al pago impuntual de RENTA(S).</li>
                                <li>INTERÉS POR RIESGO: Monto equivalente al 25% (veinticinco por ciento) del costo de la COBERTURA contratada por consecuencia del riesgo que implicaría para NAVÍO, la decisión del CLIENTE de contratar, para el CONTRATO DE ARRENDAMIENTO, a un ARRENDATARIO PROSPECTO con un mal resultado de la INVESTIGACIÓN.</li>
                                <li>INVESTIGACIÓN: Cualquiera de las investigaciones que NAVÍO le realiza al ARRENDATARIO PROSPECTO o a un posible FIADOR incluyendo, sin limitar, la investigación en Buró de Crédito, la investigación judicial y/o la investigación en Registro Público de la Propiedad.</li>
                                <li>NAVÍO: La Sociedad “JURÍDICO PATRIMONIAL ASI, S.A. DE C.V.”, encargada de prestar el servicio estipulado en los presentes TÉRMINOS.</li>
                                <li>PAGO TOTAL: Es el pago por parte del CLIENTE del precio total de la COBERTURA contratada (incluye el ANTICIPO).</li>
                                <li>PÓLIZA: Número de folio de identificación del CLIENTE establecido en la CARÁTULA.</li>
                                <li>RENTA(S): Pago mensual convenido entre el CLIENTE y el ARRENDATARIO en el CONTRATO DE ARRENDAMIENTO.</li>
                                <li>SERVICIOS: De manera enunciativa, más no limitativa, las cuotas de mantenimiento a ser pagadas por el ARRENDATARIO en relación al INMUEBLE, tales como luz, gas, agua y telefonía.</li>
                                <li>S.M.: Días de salario mínimo vigente en los Estados Unidos Mexicanos en la fecha de inicio de la VIGENCIA de la COBERTURA.</li>
                                <li>SOLICITUD: Es el documento que el CLIENTE llenará y firmará para solicitar cualquiera de los servicios de NAVÍO.</li>
                                <li>TÉRMINOS: Los términos y condiciones de la prestación de servicios jurídicos profesionales en materia de arrendamiento inmobiliario, que se estipulan en el presente documento.</li>
                                <li>VIGENCIA: Es la duración de la protección que NAVÍO le ofrece al CLIENTE y que iniciará y terminará según las fechas establecidas en la CARÁTULA.</li>
                            </ol>
                        </div>
                    </li>
                    <li style={{margin: '1em 0 2em 0'}}>Los servicios jurídicos contratados por el CLIENTE serán sólo los incluidos en la COBERTURA contratada, por lo que solo una de las cuatro coberturas expuestas en los incisos “a.”, “b”,“c” o “d” de la Cláusula Primera aplicaría.</li>
                    <li style={{margin: '1em 0 2em 0'}}>
                        Los servicios jurídicos contratados por el CLIENTE se llevarán a cabo únicamente en materia civil, dentro de los Estados Unidos Mexicanos y forzosamente después de cumplidos los siguientes supuestos:
                        <div style={{margin: '0 0 0 3em'}}>
                            <ol>
                                <li>Cuando se haya efectuado la totalidad del pago convenido entre NAVÍO y el CLIENTE;</li>
                                <li>Que el CONTRATO DE ARRENDAMIENTO esté ratificado por fedatario público;</li>
                                <li>Cuando se presente alguno de los casos de CONTINGENCIA; y</li>
                                <li>Se haya entregado la CARTA DE RECLAMACIÓN a su AGENTE NAVÍO con todos los requisitos que en ella se enumeran.</li>
                            </ol>
                        </div>
                    </li>
                    <li style={{margin: '1em 0 2em 0'}}>NAVÍO, sus empleados, directivos y/o cualquier persona que represente a NAVÍO, por ningún motivo recibirá pagos en efectivo. Todos los pagos por concepto de contraprestación que NAVÍO le ofrece a sus clientes por los servicios amparados serán pagados a las cuentas que NAVÍO designe para tales efectos.</li>
                </ol>
            </Typography>
            <div>
                <Typography style={{margin: '5em 0 1em 0', textAlign: 'center', fontWeight: 'bold'}} >CLÁUSULAS</Typography>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Typography style={{margin: '2em 0 0 0', fontWeight: 'bold'}} >PRIMERA.- OBLIGACIONES DE NAVÍO</Typography>
            </div>
            <Typography>
                <ol type="I">
                    <li>
                        Una vez que el CLIENTE haga llegar a NAVÍO, por medio de cualquiera de los AGENTES NAVÍO, la SOLICITUD debidamente llenada y firmada, y realice el pago del
                        ANTICIPO correspondiente a la COBERTURA deseada (NAVÍO se compromete a respetar el pago del ANTICIPO por un plazo máximo de hasta 12 meses), NAVÍO queda 
                        obligado a iniciar el proceso de INVESTIGACIÓN. Posteriormente y una vez realizada la INVESTIGACIÓN y se hayan encontrado resultados favorables, a discreción de
                        NAVÍO, se dará el visto bueno del ARRENDATARIO PROSPECTO y NAVÍO procederá a elaborar el correspondiente CONTRATO DE ARRENDAMIENTO. Una vez firmado
                        el CONTRATO DE ARRENDAMIENTO entre el CLIENTE y el ARRENDATARIO, la VIGENCIA de la COBERTURA iniciará y quedará vigente por el plazo establecido en la
                        CARÁTULA. En caso de rescisión o terminación anticipada del CONTRATO DE ARRENDAMIENTO, la VIGENCIA de la COBERTURA quedará rescindida.
                    </li>
                    <li>
                        Una vez que el CLIENTE dé aviso por escrito a NAVÍO de la existencia de un caso de CONTINGENCIA, NAVÍO intervendrá en primera instancia por la vía extrajudicial o
                        la establecida en el pacto comisorio del CONTRATO DE ARRENDAMIENTO, con el propósito de mediar un acuerdo entre el CLIENTE y el ARRENDATARIO, o en su caso
                        requerir al ARRENDATARIO por escrito el pago de las RENTA(S) vencidas y/o la desocupación y entrega legal del INMUEBLE.
                    </li>
                    <li>
                        Queda a total y única discreción de NAVÍO la decisión de qué acciones extrajudiciales o judiciales se llevarán a cabo, así como los gastos que NAVÍO podría llegar a
                        efectuar dependiendo del caso concreto y del momento en que una decisión sea efectuada y/o de los convenios pactados con los ALIANZAS NAVÍO, en su caso.
                    </li>
                    <li>
                        Las obligaciones de NAVÍO con el CLIENTE dependen de lo establecido en la COBERTURA contratada y expuesta en la CARÁTULA. Dicha COBERTURA podrá contener
                        solo una de las cuatro distintas modalidades que de manera limitativa amparan lo siguiente:
                        <div style={{margin: '0 0 0 3em'}}>
                            <ol type="a">
                                <li style={{margin: '1em 0 2em 0'}}>
                                    COBERTURA ESENCIAL
                                    <div style={{margin: '0 0 0 3em'}}>
                                        <ol type="i">
                                            <li>
                                                NAVÍO llevará a cabo una investigación judicial del posible ARRENDATARIO PROSPECTO en los diferentes Juzgados del Estado de Nuevo León con el fin de
                                                conocer si existe algún motivo de impedimento, por cuestiones de riesgo, para que éste celebre el CONTRATO DE ARRENDAMIENTO con el CLIENTE;
                                            </li>
                                            <li>
                                                NAVÍO redactará el CONTRATO DE ARRENDAMIENTO de acuerdo a las necesidades del CLIENTE y lo que NAVÍO, de conformidad con sus mejores
                                                conocimientos, considere pertinente;
                                            </li>
                                            <li>
                                                Asistencia legal sobre cuestiones legales de su arrendamiento durante la VIGENCIA, es decir, NAVÍO apoyará al cliente resolviendo dudas técnicas legales
                                                sobre su específico arrendamiento y le hará recomendaciones sobre el cómo actuar en ciertos casos sin que NAVÍO esté obligado a llevar a cabo acciones
                                                distintas a las establecidas en el inciso “iv” siguiente; y
                                            </li>
                                            <li>
                                                En caso de contingencia:
                                                <div style={{margin: '0 0 0 3em'}}>
                                                    <ol>
                                                        <li>
                                                            NAVÍO promoverá las acciones extrajudiciales que crea convenientes, con el único propósito de gestionar para el CLIENTE la recuperación del
                                                            INMUEBLE cuando el ARRENDATARIO haya incumplido con el pago completo de por lo menos dos meses de RENTA. Por ningún motivo
                                                            NAVÍO tendrá obligación de realizar cualquier trámite judicial en nombre y representación del CLIENTE.
                                                        </li>
                                                    </ol>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                                <li style={{margin: '1em 0 2em 0'}}>
                                    COBERTURA BÁSICA
                                    <div style={{margin: '0 0 0 3em'}}>
                                        <ol type="i">
                                            <li>
                                                NAVÍO se obliga de manera textual a todo lo expuesto en el inciso “a. Cobertura Esencial” de esta Cláusula Primera, exceptuando los numerales romanos
                                                “i” y “iv”. Además de lo siguiente:
                                            </li>
                                            <li>
                                                NAVÍO llevará a cabo una investigación judicial del ARRENDATARIO PROSPECTO con el fin de investigar si existe algún impedimento para que éste celebre
                                                el CONTRATO DE ARRENDAMIENTO con el CLIENTE. En caso de existir algún impedimento, tal como un mal historial judicial y/o crediticio, a discreción de
                                                NAVÍO, se le hará saber al CLIENTE por escrito para exhortarlo a encontrar un nuevo ARRENDATARIO PROSPECTO. En caso de que el CLIENTE, una vez
                                                entregados los resultados negativos de esta INVESTIGACIÓN, decida de igual manera continuar con la celebración del CONTRATO DE ARRENDAMIENTO
                                                con ese ARRENDATARIO PROSPECTO, el CLIENTE acuerda que NAVÍO podría solicitarle el pago del INTERÉS POR RIESGO o negarle los servicios aquí
                                                establecidos. En caso de que NAVÍO le solicite al CLIENTE pagar el INTERÉS POR RIESGO y el CLIENTE decida no pagar el INTERÉS POR RIESGO, el CLIENTE
                                                entiende que NAVÍO no podría prestarle los servicios establecidos en los presentes TÉRMINOS correspondientes a la COBERTURA contratada y acepta
                                                que NAVÍO rescinda la presente relación contractual con el CLIENTE. En este caso y por razones de costos administrativos, NAVÍO no quedará obligado
                                                por ningún motivo a devolver el ANTICIPO pagado por el CLIENTE;
                                            </li>
                                            <li>
                                                En caso de que el CLIENTE requiera terminar la relación contractual con el ARRENDATARIO, NAVÍO redactará un convenio de terminación para que de
                                                esta manera el CLIENTE recupere la posesión del bien inmueble;y
                                            </li>
                                            <li>
                                                Proporcionar al CLIENTE acceso a la HERRAMIENTA ADMINISTRATIVA y soporte técnico respecto a esta; y
                                            </li>
                                            <li>
                                                En caso de contingencia:
                                                <div style={{margin: '0 0 0 3em'}}>
                                                    <ol>
                                                        <li>
                                                            NAVÍO promoverá las acciones extrajudiciales que crea convenientes con el propósito de mediar y/o negociar en nombre del CLIENTE para
                                                            recuperar el INMUEBLE cuando el ARRENDATARIO haya incumplido con el pago completo de por lo menos dos meses de RENTA.
                                                        </li>
                                                        <li>
                                                            NAVÍO promoverá ante el Árbitro y/o tribunales competentes, o de cualquier manera legal que crea conveniente, lo que legalmente proceda
                                                            con el único propósito de recuperar el INMUEBLE. Lo anterior cuando el ARRENDATARIO haya incumplido con el pago completo de por lo
                                                            menos dos meses de RENTA.
                                                        </li>
                                                        <li>
                                                            NAVÍO se compromete a cubrir con el costo de la cobertura básica el total de honorarios por concepto de abogados y gastos legales de los
                                                            procedimientos judiciales, por una suma de hasta $75,000 pesos (setenta y cinco mil Pesos 00/100 M.N.). La disposición de la suma anterior
                                                            será única y exclusivamente para los honorarios devengados por la desocupación del INMUEBLE. Se hace constar por este medio que dichos
                                                            gastos legales incluyen los gastos de lanzamiento del juicio de desahucio.
                                                        </li>
                                                    </ol>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                                <li style={{margin: '1em 0 2em 0'}}>
                                    COBERTURA AMPLIA
                                    <div style={{margin: '0 0 0 3em'}}>
                                        <ol type="i">
                                            <li>
                                                NAVÍO se obliga de manera textual a todo lo expuesto en el inciso “b. Cobertura Básica” de esta Cláusula Primera, exceptuando el numeral romano “v”.
                                                Además de lo siguiente:
                                            </li>
                                            <li>
                                                A cubrir los gastos originados por la ratificación del CONTRATO DE ARRENDAMIENTO ante un Fedatario Público, el cual será elegido a discreción de
                                                NAVÍO. En caso de que el CLIENTE decida acudir ante un Fedatario Público distinto del propuesto por NAVÍO, este último quedará exento de cumplir con
                                                dicha obligación
                                            </li>
                                            <li>
                                                En caso de contingencia:
                                                <div style={{margin: '0 0 0 3em'}}>
                                                    <ol>
                                                        <li>
                                                            NAVÍO promoverá las acciones extrajudiciales que crea convenientes, con el propósito de mediar y/o negociar en nombre del CLIENTE para
                                                            recuperar el INMUEBLE cuando el ARRENDATARIO haya incumplido con el pago completo de por lo menos dos meses de RENTA.
                                                        </li>
                                                        <li>
                                                            NAVÍO promoverá ante el Árbitro y/o tribunales competentes, o de cualquier manera legal que crea conveniente, lo que legalmente proceda
                                                            con el único propósito de recuperar el INMUEBLE. Lo anterior cuando el ARRENDATARIO haya incumplido con el pago completo de por lo
                                                            menos dos meses de RENTA.
                                                        </li>
                                                        <li>
                                                            NAVÍO se compromete a cubrir con el costo de la cobertura amplia el total de honorarios por concepto de abogados y gastos legales de los
                                                            procedimientos judiciales por una suma de hasta $100,000 pesos (CIEN MIL PESOS 00/100 M.N.). La disposición de la suma anterior será
                                                            única y exclusivamente para los honorarios devengados por la desocupación del INMUEBLE. Se hace constar por este medio que dichos
                                                            gastos legales incluyen los gastos de lanzamiento del juicio de desahucio.
                                                        </li>
                                                    </ol>
                                                </div>
                                            </li>
                                            <li>
                                                NAVÍO se obliga a cubrir al CLIENTE con el presente servicio por la VIGENCIA establecida en la CARÁTULA, misma que por lo general comprenderá 365
                                                (trescientos sesenta y cinco) días. Adicional a los 365 (trescientos sesenta y cinco) días mencionados anteriormente, contará con una protección adicional
                                                de hasta 30 (treinta) días después de terminada la VIGENCIA del establecida en la CARÁTULA.
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                                <li style={{margin: '1em 0 2em 0'}}>
                                    COBERTURA PREMIUM
                                    <div style={{margin: '0 0 0 3em'}}>
                                        <ol type="i">
                                            <li>
                                                NAVÍO se obliga de manera textual a todo lo expuesto en el inciso “c. Cobertura Amplia” de esta Cláusula Primera exceptuando el numeral romano “iii”.
                                                Además de lo siguiente:
                                            </li>
                                            <li>
                                                NAVÍO llevará a cabo una investigación del posible FIADOR en el Registro Público de la Propiedad del Estado de Nuevo León con el fin de investigar si este
                                                tiene la suficiente solvencia económica para garantizar las obligaciones del ARRENDATARIO. En caso del posible FIADOR no cuente con suficiente
                                                solvencia económica para garantizar las obligaciones del ARRENDATARIO, NAVÍO exhortará al CLIENTE a que consiga un nuevo posible FIADOR que pueda
                                                garantizar las obligaciones del ARRENDATARIO. En caso de que el CLIENTE, una vez entregados los resultados negativos de esta INVESTIGACIÓN, decida
                                                de igual manera continuar con la celebración del CONTRATO DE ARRENDAMIENTO con ese ARRENDATARIO PROSPECTO sin contar con un FIADOR que
                                                pueda garantizar las obligaciones del ARRENDATARIO, el CLIENTE acuerda que NAVÍO no podrá protegerlo bajo esta cobertura; sin embargo, NAVÍO
                                                exhortará al CLIENTE de contratar alguna de las coberturas Esencial, Básica o Amplia en virtud de que estas tres coberturas mencionadas no tienen como
                                                requisito contar con un FIADOR. Por razones de costos administrativos, NAVÍO no quedará obligado por ningún motivo a devolver el ANTICIPO pagado
                                                por el CLIENTE;
                                            </li>
                                            <li>
                                                En caso de contingencia:
                                                <div style={{margin: '0 0 0 3em'}}>
                                                    <ol>
                                                        <li>
                                                          NAVÍO promoverá las acciones extrajudiciales que crea convenientes, con el propósito de mediar y/o negociar en nombre del CLIENTE para
                                                          recuperar el INMUEBLE cuando el ARRENDATARIO haya incumplido con el pago completo de por lo menos dos meses de RENTA.
                                                        </li>
                                                        <li>
                                                            NAVÍO promoverá ante el Árbitro y/o tribunales competentes, o de cualquier manera legal que crea conveniente, lo que legalmente proceda
                                                            con el propósito de:
                                                            <div style={{margin: '0 0 0 3em'}}>
                                                                <ol type="a">
                                                                    <li>
                                                                        Recuperar legalmente el INMUEBLE. Lo anterior cuando el ARRENDATARIO haya incumplido con el pago completo de por lo
                                                                        menos dos meses de RENTA.
                                                                    </li>
                                                                    <li>
                                                                        Recuperar el monto total de lo adeudado por el ARRENDATARIO por concepto de RENTAS, SERVICIOS, e INTERESES generados
                                                                        dentro del periodo de VIGENCIA de la CARÁTULA, y/o en su caso por daños al INMUEBLE dentro del periodo de VIGENCIA de la
                                                                        CARÁTULA. Esto cuando el ARRENDATARIO se niegue a desocupar el INMUEBLE al vencimiento del CONTRATO DE
                                                                        ARRENDAMIENTO, y/o cuando haya omitido el pago completo de al menos dos meses de RENTAS más SERVICIOS e INTERESES,
                                                                        sin importar la suma de estos, o de solo los SERVICIOS e INTERESES cuya suma sea igual o mayor al importe de 300 (Trescientos)
                                                                        S.M. más un mes de RENTA si es que lo hubiere.
                                                                    </li>
                                                                </ol>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            NAVÍO se compromete a cubrir con el costo de la cobertura Premium el total de honorarios por concepto de abogados y gastos legales de los
                                                            procedimientos judiciales, por una suma de hasta $125,000 pesos (CIENTO VEINTICINCO MIL PESOS 00/100 M.N.). La disposición de la suma
                                                            anterior será para los honorarios devengados por la desocupación del INMUEBLE, la cobranza extrajudicial y judicial y las consultas legales
                                                            del CLIENTE que tenga respecto a dicho INMUEBLE. Se hace constar por este medio que dichos gastos legales incluyen los gastos de
                                                            lanzamiento del juicio de desahucio, gastos por concepto de cobranza judicial y los gastos del procedimiento para llevar a cabo la cobranza
                                                            por daños al INMUEBLE.
                                                        </li>
                                                        <li>
                                                            NAVÍO iniciará y dará seguimiento únicamente al proceso arbitral y/o judicial que este haya instaurado en contra del ARRENDATARIO y/o
                                                            FIADOR, ofreciendo pruebas e interviniendo en el desahogo de las mismas, así como la contestación a la vista de la demanda y a la
                                                            reconvención en su caso, en los diferentes incidentes y en la tramitación y/o contestación de agravios por apelaciones, en la interposición de
                                                            alegatos tanto en primera como en segunda instancia y en general en todas y cada una de las diligencias o audiencias en las que se deba
                                                            promover y/o actuar, incluyendo los trámites de los amparos directos o indirectos que pudieran derivarse del procedimiento instruido y la
                                                            relación de los convenios o arreglos judiciales o extrajudiciales con el ARRENDATARIO y/o con su FIADOR. También promoverá, si fuera
                                                            necesario y legalmente posible, el embargo de bienes materiales propiedad del ARRENDATARIO o del FIADOR.
                                                        </li>
                                                        <li>
                                                            A cargo del CLIENTE quedará el trámite de las fianzas que exija un Juez, para garantizar la reparación de los daños y perjuicios que se le
                                                            pudieran ocasionar al ARRENDATARIO por el lanzamiento del que fuera objeto al ARRENDATARIO o FIADOR por el embargo de bienes
                                                            materiales de su propiedad, siendo responsabilidad del CLIENTE el aportar los documentos y las garantías que la afianzadora requiera y en su
                                                            caso el pago en exceso de 40 (cuarenta) S.M. por el traslado de bienes embargados, para llevarlo al lugar designado por dicho CLIENTE.
                                                        </li>
                                                        <li>
                                                            En el supuesto de que el Juez haga exigible el pago de la reparación de dichos daños y perjuicios, NAVÍO quedará exento de esta obligación.
                                                        </li>
                                                    </ol>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </li>
                </ol>
            </Typography>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Typography style={{margin: '2em 0 0 0', fontWeight: 'bold'}} >SEGUNDA.- OBLIGACIONES DEL CLIENTE</Typography>
            </div>
            <Typography>
                <ol>
                    <li>
                        El CLIENTE se compromete a entregar NAVÍO los siguientes documentos y requerimientos, conforme a las siguientes especificaciones como condición para que
                        NAVÍO inicie los servicios legales amparados conforme a la COBERTURA contratada:
                        <div style={{margin: '0 0 0 3em'}}>
                            <ol type="a">
                                <li>
                                    Entregar la CARTA DE RECLAMACIÓN junto con toda la información, documentos y requisitos establecidos en ella. NAVÍO se compromete a responder la
                                    CARTA DE RECLAMACIÓN en un término no mayor a 48 horas después de recibida;
                                </li>
                                <li>
                                    Entregar el original del CONTRATO DE ARRENDAMIENTO con las firmas ratificadas ante notario público o copia certificada del mismo;
                                </li>
                                <li>
                                    Entregar los recibos de pago debidamente firmados por el ARRENDATARIO;
                                </li>
                                <li>
                                    A firmar todos los documentos relacionados con el juicio, tales como demandas, promociones, apelaciones, nombramientos, oficios, etc. y su
                                    conformidad de comparecer personalmente, o a través de un representante legal (con poder notariado), en cualquier lugar, fecha y hora y cuantas veces
                                    sea necesario, a todas las diligencias para las que sea requerido, ante la autoridad correspondiente y/o ante el departamento jurídico de NAVÍO;
                                </li>
                                <li>
                                    A otorgar un Poder Notarial a favor de las personas que NAVÍO designe o, en su defecto, su conformidad por escrito en autos para dar intervención a
                                    dichas personas, para que en su nombre y representación le den seguimiento al proceso y en el momento oportuno lo concluyan;
                                </li>
                                <li>
                                    A mostrar su conformidad por escrito de ceder y/o subrogar en cualquier momento, y sin limitación alguna, a NAVÍO los derechos concernientes a la
                                    condenación y al cobro de gastos y costas de dichos procedimientos; y
                                </li>
                                <li>
                                    A entregar cualquier otro documento y/o información que NAVÍO considere pertinente para el mejor desempeño de sus habilidades en la persecución de
                                    la protección del CLIENTE y su INMUEBLE
                                </li>
                            </ol>
                        </div>
                        La falta de alguno de los documentos y requerimientos anteriormente mencionados o la omisión o error de datos en cualquiera de ellos o la inasistencia o
                        incumplimiento a las diligencias o audiencias, por parte del CLIENTE, por cualquier causa, originará que NAVÍO quede relevado de su responsabilidad de cumplir con
                        sus obligaciones pactadas en los presentes TÉRMINOS, por ser impedimento legal para interponer una demanda, seguir un procedimiento arbitral o judicial o lograr
                        un laudo o sentencia favorable y/o la ejecución de cualquiera de éstos. Sin embargo, en el supuesto de que se logrará subsanar el asunto, el CLIENTE deberá cubrir
                        los gastos de dichos actos.
                    </li>
                    <li>
                        El CLIENTE será responsable del pago de todas las diligencias que no se concluyan exitosamente por causas imputables a él.
                    </li>
                    <li>
                        NAVÍO designará el Árbitro que considere conveniente para la resolución de cualquier controversia. En caso de que el CLIENTE decida nombrar a un Árbitro distinto
                        del designado por NAVÍO, este último se limitará a pagar sus honorarios y los de su secretario, hasta una cantidad de 100 (cien) S.M. para ambos.
                    </li>
                    <li>
                        NAVÍO tendrá libertad plena para sustituir al abogado que tenga a su cargo en el asunto judicial o asignar otro u otros de apoyo y el CLIENTE deberá firmar el oficio
                        de nombramiento del o de los nuevos abogados y en su caso la revocación del anterior.
                    </li>
                </ol>
            </Typography>
            <div>
                <Typography style={{margin: '2em 0 0 0', fontWeight: 'bold'}} >TERCERA.- CESANTÍA DE LAS OBLIGACIONES DE NAVÍO</Typography>
            </div>
            <Typography>
                NAVÍO cesará los servicios jurídicos comprendidos y convenidos con el CLIENTE en la CARÁTULA cuando ocurra cualquiera de los siguientes supuestos:
                <div style={{margin: '0 0 0 3em'}}>
                    <ol type="A">
                        <li>
                            Cuando el CLIENTE decida contratar servicios jurídicos de un tercero externo a NAVÍO;
                        </li>
                        <li>
                            Cuando el ARRENDATARIO o el FIADOR pongan a disposición del CLIENTE, con o sin el conocimiento de NAVÍO, el total de lo adeudado ya sea por concepto de
                            pensiones rentísticas vencidas, cuotas de mantenimiento, daños al INMUEBLE o INTERESES y/o SERVICIOS;
                        </li>
                        <li>
                            Cuando el ARRENDATARIO ponga a disposición del CLIENTE la posesión del INMUEBLE en el tiempo convenido; y/o
                        </li>
                        <li>
                            Cuando el CLIENTE incumpla en cualquiera de sus obligaciones pactadas en los presentes TÉRMINOS.
                        </li>
                    </ol>
                </div>
            </Typography>
            <div>
                <Typography style={{margin: '2em 0 0 0', fontWeight: 'bold'}} >CUARTA.- VENCIMIENTO O TERMINACIÓN DE LA COBERTURA</Typography>
            </div>
            <Typography>
                Los presentes TÉRMINOS quedarán sin efecto alguno, aún cuando la VIGENCIA establecida en la CARÁTULA no se haya cumplido en su totalidad, en los siguientes casos:
                <div style={{margin: '0 0 0 3em'}}>
                    <ol type="A">
                        <li>
                            Cuando por cualquier motivo y/o situación el INMUEBLE regrese a la posesión del CLIENTE. Lo anterior, exceptuando la cobertura Premium cuando existan adeudos
                            por concepto de rentas vencidas, daños al INMUEBLE y/o SERVICIOS e INTERESES;
                        </li>
                        <li>
                            Por terminación, cancelación, rescisión y/o vencimiento del CONTRATO DE ARRENDAMIENTO;
                        </li>
                        <li>
                            Por sentencia penal en contra del CLIENTE;
                        </li>
                        <li>
                            Por embargo o expropiación del INMUEBLE por parte de cualquier autoridad para asegurar cualquier obligación contraída por el CLIENTE; y/o
                        </li>
                        <li>
                            Por muerte del CLIENTE.
                        </li>
                    </ol>
                </div>
            </Typography>
            <div>
                <Typography style={{margin: '2em 0 0 0', fontWeight: 'bold'}} >QUINTA.- EXCLUSIONES</Typography>
            </div>
            <Typography>
                NAVÍO se eximirá de la prestación de sus servicios comprendidos dentro de los presentes TÉRMINOS, al ocurrir cualquiera de los siguientes casos:
                <div style={{margin: '0 0 0 3em'}}>
                    <ol type="A">
                        <li>
                            Cuando los bienes del ARRENDATARIO y/o FIADOR susceptibles de embargo se encuentren ubicados en alguna zona conocida por ser peligrosa, violenta o bien exista
                            algún posible riesgo que pueda poner en peligro la integridad física y/o seguridad de cualquier AGENTE NAVÍO, ALIANZAS NAVÍO y/o las personas encargadas de
                            llevar a cabo las diligencias extrajudiciales o judiciales;
                        </li>
                        <li>
                            Cuando se tenga conocimiento o sospecha de que el ARRENDATARIO lleva a cabo actividades fraudulentas o criminales formando, o no, parte del crimen organizado;
                        </li>
                        <li>
                            Cuando exista diferencia, en los datos del ARRENDATARIO y/o del FIADOR, entre la CARÁTULA y el CONTRATO DE ARRENDAMIENTO;
                        </li>
                        <li>
                            Mientras exista huelga emplazada o estallada en el INMUEBLE;
                        </li>
                        <li>
                            Mientras el INMUEBLE permanezca clausurado o asegurado a causa de alguna falta administrativa o a la comisión de algún delito;
                        </li>
                        <li>
                            Cuando el CONTRATO DE ARRENDAMIENTO original no se encuentre debidamente firmado y ratificado ante fedatario público por el CLIENTE, el ARRENDATARIO y el
                            FIADOR, en su caso, de conformidad con la VIGENCIA mostrada en la CARÁTULA;
                        </li>
                        <li>
                            Mientras exista una huelga emplazada o estallada en contra del FIADOR respecto de la finca o empresas señaladas como garantía, o mientras estas permanezcan
                            clausuradas o aseguradas a causa de alguna falta administrativa o de la comisión de algún delito. Esta exclusión sólo opera en el caso de la cobranza judicial a la
                            cobertura Premium;
                        </li>
                        <li>
                            Cuando sin consentimiento expreso de NAVÍO, se modifique o altere cualquier Cláusula del CONTRATO DE ARRENDAMIENTO original;
                        </li>
                        <li>
                            Cuando el CLIENTE no haya pagado el importe total establecido en la CARÁTULA, la cual depende de la COBERTURA contratada o de sus modificaciones;
                        </li>
                        <li>
                            Cuando el ARRENDATARIO y/o FIADOR inicien cualquier acción legal por cualquier motivo y en cualquier materia o vía en contra del CLIENTE;
                        </li>
                        <li>
                            Cuando existan datos incompletos o inexactos, dolo, mala fe, abuso o fraude por parte del CLIENTE, respecto a cualquier información proporcionada a NAVÍO;
                        </li>
                        <li>
                            Cuando sea necesario que el CLIENTE firme, entregue, o bien proporcione cierta información que sea necesaria para darle seguimiento al procedimiento judicial ya
                            sea ante Árbitro o Juez, y no lo hiciere en un plazo de 2 (dos) meses; y/o
                        </li>
                        <li>
                            Cuando iniciado el procedimiento judicial, ya sea ante Árbitro o Juez, con o sin conocimiento de NAVÍO, el CLIENTE reciba el pago de las RENTAS, INTERESES y
                            SERVICIOS.
                        </li>
                    </ol>
                </div>
            </Typography>
            <div>
                <Typography style={{margin: '2em 0 0 0', fontWeight: 'bold'}} >SEXTA.- CONVENIOS</Typography>
            </div>
            <Typography>
                Cuando después de haberse iniciado un procedimiento arbitral o un juicio en contra del ARRENDATARIO y/o del FIADOR, las partes involucradas en el CONTRATO DE
                ARRENDAMIENTO formalicen un convenio verbal o escrito, ya sea en forma privada o ante el Árbitro o Juez que conozca del asunto, los efectos derivados de la COBERTURA
                contratada y expuesta en la CARÁTULA caducarán precisamente al vencimiento de ésta.
                Si el convenio antes mencionado fuera incumplido por el ARRENDATARIO y/o FIADOR y por causa imputable al CLIENTE no se pudiera llevar a cabo la ejecución del mismo dentro
                de los siguientes 30 (treinta) días al de haberse dado el incumplimiento, NAVÍO quedará relevada de toda responsabilidad respecto a sus obligaciones establecidas en la
                COBERTURA.
            </Typography>
            <div>
                <Typography style={{margin: '2em 0 0 0',  fontWeight: 'bold'}} >SÉPTIMA.- RESULTADO DE PROCEDIMIENTOS LEGALES</Typography>
            </div>
            <Typography>
                El resultado de todos aquellos actos jurídicos descritos en los presentes TÉRMINOS que NAVÍO lleve a cabo dependen directamente de los diferentes elementos y circunstancias
                que se lleguen a suscitar y de la jurisdicción en la cual sean sometidos, por lo tanto, por ninguna situación o circunstancia NAVÍO tendrá responsabilidad alguna con el CLIENTE
                en caso de no tener éxito en cualquiera de los procesos legales que NAVÍO llevará a cabo para el CLIENTE. No obstante lo anterior, NAVÍO pondrá todo su esfuerzo en lograr un
                resultado positivo para el CLIENTE.
            </Typography>
            <div>
                <Typography style={{margin: '2em 0 0 0',  fontWeight: 'bold'}} >OCTAVA.- DAÑOS MATERIALES</Typography>
            </div>
            <Typography>
                NAVÍO quedará exento de toda responsabilidad por los daños materiales y consecuenciales que pudieran causarse al INMUEBLE y/o a sus contenidos, así como a los bienes
                propiedad del ARRENDATARIO o FIADOR, por ejecución de laudo o sentencia, aun cuando las personas encargadas de llevar a cabo las labores de carga y traslado hayan sido
                contratadas por NAVÍO
            </Typography>
            <div>
                <Typography style={{margin: '2em 0 0 0',  fontWeight: 'bold'}} >NOVENA.- LÍMITE DE RESPONSABILIDAD</Typography>
            </div>
            <Typography>
                El límite máximo de responsabilidad de NAVÍO para los servicios jurídicos materia de los presentes TÉRMINOS, depende de la COBERTURA contratada, la cual estará establecida
                en la CARÁTULA.
            </Typography>
            <div>
                <Typography style={{margin: '2em 0 0 0', fontWeight: 'bold'}} >DÉCIMA.- TERMINACIÓN ANTICIPADA DE LA COBERTURA</Typography>
            </div>
            <Typography>
                Las partes convienen expresamente en que la COBERTURA es personal e intransferible y solo podrá darse por terminada anticipadamente a causa de los supuestos señalados en
                la Cláusula Cuarta que antecede. También podrá ser cancelada por falta de pago conforme a lo descrito en el inciso “I” de la Cláusula Quinta que antecede, o a solicitud escrita
                del CLIENTE dentro de los primeros 30 (treinta) días al de haber iniciado su VIGENCIA, excepto cuando NAVÍO ya haya prestado algún servicio a cargo de la misma
            </Typography>
            <div>
                <Typography style={{margin: '2em 0 0 0', fontWeight: 'bold'}} >DÉCIMA PRIMERA.- PLAZOS PARA REPORTAR UNA CONTINGENCIA POR PARTE DEL CLIENTE</Typography>
            </div>
            <Typography>
                Para tener derecho a los servicios amparados por los presentes TÉRMINOS, el CLIENTE deberá presentar su CARTA DE RECLAMACIÓN por escrito a NAVÍO y permitir su
                intervención jurídica dentro de los siguientes plazos:
                <div style={{margin: '0 0 0 3em'}}>
                    <ol type="A">
                        <li>
                            Cuando la reclamación sea originada por el incumplimiento en el pago de las RENTAS: 15 (quince) días hábiles posteriores al de haberse vencido y no pagado la
                            RENTA de dos meses como máximo; y
                        </li>
                        <li>
                            Cuando la reclamación se origine por la negativa del ARRENDATARIO a desocupar el INMUEBLE una vez vencido el CONTRATO: 30 (treinta) días naturales contados a
                            partir de la fecha del vencimiento de dicho CONTRATO DE ARRENDAMIENTO. Esto siempre que en la CARÁTULA aparezca como amparada la cobertura Amplia o
                            Premium.
                        </li>
                    </ol>
                </div>
            </Typography>
            <div>
                <Typography style={{margin: '2em 0 0 0', fontWeight: 'bold'}} >DÉCIMA SEGUNDA.- ACEPTACIÓN DE LA COBERTURA</Typography>
            </div>
            <Typography>
                Si el contenido de la COBERTURA o sus modificaciones no concordaren con la oferta, el CLIENTE podrá pedir la rectificación correspondiente dentro de los 15 (quince) días
                naturales que sigan al día en que inicie su VIGENCIA. Transcurrido este plazo se considerarán aceptadas las estipulaciones de los presentes TÉRMINOS o de sus modificaciones.
            </Typography>
            <div>
                <Typography style={{margin: '2em 0 0 0', fontWeight: 'bold'}} >DÉCIMA TERCERA.-RECONTRATACIÓN</Typography>
            </div>
            <Typography>
                El CLIENTE gozará de los siguientes descuentos por concepto de renovación de su COBERTURA con NAVÍO:
                <div style={{margin: '0 0 0 3em'}}>
                    <ol type="A">
                        <li>
                            Un descuento del 20% (Veinte por ciento) sobre el PRECIO TOTAL por el primer año en el que renueve su COBERTURA;
                        </li>
                        <li>
                            Un descuento del 40% (Cuarenta por ciento) sobre el PRECIO TOTAL por el segundo año en el que renueve su COBERTURA;
                        </li>
                        <li>
                            Un descuento del 60% (Sesenta por ciento) sobre el PRECIO TOTAL por el tercer año en el que renueve su COBERTURA;
                        </li>
                        <li>
                            Un descuento del 100% (Cien por ciento) sobre el PRECIO TOTAL por el cuarto año en el que renueve su COBERTURA, por lo que el CLIENTE no tendría que pagar
                            monto alguno por esta recontratación.
                        </li>
                    </ol>
                </div>
                Del quinto año en adelante, el CLIENTE acuerda que NAVÍO deberá de generar una nueva cotización que el CLIENTE deberá de pagar para seguir contando con los beneficios
                plasmados en estos TÉRMINOS.
            </Typography>
            <Typography style={{margin: '1em 0 0 0'}}>
                Por medio de los presentes TÉRMINOS, el CLIENTE acuerda que para efectos de gozar de los beneficios antes mencionados, este deberá de cumplir con los siguientes
                requerimientos:
                <div style={{margin: '0 0 0 3em'}}>
                    <ol>
                        <li>
                            Haber avisado a NAVÍO de cualquier posible riesgo de CONTINGENCIA con el ARRENDATARIO dentro de los plazos señalados en los presentes TÉRMINOS;
                        </li>
                        <li>
                            No tener ningún monto adeudado por concepto de años anteriores con NAVÍO;
                        </li>
                        <li>
                            Haber mantenido al mismo ARRENDATARIO que se señala en la CARÁTULA; y
                        </li>
                        <li>
                            Cualquier otro requerimiento que NAVÍO considere apropiado.
                        </li>
                    </ol>
                </div>
                El descuento correspondiente a cada año de renovación será sobre el precio que en ese momento corresponda al valor de la COBERTURA y no sobre el PAGO TOTAL de la
                primera COBERTURA contratada por el CLIENTE.
            </Typography>
            <div>
                <Typography style={{margin: '2em 0 0 0',  fontWeight: 'bold'}} >DÉCIMA CUARTA.- PREVALENCIA</Typography>
            </div>
            <Typography>
                Todas las Cláusulas de los presentes TÉRMINOS y/o en sus anexos, prevalecerán sobre las de cualquier otro documento o convenio de la materia.
            </Typography>
            <div>
                <Typography style={{margin: '2em 0 0 0',  fontWeight: 'bold'}} >DÉCIMA QUINTA.- JURISDICCIÓN</Typography>
            </div>
            <Typography>
                En caso de controversia respecto a la aplicación o interpretación de los presentes TÉRMINOS, el quejoso deberá ocurrir a los tribunales de la ciudad de Monterrey, Nuevo León, México.
            </Typography>
            <Typography style={{margin: '1em 0 0 0'}}>
                Una vez leídos los presentes TÉRMINOS, y enterado del contenido y alcance legal de todas y cada una de las Cláusulas que lo componen, el CLIENTE declara que no existen vicios
                del consentimiento que pudieren invalidarlo y lo firma de entera conformidad en la CARÁTULA.
            </Typography>
        </Grid>
      </Grid>
    </div>
  )
};

export default TermsDocument;

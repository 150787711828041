import React, { useState, useContext, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { subject } from '@casl/ability';

import { StoreContext } from 'App';
import { UPDATE_USER } from 'constants/queries';
import { Can } from 'lib/can';
import DefineAbilityFor from 'constants/abilities';

const useStyles = makeStyles(() => ({
  root: {}
}));

const UserPassword = props => {
  const classes = useStyles();
  const context = useContext(StoreContext);
  const password = useRef({});
  const ability = DefineAbilityFor(context.state.session)
  const fieldsEnabled = ability.can('update', subject('user', props.user))


  const { t, i18n } = useTranslation();
  const { className, user, ...rest } = props;
  const { register, errors, handleSubmit, watch } = useForm();
  password.current = watch('password', '');
  const [updateUser, mutationStatus] = useMutation(UPDATE_USER, {
    onCompleted: () =>
      context.state.showAlert({
        severity: 'success',
        message: `${t('updated_password')}`
      })
  });

  const onSubmit = data =>
  updateUser({
    variables: {
      id: user.id,
      input: {
        ...data
      }
    }
  });

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardHeader
          subheader={fieldsEnabled && t("information_editable")}
          title={t("password")}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={!fieldsEnabled}
                label={t("password")}
                margin="dense"
                name="password"
                variant="outlined"
                type="password"
                inputRef={register({ required: true })}
                error={errors.password}
                helperText={errors.password && t(errors.password.type)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={!fieldsEnabled}
                label={t("confirmation")}
                margin="dense"
                name="passwordConfirmation"
                variant="outlined"
                type="password"
                inputRef={register({ 
                  required: true, 
                  validate: value =>
                    value === password.current || "password_mismatch"
                })}
                error={errors.passwordConfirmation}
                helperText={errors.passwordConfirmation && t(errors.passwordConfirmation.type)}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
          {fieldsEnabled && (
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                {t("update_password")}
              </Button>
            </CardActions>
          )}
      </form>
    </Card>
  );
};

export default UserPassword;

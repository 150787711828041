import React, { useState, useContext, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
  Select,
  FormControl,
  InputLabel,
  Chip,
  option,
  FormHelperText,
  Paper
} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import ClearIcon from '@material-ui/icons/Clear';
import ErrorIcon from '@material-ui/icons/Error';
import { useTranslation } from 'react-i18next';

import DefineAbilityFor from 'constants/abilities';
import { StoreContext } from 'App';
import { UPDATE_LOT, DELETE_FILE, UPDATE_REQUIREMENT } from 'constants/queries';
import { Can } from 'lib/can';
import { subject } from '@casl/ability';
import { DropzoneArea } from 'material-ui-dropzone';
import { LoadingBar } from 'components';
import Helpers from 'lib/helpers'

const useStyles = makeStyles(theme => ({
  root: {},
  paper: {
    padding: '1rem'
  },
  paperError: {
    padding: '1rem',
    borderColor: theme.palette.error.main
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '90%'
  },
  fileCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Roboto, sans-serif',
    padding: '1rem',
    transition: 'all 0.3s',
    '&:hover': {
      boxShadow: '0 3px 6px rgba(0,0,0,0.5)'
    }
  },
  deleteFileIcon: {
    color: 'red'
  },
  fileCardContent: {
    display: 'flex',
    alignItems: 'center'
  },
  fileCardIcon: {
    marginRight: '1rem'
  }
}));

const LotDetails = props => {
  const { className, lot, ...rest } = props;
  const { register, handleSubmit, errors, control, setValue, watch } = useForm();
  const { t } = useTranslation();
  const classes = useStyles();
  const context = useContext(StoreContext);
  const ability = DefineAbilityFor(context.state.session);
  const userCanUpdate = ability.can('update', subject('lot', lot));
  const fieldsEnabled = userCanUpdate && lot.requirement.status == 'active';

  const inAdvance = useRef({})
  const againstDocs = useRef({})
  const onDelivery = useRef({})
  inAdvance.current = watch('inAdvance');
  againstDocs.current = watch('againstDocs');
  onDelivery.current = watch('onDelivery');

  const paymentSchemeTotal = parseInt(inAdvance.current) + parseInt(againstDocs.current) + parseInt(onDelivery.current)
  const paymentSchemeAddsUp = () => (
    paymentSchemeTotal == 100
  )

  const [deleteFile, deletionStatus] = useMutation(DELETE_FILE, {
    onCompleted: ({ deleteFile }) => {
      if (deleteFile.result)
        context.state.showAlert({
          severity: 'success',
          message: t('file_deleted')
        });
      else
        context.state.showAlert({
          severity: 'error',
          message: t('file_deletion_failure')
        });
    }
  });

  const [updateLot, mutationStatus] = useMutation(UPDATE_LOT, {
    onCompleted: () =>
      context.state.showAlert({
        severity: 'success',
        message: `${t('lot')} ${'updated'}`
      })
  });

  const [updateRequirement, requirementStatus] = useMutation(
    UPDATE_REQUIREMENT,
    {
      onCompleted: () =>
        context.state.showAlert({
          severity: 'success',
          message: `${t('lot')} ${t('chosen')}`
        })
    }
  );

  useEffect(() => {
    register('certifications');
  }, [register]);

  const onSubmit = data => {
    updateLot({
      variables: {
        id: lot.id,
        input: {
          ...data,
          pricePerUnit: parseInt(data.pricePerUnit),
          inAdvance: parseInt(data.inAdvance),
          onDelivery: parseInt(data.onDelivery),
          againstDocs: parseInt(data.againstDocs)
        }
      }
    });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardHeader
          avatar={
            lot.accepted && <Chip color="primary" label={t('accepted')} />
          }
          subheader={`${t('provider')}: ${lot.provider.firstName} ${
            lot.provider.lastName
          }- ${moment(lot.createdAt).format('DD/MM/YYYY')} `}
          title={`${t('lot_for')} ${lot.requirement.name}`}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={6}>
              <TextField
                fullWidth
                error={errors.pricePerUnit}
                helperText={errors.pricePerUnit && t(errors.pricePerUnit.type)}
                disabled={!fieldsEnabled}
                label={t('price_per_unit')}
                margin="dense"
                name="pricePerUnit"
                inputRef={register({ required: true })}
                defaultValue={lot.pricePerUnit}
                type="number"
                step="0.01"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Paper className={errors.inAdvance ? classes.paperError :classes.paper} variant='outlined'>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant='body2'>
                        {t('payment_scheme')}
                    </Typography>
                    <Typography variant='caption'>
                        {t('payment_scheme_explanation')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      error={errors.inAdvance}
                      defaultValue={lot.inAdvance}
                      disabled={!fieldsEnabled}
                      label={Helpers.capitalize(t('in_advance'))}
                      margin='dense'
                      name='inAdvance'
                      inputRef={register({
                        required: true,
                        validate: () => paymentSchemeAddsUp()
                      })}
                      type='number'
                      variant='outlined'
                    >
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      error={errors.againstDocs}
                      disabled={!fieldsEnabled}
                      defaultValue={lot.againstDocs}
                      label={Helpers.capitalize(t('against_docs'))}
                      margin='dense'
                      name='againstDocs'
                      inputRef={register({required: true})}
                      type='number'
                      variant='outlined'
                    >
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      defaultValue={lot.onDelivery}
                      error={errors.onDelivery}
                      disabled={!fieldsEnabled}
                      label={Helpers.capitalize(t('on_delivery'))}
                      margin='dense'
                      name='onDelivery'
                      inputRef={register({required: true})}
                      type='number'
                      variant='outlined'
                    >
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                  {
                    errors.inAdvance && (
                      <Typography variant='caption' color='error'>
                          {t('payment_scheme_addition_error')} ({paymentSchemeTotal})
                      </Typography>
                    )
                  }
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                error={errors.priceDetail}>
                <InputLabel id="demo-simple-select-outlined-label">
                  {t('price_detail')}
                </InputLabel>
                <Select
                  native
                  inputRef={register({ required: true })}
                  defaultValue={lot.priceDetail}
                  disabled={!fieldsEnabled}
                  label="Detalle de Precio"
                  inputProps={{
                    name: 'priceDetail',
                    id: 'outlined-age-native-simple'
                  }}>
                  <option aria-label="None" value="" />
                  <option value={'CIF'}>CIF</option>
                  <option value={'FOB'}>FOB</option>
                </Select>
                <FormHelperText>
                  {errors.priceDetail && t(errors.priceDetail.type)}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                error={errors.deliveryDate}
                helperText={errors.deliveryDate && t(errors.deliveryDate.type)}
                margin="dense"
                disabled={!fieldsEnabled}
                label={t('delivery_date')}
                type="date"
                name="deliveryDate"
                variant="outlined"
                defaultValue={lot.deliveryDate}
                inputRef={register({ required: true })}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                error={errors.expirationDate}
                helperText={
                  errors.expirationDate && t(errors.expirationDate.type)
                }
                disabled={!fieldsEnabled}
                margin="dense"
                name="expirationDate"
                label={t('expiration_date')}
                type="date"
                variant="outlined"
                defaultValue={lot.expirationDate}
                inputRef={register({ required: true })}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                error={errors.contactEmail}
                helperText={errors.contactEmail && t(errors.contactEmail.type)}
                disabled={!fieldsEnabled}
                label={t('contact_email')}
                margin="dense"
                name="contactEmail"
                inputRef={register({ required: true })}
                defaultValue={lot.contactEmail}
                type="text"
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                error={errors.contactPhone}
                helperText={errors.contactPhone && t(errors.contactPhone.type)}
                disabled={!fieldsEnabled}
                margin="dense"
                name="contactPhone"
                label={t('contact_phone')}
                type="text"
                variant="outlined"
                defaultValue={lot.contactPhone}
                inputRef={register({ required: true })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={errors.comments}
                helperText={errors.comments && t(errors.comments.type)}
                disabled={!fieldsEnabled}
                label={t('comments')}
                margin="dense"
                name="comments"
                multiline
                rows={5}
                inputRef={register}
                defaultValue={lot.comments}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              {lot.certifications.length == 0 ? (
                <Chip icon={<ErrorIcon />} label={t('without_docs')} color="error" />
              ) : (
                <Typography>{t('photos')}</Typography>
              )}
            </Grid>
            {fieldsEnabled && (
              <Grid item xs={12}>
                <Controller
                  name="certifications"
                  control={control}
                  as={props => (
                    <DropzoneArea
                      onChange={files => setValue('certifications', files)}
                      dropzoneText={t('drag_files_here')}
                    />
                  )}></Controller>
              </Grid>
            )}
            <Can I="read" this="lot" field="credentials">
              <Grid item container spacing={3} xs={12}>
                {lot.certifications.map(cert => (
                  <Grid item xs={4} key={cert.link}>
                    <a href={cert.link}>
                      <Card className={classes.fileCard}>
                        <CardContent className={classes.fileCardContent}>
                          <div className={classes.fileCardIcon}>
                            <DescriptionIcon />
                          </div>
                          <Typography variant="subtitle1">
                            {cert.name}
                          </Typography>
                        </CardContent>
                        {fieldsEnabled && (
                          <div
                            className={classes.deleteFileIcon}
                            onClick={e => {
                              e.preventDefault();
                              deleteFile({
                                variables: { id: cert.attachmentId }
                              });
                            }}>
                            <ClearIcon />
                          </div>
                        )}
                      </Card>
                    </a>
                  </Grid>
                ))}
              </Grid>
            </Can>
            {mutationStatus.loading && <LoadingBar />}
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Can I="update" this={subject('lot', lot)}>
            <Button
              color="primary"
              variant="contained"
              disabled={!fieldsEnabled}
              type="submit">
              {t('save_changes')}
            </Button>
          </Can>
          <Can
            I="choose lots for"
            this={subject('requirement', lot.requirement)}>
            <Button
              color="primary"
              variant="contained"
              disabled={!fieldsEnabled}
              onClick={e => {
                e.preventDefault();
                updateRequirement({
                  variables: {
                    id: lot.requirement.id,
                    input: {
                      acceptedLotId: lot.id,
                      status: 'inactive'
                    }
                  }
                });
              }}>
              {t('choose_lot')}
            </Button>
          </Can>
        </CardActions>
      </form>
    </Card>
  );
};

export default LotDetails;
